import React from "react";
import Main from "./Main";
import { ScreenProvider } from "../../context/reducers/screenContext";
import {
  initialState,
  reducer,
} from "../../context/reducers/screenContext/reducer";

const Screens = () => {
  return (
    <ScreenProvider initialState={initialState} reducer={reducer}>
      <Main />
    </ScreenProvider>
  );
};

export default Screens;
