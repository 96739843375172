import React, { memo } from "react";
import { areEqual } from "../../utils/comparison";

const Vimeo = ({ elData }) => {
  const id = elData.provider.data.id;
  const { autoplay, loop } = elData.videoFormat;

  return (
    <div className="Vimeo videoData-responsive">
      <iframe
        title="youtube-video"
        scrolling="no"
        allow={`${autoplay ? "autoplay;" : ""} fullscreen`}
        allowFullScreen
        src={`https://player.vimeo.com/video/${id}?autoplay=${Number(autoplay)}&loop=${Number(loop)}&autopause=0&muted=1`}
      />
    </div>
  );
};

export default memo(Vimeo, areEqual);
