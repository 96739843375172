export const updateElementData = (els, elId, data) => ({
  ...els,
  [elId]: { ...els[elId], elData: { ...els[elId].elData, ...data } },
});

export const updateElementContent = (els, elId, data) => ({
  ...els,
  [elId]: { ...els[elId], ...data },
});

export const updateHeaderData = (layout, element, elData) => ({
  ...layout.header,
  [element]: {
    ...layout.header[element],
    ...elData,
  },
});

export const updateFooterData = (layout, element, elData) => ({
  ...layout.footer,
  [element]: {
    ...layout.footer[element],
    ...elData,
  },
});
