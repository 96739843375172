import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import * as yup from "yup";
import { useUsers } from "../../context/reducers/usersContext";
import { editUser, getUser } from "../../context/fetch/fetchUsers";
import EditInput from "./editInput";
import { Loader } from "../../core/Loader";

const schema = yup.object().shape({
  name: yup.string().required({ key: "name", value: "Name is required" }),
  email: yup
    .string()
    .email()
    .required({ key: "email", value: "Email is required" }),
  company: yup
    .string()
    .required({ key: "company", value: "Company is required" }),
});

const EditModal = ({ id, onChangeToggle }) => {
  const [show, setShow] = useState(true);
  const [users, dispatch] = useUsers();
  const [role, setRole] = useState(users.users.user.role);
  const [status, setStatus] = useState(users.users.user.status);
  const [value, setValue] = useState({ obj: {}, id: id });

  const handleClose = () => {
    onChangeToggle(false);
    setShow(false);
  };

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const companyRef = useRef(null);
  const streetRef = useRef(null);
  const zipRef = useRef(null);
  const countryRef = useRef(null);
  const cityRef = useRef(null);
  const phoneRef = useRef(null);

  const [state, setState] = useState({
    errors: {
      name: "",
      email: "",
      company: "",
    },
  });

  const [edit, setEdit] = useState(false);

  useEffect(() => {
    getUser(id, dispatch);
    setRole(users.users.user.role);
  }, [id, dispatch, users.users.user.role]);

  useEffect(() => {
    return () =>
      edit &&
      (setValue({ obj: {} }),
      setState({
        errors: { name: "", email: "", company: "" },
      }),
      handleClose());
  });

  const handleUpdateItem = (e) => {
    setValue({ ...value, obj: { ...value.obj, ...e.obj } });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    schema
      .validate(
        {
          name: nameRef.current.value,
          email: emailRef.current.value,
          company: companyRef.current.value,
        },
        { abortEarly: false }
      )
      .then(async (valid) => {
        if (Object.keys(value.obj).length) {
          if (
            !(
              nameRef.current.value === users.users.user.name &&
              emailRef.current.value === users.users.user.email &&
              companyRef.current.value === users.users.user.company &&
              streetRef.current.value === users.users.user.street &&
              zipRef.current.value === users.users.user.zip &&
              cityRef.current.value === users.users.user.cityRef &&
              countryRef.current.value === users.users.user.country &&
              phoneRef.current.value === users.users.user.phone &&
              role === users.users.user.role &&
              status === users.users.user.status
            )
          ) {
            editUser(value, dispatch);
            setEdit(true);
          }
        } else {
          console.log("No field changed");
        }
      })
      .catch((err) => {
        const newState = {
          errors: {
            name: "",
            email: "",
            company: "",
          },
        };
        err.errors.forEach((err) => {
          newState.errors[err.key] = err.value;
        });
        setState({ ...state, ...newState });
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-left">
          {users.users.userLoading ? (
            <Loader />
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <EditInput
                  type="text"
                  name="email"
                  ref={emailRef}
                  placeholder="Email"
                  value={users.users.user.email}
                  updateValue={handleUpdateItem}
                  id="email"
                />
                {state.errors.email && (
                  <Form.Text className="text-danger">
                    {state.errors.email}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group>
                <EditInput
                  type="text"
                  name="name"
                  ref={nameRef}
                  placeholder="Name"
                  value={users.users.user.name}
                  updateValue={handleUpdateItem}
                  id="name"
                />
                {state.errors.name && (
                  <Form.Text className="text-danger">
                    {state.errors.name}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group>
                <select
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                    setValue({
                      ...value,
                      obj: { ...value.obj, role: e.target.value },
                    });
                  }}
                  className="form-control"
                  id="exampleFormControlSelect1"
                >
                  <option name="role" value="ADMIN">
                    Admin
                  </option>
                  <option name="role" value="PUBLISHER">
                    Publisher
                  </option>
                </select>
              </Form.Group>
              <Form.Group>
                <select
                  defaultValue={users.users.user.status}
                  disabled={role === "ADMIN"}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setValue({
                      ...value,
                      obj: { ...value.obj, status: e.target.value },
                    });
                  }}
                  className="form-control"
                  id="exampleFormControlSelect1"
                >
                  <option name="status" value="INACTIVE">
                    Inactive
                  </option>
                  <option name="status" value="ACTIVE">
                    Active
                  </option>
                </select>
              </Form.Group>
              <Form.Group>
                <EditInput
                  type="text"
                  name="company"
                  ref={companyRef}
                  placeholder="Company"
                  value={users.users.user.company}
                  updateValue={handleUpdateItem}
                  id="company"
                />
                {state.errors.company && (
                  <Form.Text className="text-danger">
                    {state.errors.company}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group>
                <EditInput
                  type="text"
                  name="street"
                  ref={streetRef}
                  placeholder="Street"
                  id="street"
                  updateValue={handleUpdateItem}
                  value={users.users.user?.street}
                />
              </Form.Group>

              <Form.Group>
                <EditInput
                  type="text"
                  name="city"
                  ref={cityRef}
                  placeholder="City"
                  value={users.users.user?.city}
                  updateValue={handleUpdateItem}
                  id="city"
                />
              </Form.Group>
              <Form.Group>
                <EditInput
                  type="text"
                  name="country"
                  ref={countryRef}
                  placeholder="Country"
                  value={users.users.user?.country}
                  updateValue={handleUpdateItem}
                  id="country"
                />
              </Form.Group>
              <Form.Group>
                <EditInput
                  type="text"
                  name="zip"
                  ref={zipRef}
                  placeholder="Zip"
                  value={users.users.user?.zip}
                  updateValue={handleUpdateItem}
                  id="zip"
                />
              </Form.Group>
              <Form.Group>
                <EditInput
                  type="text"
                  name="phone"
                  ref={phoneRef}
                  placeholder="Phone"
                  value={users.users.user?.phone}
                  updateValue={handleUpdateItem}
                  id="phone"
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={users.users.userEditLoading}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={users.users.userEditLoading}
          >
            {users.users.userEditLoading ? "...Loading" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditModal;
