import Axios from "axios";

export default class TemplatesServices {
  url = process.env.REACT_APP_API;

  getFolders = () => {
    return Axios({
      method: "GET",
      url: `${this.url}/folders/templates`,
      withToken: true,
    });
  };

  setFolder = (name) => {
    return Axios({
      method: "POST",
      url: `${this.url}/folders`,
      data: { name, type: "TEMPLATE" },
      withToken: true,
    });
  };

  editFolder = ({ folderId, folderName }) => {
    return Axios({
      method: "PATCH",
      url: `${this.url}/folders/${folderId}`,
      data: { name: folderName },
      withToken: true,
    });
  };

  removeFolder = (id) => {
    return Axios({
      method: "DELETE",
      url: `${this.url}/folders/${id}`,
      withToken: true,
    });
  };

  getTemplates = (id, sortQuery) => {
    return Axios({
      method: "GET",
      url: `${this.url}/templates?folderId=${id + (sortQuery ? "&sort=" + sortQuery : "")}`,
      withToken: true,
    });
  };

  setTemplate = ({ tmplName, id, body }) => {
    return Axios({
      method: "POST",
      url: `${this.url}/templates`,
      data: {
        name: tmplName,
        body,
        folderId: id,
      },
      withToken: true,
    });
  };

  getAllTemplates = () => {
    return Axios({
      method: "GET",
      url: `${this.url}/templates`,
      withToken: true,
    });
  };

  getPreviewData = (id) => {
    return Axios({
      method: "GET",
      url: `${this.url}/screens/${id}/preview`,
    });
  };
}
