import React, { useState } from "react";
import { useStateValue } from "../../context";
import { Form, Button, Container, Col, Row } from "react-bootstrap";
import { signUp } from "../../context/fetch/fetchUser";
import schema from "./schema";
import { useHistory } from "react-router-dom";

const Register = () => {
  const [{ user }, dispatch] = useStateValue();
  const userSignupLoading = user.userSignupLoading;
  const history = useHistory();

  const [state, setState] = useState({
    fields: {
      name: "",
      password: "",
      email: "",
      company: "",
      confirmPassword: "",
    },
    errors: {
      name: "",
      password: "",
      email: "",
      company: "",
      confirmPassword: "",
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    schema
      .validate(state.fields, { abortEarly: false })
      .then(() => {
        signUp(state.fields, dispatch, history);
      })
      .catch((err) => {
        const newState = {
          errors: {
            name: "",
            password: "",
            email: "",
            company: "",
            confirmPassword: "",
          },
        };
        err.errors.forEach((err) => {
          newState.errors[err.key] = err.value;
        });
        setState({ ...state, ...newState });
      });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      fields: { ...state.fields, [name]: value },
      errors: { ...state.errors, [name]: "" },
    });
  };

  return (
    <Container className={"login-container"}>
      <Row className={"justify-content-center login-section"}>
        <Col md={6} className={"login-box col-10"}>
          <Form onSubmit={handleSubmit}>
            <p className={"text-center text-uppercase login-title fbold"}>
              sign up
            </p>
            <Form.Group>
              <Form.Control
                type="text"
                name="email"
                placeholder="Email"
                value={state.fields.email}
                onChange={onChange}
                id="email"
                className={"focus-effect cursor-pointer"}
              />
              <span className={"focus-border"}></span>
              {state.errors.email && (
                <Form.Text className="text-danger">
                  {state.errors.email}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                value={state.fields.name}
                onChange={onChange}
                id="name"
                className={"focus-effect cursor-pointer"}
              />
              <span className={"focus-border"}></span>
              {state.errors.name && (
                <Form.Text className="text-danger">
                  {state.errors.name}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="company"
                placeholder="Company"
                value={state.fields.company}
                onChange={onChange}
                id="company"
                className={"focus-effect cursor-pointer"}
              />
              <span className={"focus-border"}></span>
              {state.errors.company && (
                <Form.Text className="text-danger">
                  {state.errors.company}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                id="password"
                onChange={onChange}
                value={state.fields.password}
                className={"focus-effect cursor-pointer"}
              />
              <span className={"focus-border"}></span>
              {state.errors.password && (
                <Form.Text className="text-danger">
                  {state.errors.password}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={state.fields.confirmPassword}
                onChange={onChange}
                id="confirmPassword"
                className={"focus-effect cursor-pointer"}
              />
              <span className={"focus-border"}></span>
              {state.errors.confirmPassword && (
                <Form.Text className="text-danger">
                  {state.errors.confirmPassword}
                </Form.Text>
              )}
            </Form.Group>
            <p className={"text-center mb-0"}>
              <Button type="submit" disabled={userSignupLoading}>
                {userSignupLoading ? "...Loading" : "Sign Up"}
              </Button>
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
