import React, { useRef, memo } from "react";
import { Form } from "react-bootstrap";
import {
  setBtnTextVal,
  setBtnUrlVal,
} from "../../../editor/utils/setStateValues";
import { areEqual } from "../../../editor/utils/comparison";

const LinkButton = ({ elId, cards, setCards, isEdited, setIsEdited }) => {
  const btnTextRef = useRef(null);
  const urlRef = useRef(null);

  const elData = cards[elId].elData;

  const handleOnSetBtnTextChange = () => {
    const updatedCards = setBtnTextVal(btnTextRef, cards, elId);
    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  const handleOnURLChange = () => {
    const updatedCards = setBtnUrlVal(urlRef, cards, elId);
    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  return (
    <Form.Group className="link-button">
      <h4>Link/Button</h4>

      <Form.Group className="link-button-row-1">
        <Form.Label>Button Name: </Form.Label>
        <Form.Control
          defaultValue={elData.btnText}
          type="text"
          placeholder="type a name"
          onChange={handleOnSetBtnTextChange}
          ref={btnTextRef}
        />
      </Form.Group>

      <Form.Group className="link-button-row-2">
        <Form.Label>URL: </Form.Label>
        <Form.Control
          defaultValue={elData.url.value}
          type="url"
          placeholder="type url"
          required
          onChange={handleOnURLChange}
          ref={urlRef}
          style={{ borderBottomColor: elData.url.isValid ? "green" : "red" }}
        />
      </Form.Group>
      <hr />
    </Form.Group>
  );
};

export default memo(LinkButton, areEqual);
