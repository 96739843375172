import React, { useState } from "react";
import * as yup from "yup";
import { Form, Button, Modal } from "react-bootstrap";
import { useTeg } from "../../context/reducers/tegContext";
import { addTag } from "../../context/fetch/fetchProjects";

const schema = yup.object().shape({
  title: yup.string().required({ key: "title", value: "title is required" }),
});

const initialState = {
  fields: {
    title: "",
  },
  errors: {
    title: "",
  },
};

const AddModal = ({ type, value }) => {
  const [show, setShow] = useState(false);
  const [project, dispatch] = useTeg();
  const [state, setState] = useState(initialState);

  const handleClose = () => {
    setState((state) => ({
      ...state,
      errors: initialState.errors,
    }));
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    schema
      .validate(state.fields, { abortEarly: false })
      .then(async (valid) => {
        const data = { ...state.fields, type };
        await addTag(dispatch, data);
        setState(initialState);
        !project.project.addTagLoading && handleClose();
      })
      .catch((err) => {
        const newState = {
          errors: {
            title: "",
          },
        };
        err.errors.forEach((err) => {
          newState.errors[err.key] = err.value;
        });
        setState({ ...state, ...newState });
      });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      fields: { ...state.fields, [name]: value },
      errors: { ...state.errors, [name]: "" },
    });
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} className={"mb-2"}>
        {value}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Form.Group>
              <Form.Control
                type="text"
                name="title"
                placeholder="Title"
                value={state.fields.title}
                onChange={onChange}
                id="title"
              />
              {state.errors.title && (
                <Form.Text className="text-danger">
                  {state.errors.title}
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={project.project.addTagLoading}
          >
            Close
          </Button>
          <Button
            disabled={project.project.addTagLoading}
            variant="primary"
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            {project.project.addTagLoading ? "Loading" : "Add"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AddModal;
