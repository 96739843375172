import React, { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Row, Col, Card, Button } from "react-bootstrap";
import SelectTemplates from "./SelectTemplates";

const SliderView = ({ screens, tmplsNFolders, setProject }) => {
  const [idx, setIdx] = useState(0);

  const nextScreen = () => {
    if (idx === screens.length - 1) setIdx(0);
    else setIdx(idx + 1);
  };

  const prevScreen = () => {
    if (idx === 0) setIdx(screens.length - 1);
    else setIdx(idx - 1);
  };

  return (
    <Row className={"justify-content-start project-view-slider"}>
      <Col col={12}>
        <div className={"project-view"}>
          <div className={"slider-box"}>
            <Col xl={4} md={6} className={"m-auto pt-5"}>
              <Card className={"cursor-pointer mb-4 border-0"}>
                <Card.Body className={"px-4 py-0"}>
                  <Card.Title className={"mb-4"}>
                    {screens[idx].name}
                  </Card.Title>
                </Card.Body>
                <Card.Body className={"px-4 py-0  position-relative"}>
                  <Card.Img variant="top" src={screens[idx].imgUrl} alt={""} />
                  <div className={"slider-control-icon slider-control-prev"}>
                    {screens.length > 1 && (
                      <Button onClick={prevScreen} variant="link">
                        <FiChevronLeft />
                      </Button>
                    )}
                  </div>
                  <div className={"slider-control-icon slider-control-next"}>
                    {screens.length > 1 && (
                      <Button onClick={nextScreen} variant="link">
                        <FiChevronRight />
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Row>
              <SelectTemplates
                screen={screens[idx]}
                tmplsNFolders={tmplsNFolders}
                setProject={setProject}
              />
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SliderView;
