import React from "react";
import NavBar from "../components/NavBar";

const GuestLayout = ({ children }) => {
  return (
    <>
      <header className={"ay-header"}>
        <NavBar guest={true} />
      </header>
      <main className={"ay-main"}>{children}</main>
    </>
  );
};

export default GuestLayout;
