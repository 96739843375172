import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useEffect } from "react";

const RemoveModal = ({
  onChangeToggle,
  redirect = () => {},
  loading,
  remove,
}) => {
  const [show, setShow] = useState(true);
  const [del, setDel] = useState(false);

  useEffect(() => {
    return () => del && onDelete();
  });

  const handleClose = () => {
    onChangeToggle(false);
    setShow(false);
  };
  const onDelete = () => {
    handleClose();
    redirect();
  };
  const handleRemove = () => {
    remove();
    setDel(true);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body" style={{ textAlign: "center" }}>
            <p style={{ fontSize: "20px" }}>
              Are you sure you want to delete ?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Close
          </Button>
          <Button disabled={loading} variant="primary" onClick={handleRemove}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemoveModal;
