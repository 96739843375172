import React, { useEffect } from "react";
import Dashboard from "../Dashboard";
import Menu from "../Menu";
import Header from "./Header";
import { useHistory, Prompt } from "react-router-dom";
import { useStateValue } from "../../context";
import { getTemplate } from "../../middleware";
import { attemptGetTemplate, getTemplateSuccess } from "../../context/actions";
import { Loader } from "../../../core/Loader";

const Main = () => {
  const [state, dispatch] = useStateValue();
  const history = useHistory();
  const templateId = history.location.pathname.split("/").pop();

  useEffect(() => {
    let didCancel = false;

    const fetchTemplate = async () => {
      try {
        dispatch(attemptGetTemplate());
        const result = await getTemplate(templateId);
        !didCancel && dispatch(getTemplateSuccess(result));
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchTemplate();
    return () => (didCancel = true);
  }, [dispatch, templateId]);

  if (!state.layout.fetched) return <Loader />;

  return (
    <div
      className={`main container ${
        state.isLoading || state.isHeaderDisable ? "eventDisabled" : ""
      }`}
    >
      <Prompt
        when={state.isEdited}
        message={(location) =>
          `Are you sure you want to go without saving template`
        }
      />
      <Header />
      <div className="main-body row">
        <Dashboard />
        <Menu />
      </div>
    </div>
  );
};

export default Main;
