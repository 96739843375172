import React from "react";
import { useStateValue } from "../../context";
import { getActiveEl } from "../../utils/getActiveEl";
import {
  EL_SETS_LIST,
  ELEMENT_HEADER_IDS,
  ELEMENT_FOOTER_IDS,
} from "../../configs/constants";

const Settings = () => {
  const [{ layout }] = useStateValue();
  const activeElLabel = getActiveEl(layout).elLabel;

  let ActiveElSets;

  switch (activeElLabel) {
    case ELEMENT_HEADER_IDS[1]:
      // Header-Text
      ActiveElSets = EL_SETS_LIST[0].Sets;
      break;
    case ELEMENT_HEADER_IDS[0]:
      // Header-Image
      ActiveElSets = EL_SETS_LIST[2].Sets;
      break;
    case ELEMENT_FOOTER_IDS[2]:
      // Footer-Text
      ActiveElSets = EL_SETS_LIST[0].Sets;
      break;
    case ELEMENT_FOOTER_IDS[0]:
      // Footer-Image
      ActiveElSets = EL_SETS_LIST[2].Sets;
      break;
    case ELEMENT_FOOTER_IDS[1]:
      // Footer-socialMedia
      ActiveElSets = EL_SETS_LIST[4].Sets;
      break;

    default:
      ActiveElSets = EL_SETS_LIST.filter(
        (item) => item.label === activeElLabel
      )[0].Sets;
      break;
  }

  return (
    <div className="settings">
      <ActiveElSets key={layout.activeEl.id} />
    </div>
  );
};

export default Settings;
