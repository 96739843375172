import React, { forwardRef } from "react";
import { Spinner, Form } from "react-bootstrap";

const RenameProject = (props, nameRef) => {
  const {
    name,
    loading,
    handleOnRenameProject,
    defaultValue,
    errorMessage,
    setErrorMessage,
  } = props;

  if (loading) return <Spinner animation="border" size="sm" />;

  return (
    <div className="input-group">
      <Form.Control
        isInvalid={errorMessage}
        name={name}
        ref={nameRef}
        defaultValue={defaultValue}
        className="form-control"
        onChange={() => setErrorMessage(false)}
      />
      <div className="input-group-append">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={handleOnRenameProject}
        >
          {loading ? "Renaming..." : "Rename"}
        </button>
      </div>
    </div>
  );
};

export default forwardRef(RenameProject);
