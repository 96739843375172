import React from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import Profile from "../components/Profile";
import Security from "../components/Security";
import UserManagment from "../components/UserManagment";
import GlobalLayout from "../layouts/GlobalLayout.jsx";
import WebSocket from "../layouts/WebSocket.jsx";
import AuthRoute from "./AuthRoute";
import NotFoundRoute from "./NotFoundRoute";
import GuestRoute from "./GuestRoute";
import routes from "./routesCode";
import Login from "../components/Login";
import Register from "../components/Register";
import ResetPassword from "../components/ResetPassword";
import ResetPasswordSuccess from "../components/ResetPassword/resetSuccess";
import VerifyPassword from "../components/VerifyPassword";
import RegisterSucceed from "../components/RegisterSucceed";
import RegisterConfirm from "../components/RegisterConfirm";
import Projects from "../components/Projects";
import AssetsManagement from "../components/AssetsManagement";
import TemplatesManagement from "../components/TemplatesManagement";
import Editor from "../editor";
import ProjectTypes from "../components/Tags/projectTypes";
import ProjectStatuses from "../components/Tags/projectStatuses";
import ProjectsView from "../components/ProjectsView";
import Screens from "../components/Screens";
import Tags from "../components/Tags";
import NotFound from "../core/NotFound";



const Index = () => {
  return (
    <WebSocket>
      <GlobalLayout>
          <BrowserRouter>
            <Switch>
              <AuthRoute exact path={routes.home.path} component={() => <Redirect to={{ pathname: routes.projects.path }} />} />

              <GuestRoute exact path={routes.logIn.path} component={Login} />
              <GuestRoute exact path={routes.signUp.path} component={Register} />
              <GuestRoute exact path={routes.resetPassword.path} component={ResetPassword} />
              <GuestRoute exact path={routes.resetPasswordSuccess.path} component={ResetPasswordSuccess} />
              <GuestRoute exact path={routes.verifyPassword.path} component={VerifyPassword} />
              <GuestRoute exact path={routes.registrationSucceed.path} component={RegisterSucceed} />
              <GuestRoute exact path={routes.registrationConfirm.path} component={RegisterConfirm} />
              <GuestRoute exact path={routes.references.path} component={() => null} />
              <GuestRoute exact path={routes.howItWorks.path} component={() => null} />
              <GuestRoute exact path={routes.testimonials.path} component={() => null} />
              <GuestRoute exact path={routes.prices.path} component={() => null} />

              <AuthRoute exact path={routes.projects.path} component={Projects} />
              <AuthRoute exact path={routes.projectAdd.path} component={Projects} />
              <AuthRoute exact path={routes.projectTypes.path} component={ProjectTypes} />
              <AuthRoute exact path={routes.projectStatuses.path} component={ProjectStatuses} />
              <AuthRoute exact path={routes.projectViewList.path} component={ProjectsView} />
              <AuthRoute exact path={routes.projectViewSlider.path} component={ProjectsView} />
              <AuthRoute exact path={routes.help.path} component={() => null} />
              <AuthRoute exact path={routes.tags.path} component={Tags} />
              <AuthRoute exact path={routes.screens.path} component={Screens} />
              <AuthRoute exact path={routes.assets.path} component={AssetsManagement} />
              <AuthRoute exact path={routes.templates.path} component={TemplatesManagement} />
              <AuthRoute exact path={routes.editor.path} component={Editor} />
              <AuthRoute exact path={routes.profile.path} component={Profile} />
              <AuthRoute exact path={routes.security.path} component={Security} />
              <AuthRoute exact path={routes.users.path} component={UserManagment} />

              <NotFoundRoute path={routes.notFoundRoute.path} component={NotFound} />
            </Switch>
          </BrowserRouter>
      </GlobalLayout>
    </WebSocket>
  );
};

export default Index;