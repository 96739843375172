import React, {useEffect, useMemo, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getFolders } from "../../context/fetch/fetchAssets";
import { useAssetsValue } from "../../context/reducers/assetsContext";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import {
  getAssetsSuccess,
  getFoldersSuccess,
  attemptGetFolders,
} from "../../context/actions/assetsActions";

const Main = (props) => {
  const [state, dispatch] = useAssetsValue();

  const initialState = useMemo(() => {
    return {
      columnName: 'createdAt' ,
      sortAsc: true
    }
  }, [])
  const [sortQuery, setSortValue] = useState(initialState);

  useEffect(() => {
    let didCancel = false;

    const fetchFolders = async () => {
          dispatch(attemptGetFolders());
          try {
            const sort = `${sortQuery.columnName}:${sortQuery.sortAsc ? 'asc' : 'desc'}`;

            const result = await getFolders(props.history, props.showInModal, sort);
            const { folders, activeFolderId, assets } = result;
            if (activeFolderId) {
              !didCancel && dispatch(getAssetsSuccess({ assets, activeFolderId }));
            }
            !didCancel && dispatch(getFoldersSuccess({ folders, activeFolderId }));
          } catch (error) {
            console.log("error", error);
          }
    };

    fetchFolders();
    return () => (didCancel = true);
  }, [dispatch, props.history, props.showInModal, sortQuery]);

  return (
    <>
      <Container className={"info-container"}>
        <Row className={"justify-content-start"}>
          <Col md={7} lg={5} className={"info-section assets-section"}>
            {!props.showInModal && (
              <>
                <p className={"info-title text-uppercase fbold"}>
                  asset database
                </p>
                <p className={"info-text"}>
                  The set your logo in a template header, create image sliders
                  or put photos, illustrations and sound files on your
                  recognised landing page—in the asset database you can load and
                  store your logos, photos, illustrations and sound files in
                  your specific project folder.
                  <br />
                  <br />
                  The asset database accepts jpegs, png, gif as image files and
                  mp2, mp3, mpga as audio files
                </p>
                <p>
                  <b>
                    Please regard that you only upload <br /> low-res image
                    files with less than 5 MB
                  </b>
                </p>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Container className={"editor-container"}>
        <div className={"overview-editor templates-view"}>
          <Row className={"justify-content-start"}>
            <Col>
              <div className={"editor-view assets-view"}>
                <Row className={"justify-content-start"}>
                  <Col col={12}>
                    <div className={"file-editor"}>
                      <div
                        className={`align-items-stretch d-flex flex-column flex-lg-row h-100 ${
                          state.folder.file.isAssetsLoading
                            ? "eventDisabled"
                            : ""
                        }`}
                      >
                        <LeftSide {...props} />
                        <RightSide
                            sortQuery={sortQuery}
                            setSortValue={setSortValue}
                            {...props}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Main;
