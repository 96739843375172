import React, { useState } from "react";
import { Row, Col, Nav, Button } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import ProjectCard from "./ProjectCard";
import uuid from "uuid";
import RemoveModal from "./RemoveModal";
import { removeProject } from "../../context/fetch/fetchProjects";
import { PrjNavList } from "../../configs/constants";
import {
  removeProjectSuccess,
  setCurrentPath,
} from "../../context/actions/projectActions";
import { Loader } from "../../core/Loader";
import { useHistory } from "react-router-dom";
import { areEqual } from "../../editor/utils/comparison";

const ProjectOverview = ({ projects, dispatchPrj, isPrjLoading, setSortQuery, sortQuery }) => {
  const [isVisModal, setModalVis] = useState({ state: false, ID: null });
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [activeLink, setActiveLink] = useState(2);

  const handleOnAddNewPrjClick = () => {
    const path = "/project/add";
    history.push(path);
    dispatchPrj(setCurrentPath(path));
  };

  const handleOnRemoveClick = async () => {
    const ID = isVisModal.ID;

    try {
      setLoading(true);
      await removeProject(ID);
      dispatchPrj(removeProjectSuccess(ID));
      setLoading(false);
    } catch (e) {
      console.log("e", e);
      setLoading(false);
    }

    setModalVis({ state: false, ID: null });
  };

  const getProjects = () => {

    let projecList = projects.prjList;

    if(activeLink === 0 || activeLink === 1) {
      projecList = projects.prjList.filter(project => activeLink === 0 ? !project.published: project.published )
    }

    return projecList.map((project) => (
      <ProjectCard
        key={uuid.v4()}
        project={project}
        projects={projects}
        setModalVis={setModalVis}
        dispatchPrj={dispatchPrj}
      />
    ));
  };

  const isActive = (item) => {
    return !sortQuery && item === "All" || sortQuery === item.toLowerCase();
  }
  if (isPrjLoading) return <Loader />;

  return (
    <div className={"project-editor  project-overview-editor"}>
      <Row className={"justify-content-between align-items-end mb-1"}>
        <Col className={"col-auto"}>
          <h4 className={"text-uppercase editor-title mb-0"}>My Projects</h4>
        </Col>
        <Col className={"text-right"}>
          <Button
            className={"add-btn btn btn-primary"}
            onClick={handleOnAddNewPrjClick}
          >
            <FiPlus size={20} /> Add new project
          </Button>
        </Col>
      </Row>
      <Row className={"justify-content-start"}>
        <Col col={12}>
          <div className={"file-editor"}>
            <Nav
              variant="tabs"
              defaultActiveKey="link-3"
              className={"justify-content-md-end"}
            >
              <Nav.Item className={"tabs-title"}>Sort By:</Nav.Item>
              {PrjNavList.map((item, i) => (
                <Nav.Item key={i}>
                  <Nav.Link
                    style={{
                      textDecoration:
                        activeLink === i ? "underline" : "initial",
                    }}
                    eventKey={`link-${i}`}
                    onClick={() => setActiveLink(i)}
                  >
                    {item}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <Col md={10} className={"m-auto"}>
              <Row
                className={`justify-content-start ${
                  projects.prjList.length ? "none" : ""
                }`}
              >
                <p className={"editor-placeholder mb-0"}>
                  There are no projects at the moment.
                </p>
              </Row>
              <Row className={"align-items-center mt-5 project-overview-row"}>
                {getProjects()}
              </Row>
            </Col>
          </div>
        </Col>
      </Row>
      <RemoveModal
        isVisModal={isVisModal}
        setModalVis={setModalVis}
        handleOnRemoveClick={handleOnRemoveClick}
        loading={isLoading}
      />
    </div>
  );
};

export default React.memo(ProjectOverview, areEqual);
