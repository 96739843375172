import EditorService from "../services/editorService";

const editorService = new EditorService();

export const getTemplate = async (templateId) => {
  const response = await editorService.getTemplate(templateId);
  return {
    ...response.data,
    body: response.data.body ? JSON.parse(response.data.body) : {},
  };
};

export const setTemplate = async (template) => {
  await editorService.setTemplate(template);
};

export const setTmplName = async (template) =>
  await editorService.setTmplName(template);

export const removeTemplate = async (id) =>
  await editorService.removeTemplate(id);
