import React, { memo } from "react";
import { filterComparableData } from "../../utils/comparison";
import { VIDEO_PROVIDER_LIST } from "../../configs/constants";
import YouTube from "../../core/Player/YouTube";
import Facebook from "../../core/Player/Facebook";
import Vimeo from "../../core/Player/Vimeo";

const VideoData = ({ elData }) => {
  const Player = (props) => {
    switch (elData.provider?.name) {
      case VIDEO_PROVIDER_LIST[0].name:
        return <YouTube {...props} />;

      case VIDEO_PROVIDER_LIST[1].name:
        return <Facebook {...props} />;

      case VIDEO_PROVIDER_LIST[2].name:
        return <Vimeo {...props} />;

      default:
        return null;
    }
  };

  return (
    <div className="videoData">
      {Player() ? (
        <Player elData={elData} />
      ) : (
        <div className="videoData-initialView">No Identified Source</div>
      )}
    </div>
  );
};

export default memo(VideoData, filterComparableData);
