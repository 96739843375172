import React from "react";
import { useHistory } from "react-router-dom";
import interceptor from "../common/interceptor";
import { useStateValue } from "../context";
import { WebSocketContext } from "./WebSocket";
import Auth from "../configs/Auth";
import { fetchMe } from "../context/fetch/fetchUser";
import { Loader } from "../core/Loader";

let isInterceptorActive = false;

const GlobalLayout = ({ children }) => {
  const [state, dispatch] = useStateValue();
  const socketState = React.useContext(WebSocketContext);
  const history = useHistory();

  React.useEffect(() => {
    if (Auth.isAuthenticated()) fetchMe(dispatch);
  }, [dispatch]);

  !isInterceptorActive && interceptor(dispatch, history, socketState);
  isInterceptorActive = true;

  if (Auth.isAuthenticated() && !state.user.user.id) return <Loader />;
  return <>{children}</>;
};

export default GlobalLayout;
