import React, { useState } from "react";
import { ListGroup, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getTemplates, removeFolder } from "../../context/fetch/fetchTemplates";
import { useTemplatesValue } from "../../context/reducers/templatesContext";
import { MdModeEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import RemoveModal from "./RemoveModal";

const FolderList = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [state, dispatch] = useTemplatesValue();
  const history = useHistory();
  const { name, id, setIndex, setFolderId, showInModal } = props;

  const handleOnFolderClick = () => {
    const folderId = id === state.folder.activeFolderId ? null : id;
    getTemplates(folderId, dispatch, history, setShowSpinner, showInModal);
  };

  const handleOnEditClick = (e) => {
    e.stopPropagation();
    setIndex(2);
    setFolderId(id);
  };

  const handleOnRemoveClick = () => {
    const folder = state.folder;
    removeFolder(id, dispatch, history, folder);
  };

  const handleOnTemplateNameClick = () => {
    props.showInModal && props.closeModal();
  };

  const getTemplateNames = () => {
    const tmplNames = state.folder.template.list;
    return tmplNames.reduce((array, item) => {
      if (item.folderId === id) {
        array.push(
          <ListGroup.Item key={item.id}>
            <Link
              to={`/templates/${id}/editor/${item.id}`}
              className={"list-group-link"}
              onClick={handleOnTemplateNameClick}
            >
              {item.name}
            </Link>
          </ListGroup.Item>
        );
      }
      return array;
    }, []);
  };

  return (
    <ListGroup variant="flush" className={"my-3"}>
      <ListGroup.Item className={"parent-list-group-item"}>
        <span
          onClick={handleOnFolderClick}
          className={
            id === state.folder.activeFolderId
              ? "list-group-link parent-list-group-link active"
              : "list-group-link parent-list-group-link"
          }
        >
          {name}
          <span className="control-btns">
            <span>
              {showSpinner && state.folder.template.isTmplsLoading && (
                <Spinner animation="grow" variant="success" />
              )}
            </span>
            <Button
              className={`mr-2 ${showInModal ? "hidden" : ""}`}
              variant="outline-info"
              size="sm"
              onClick={(e) => handleOnEditClick(e)}
            >
              <MdModeEdit />
            </Button>
            <span
              className={`${showInModal ? "hidden" : ""}`}
              onClick={(e) => e.stopPropagation()}
            >
              <RemoveModal handleOnRemoveClick={handleOnRemoveClick} />
            </span>
          </span>
        </span>
        <ListGroup></ListGroup>
        <ListGroup
          variant="flush"
          className={
            id === state.folder.activeFolderId ? "d-block mt-3 pl-3" : "d-none"
          }
        >
          {getTemplateNames()}
        </ListGroup>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default FolderList;
