import * as Sentry from "@sentry/react";

export default {
  initalize: () => {
    return Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT
    });
  },
  sendMessage: (message) => {
    return Sentry.captureMessage(message);
  },
  setContextProps: (contextName, props) => {
    return Sentry.setContext(contextName,props)
  }
}
