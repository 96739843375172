import * as yup from "yup";

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, {
      key: "password",
      value: "Password is too short - should be 6 chars minimum."
    })
    .required({ key: "password", value: "Password is required" }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], {
      key: "confirmPassword",
      value: "Passwords must match"
    })
    .required({
      key: "confirmPassword",
      value: "Confirm password is required"
    })
});

export default schema;
