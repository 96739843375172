export const ADD_NEW_ELEMENT = "ADD_NEW_ELEMENT";
export const UPDATE_ELEMENT = "UPDATE_ACTIVE_ELEMENT_DATA";
export const SET_BAR_INDEX = "SET_BAR_INDEX";
export const REMOVE_ELEMENT = "REMOVE_ELEMENT";
export const CHANGE_ACTIVE_ELEMENT = "CHANGE_ACTIVE_ELEMENT";

export const ATTEMPT_GET_TEMPLATE = "ATTEMPT_GET_TEMPLATE";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";

export const ATTEMPT_SET_TEMPLATE_NAME = "ATTEMPT_SET_TEMPLATE_NAME";
export const SET_TEMPLATE_NAME_SUCCESS = "SET_TEMPLATE_NAME_SUCCESS";

export const ATTEMPT_REMOVE_TEMPLATE = "ATTEMPT_REMOVE_TEMPLATE";
export const REMOVE_TEMPLATE_SUCCESS = "REMOVE_TEMPLATE_SUCCESS";

export const UPDATE_HEADER = "UPDATE_HEADER";
export const SET_ELEMENT_HEADER = "SET_ELEMENT_HEADER";

export const SET_ELEMENT_FOOTER = "SET_ELEMENT_FOOTER";
export const UPDATE_FOOTER = "UPDATE_FOOTER";

export const SET_IS_EDITED = "SET_IS_EDITED";

// ------> Header
export const updateHeader = (header) => ({
  type: UPDATE_HEADER,
  payload: header,
});

export const setElementHeader = (label) => ({
  type: SET_ELEMENT_HEADER,
  payload: label,
});
// ------> Footer
export const setElementFooter = (label) => ({
  type: SET_ELEMENT_FOOTER,
  payload: label,
});

export const updateFooter = (elData) => ({
  type: UPDATE_FOOTER,
  payload: elData,
});
// -------

export const addNewElement = (payload) => ({
  type: ADD_NEW_ELEMENT,
  payload,
});

export const updateElState = (elData) => ({
  type: UPDATE_ELEMENT,
  payload: elData,
});

export const updateElement = (elData) => ({
  type: UPDATE_ELEMENT,
  payload: elData,
});

export const setBarIndex = (i) => ({ type: SET_BAR_INDEX, payload: i });

export const removeElement = (filteredEls, activeElId) => ({
  type: REMOVE_ELEMENT,
  payload: { els: filteredEls, id: activeElId },
});

export const changeActiveElement = (id) => ({
  type: CHANGE_ACTIVE_ELEMENT,
  payload: id,
});

export const attemptGetTemplate = () => ({
  type: ATTEMPT_GET_TEMPLATE,
});
export const getTemplateSuccess = (payload) => ({
  type: GET_TEMPLATE_SUCCESS,
  payload,
});

export const attemptSetTemplateName = () => ({
  type: ATTEMPT_SET_TEMPLATE_NAME,
});
export const setTemplateNameSuccess = (payload) => ({
  type: SET_TEMPLATE_NAME_SUCCESS,
  payload,
});

export const attemptRemoveTemplate = () => ({
  type: ATTEMPT_REMOVE_TEMPLATE,
});
export const removeTemplateSuccess = () => ({
  type: REMOVE_TEMPLATE_SUCCESS,
});

export const setIsEdited = (payload) => ({
  type: SET_IS_EDITED,
  payload,
});
