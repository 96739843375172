import React, { memo } from "react";
import { filterComparableData } from "../../utils/comparison";

const ImageData = ({ elData }) => (
  <div className="imageData">
    {elData.imgSrc ? (
      <a
        className="d-inline-block"
        href={elData.url.value}
        target="_blank"
        onClick={elData.url.isValid ? null : (e) => e.preventDefault()}
        rel="noopener noreferrer"
      >
        <img src={elData.imgSrc} alt="img" />
      </a>
    ) : (
      <div className="imageData-initialView">Choose image from assets</div>
    )}
  </div>
);

export default memo(ImageData, filterComparableData);
