import React from "react";
import { useDrag } from "react-dnd";

const DraggableComponent = (props) => {
  const { children, className, id, type, index } = props;

  const [, drag] = useDrag({
    item: { id, type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div className={className} ref={drag}>
      {children}
    </div>
  );
};

export default DraggableComponent;
