import TemplatesServices from "../../services/templatesServices";
import {
  attemptSetFolder,
  setFolderSuccess,
  attemptGetTemplates,
  getTemplatesSuccess,
  attemptSetTemplate,
  setTemplateSuccess,
  setActiveFolderId,
  attemptEditFolder,
  editFolderSuccess,
  attemptRemoveFolder,
  removeFolderSuccess,
  removeFolderFail,
} from "../actions/templatesActions";

const templatesServices = new TemplatesServices();

export const getFolders = async (history, showInModal, sortQuery) => {
  const foldersRes = await templatesServices.getFolders();
  const folders = foldersRes.data.data;
  if (showInModal) {
    return { folders, activeFolderId: null };
  }
  const currentPath = history.location.pathname.split("/").pop();
  const activeFolderId =
    currentPath === "templates" ? null : Number(currentPath);
  const result = { folders, activeFolderId, templates: null };
  if (activeFolderId) {
    const tmpltsRes = await templatesServices.getTemplates(activeFolderId, sortQuery);
    const templates = tmpltsRes.data.data;
    result.templates = templates;
  }
  return result;
};

export const setFolder = async (name, dispatch, history, props) => {
  try {
    dispatch(attemptSetFolder());

    const response = await templatesServices.setFolder(name);
    const res = response.data;
    !props.showInModal && history.push(`/templates/${res.id}`);
    dispatch(setFolderSuccess(res));
  } catch (e) {
    console.log("e", e);
  }
};

export const editFolder = async (
  { folderId, folderName },
  list,
  dispatch,
  setIndex
) => {
  try {
    dispatch(attemptEditFolder());

    await templatesServices.editFolder({ folderId, folderName });
    const updatedList = list.map((item) => {
      if (folderId === item.id) {
        item.name = folderName;
        return item;
      }
      return item;
    });
    setIndex(0);
    dispatch(editFolderSuccess(updatedList));
  } catch (e) {
    console.log("e", e);
  }
};

export const removeFolder = async (id, dispatch, history, folder) => {
  try {
    dispatch(attemptRemoveFolder());

    await templatesServices.removeFolder(id);
    const filteredList = folder.list.filter((folder) => id !== folder.id);
    if (folder.activeFolderId === id) {
      history.push("/templates");
      dispatch(removeFolderSuccess({ id: null, filteredList }));
    } else {
      history.push(`/templates/${folder.activeFolderId}`);
      dispatch(
        removeFolderSuccess({ id: folder.activeFolderId, filteredList })
      );
    }
  } catch (e) {
    dispatch(removeFolderFail());
  }
};

export const setTemplate = async (data, dispatch) => {
  try {
    dispatch(attemptSetTemplate());
    const response = await templatesServices.setTemplate(data);
    const res = response.data;
    dispatch(setTemplateSuccess(res));
    return res;
  } catch (e) {
    console.log("e", e);
  }
};

export const getTemplates = async (
  id,
  dispatch,
  history,
  setShowSpinner,
  showInModal
) => {
  try {
    if (id) {
      dispatch(attemptGetTemplates());
      setShowSpinner(true);

      const tmpltsRes = await templatesServices.getTemplates(id);
      const templates = tmpltsRes.data.data;

      !showInModal && history.push(`/templates/${id}`);

      dispatch(getTemplatesSuccess({ templates, id }));
      setShowSpinner(false);
    } else {
      !showInModal && history.push(`/templates`);
      dispatch(setActiveFolderId(id));
    }
  } catch (e) {
    console.log("e", e);
  }
};

export const getPreviewData = async (id) => {
  const response = await templatesServices.getPreviewData(id);
  return response.data;
};
