import React, { useState, useEffect } from "react";
import Table from "./tagsTable";
import { useTeg } from "../../context/reducers/tegContext";
import { getProjectStatuses } from "../../context/fetch/fetchProjects";
import AddModal from "./addModal";
import { getProjectStatusesSuccess } from "../../context/actions/projectActions";
import { Loader } from "../../core/Loader";

const ProjectStatuses = ({ btnName }) => {
  const [editIdx, setEditIdx] = useState(-1);
  const [removeToggle, setRemoveToggle] = useState(false);

  const [project, dispatch] = useTeg();
  useEffect(() => {
    let isCancelled = false;
    getProjectStatuses(dispatch).then(
      (data) => !isCancelled && dispatch(getProjectStatusesSuccess(data))
    );
    return () => (isCancelled = true);
  }, [dispatch]);

  const startEditing = (i) => {
    setEditIdx(i);
  };

  const stopEditing = () => {
    setEditIdx(-1);
  };
  return (
    <div className="mt-2 text-right">
      <AddModal type="PROJECT_STATUS" value={btnName} />

      {project.project.projectStatusesLoading ? (
        <Loader />
      ) : (
        <>
          <Table
            data={project.project.projectStatuses}
            header={[
              {
                name: "Name",
                prop: "title",
                type: "text",
              },
            ]}
            editIdx={editIdx}
            startEditing={startEditing}
            stopEditing={stopEditing}
            removeToggle={removeToggle}
            setRemoveToggle={setRemoveToggle}
            dispatch={dispatch}
          />
        </>
      )}
    </div>
  );
};

export default ProjectStatuses;
