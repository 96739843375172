import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { useStateValue } from "../../context";
import { fetchMe } from "../../context/fetch/fetchUser";
import {
  LOGGEDIN_ADMIN_NAVBAR,
  LOGGEDIN_NAVBAR,
  GUEST_NAVBAR,
} from "../../configs/constants";
import Auth from "../../configs/Auth";
import routes from "../../routes/routesCode";
import { WebSocketContext } from "../../layouts/WebSocket";
import logger from '../../utils/logger';
const Logo = require("../../assets/images/ayscan_logo.svg");

const NavBar = ({ guest }) => {
  const socketState = React.useContext(WebSocketContext);
  const history = useHistory();
  const [state, dispatch] = useStateValue();
  const { user, isLoggedIn } = state.user;

  let navigate =
    user.role === "ADMIN" ? LOGGEDIN_ADMIN_NAVBAR : LOGGEDIN_NAVBAR;

  if (guest) navigate = GUEST_NAVBAR;

  const [activePath, setActivePath] = useState(history.location.pathname);
  useEffect(() => {
    setActivePath(history.location.pathname);
  }, [history.location.pathname, isLoggedIn]);

  const handleOnLogOutClick = () => {
    logger.setContextProps('user',{});
    Auth.logout(history, socketState);
  };

  const handloOnBackToMyAccountClick = async () => {
    localStorage.token = localStorage.adminToken;
    localStorage.refreshToken = localStorage.adminRefreshToken;
    localStorage.removeItem("adminToken");
    localStorage.removeItem("adminRefreshToken");
    await fetchMe(dispatch);
    history.push(routes.users.path);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="white"
      variant="light"
      fixed="top"
      className={
        localStorage.token ? "ay-navbar ay-navbar_shadow" : "ay-navbar"
      }
    >
      <Container>
        <Navbar.Brand>
          <NavLink to="/">
            <img className="logo" src={Logo} alt="" />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-between"
        >
          <Nav>
            {navigate.map((x, i) => {
              if (x?.type === 'external'){
                  return <a href={x.to} key={i} target="_blank" className="nav-link text-uppercase">{x.label}</a>
              }
              return (
                <NavLink
                  key={i}
                  className="nav-link text-uppercase"
                  style={{ textDecoration: "none" }}
                  activeStyle={
                    activePath.includes(x.to)
                      ? { color: "#17bae7" }
                      : { color: "#676767" }
                  }
                  to={x.to}
                >
                  {x.label}
                </NavLink>
              );
            })}
          </Nav>
          {!localStorage.token ? (
            <Nav>
              <NavLink
                className="nav-link text-uppercase"
                style={{ textDecoration: "none" }}
                activeStyle={
                  activePath === routes.logIn.path
                    ? { color: "#17bae7" }
                    : { color: "#676767" }
                }
                to={routes.logIn.path}
              >
                {"Log In"}
              </NavLink>
              <NavLink
                className="nav-link text-uppercase"
                style={{ textDecoration: "none" }}
                activeStyle={
                  activePath === routes.signUp.path
                    ? { color: "#17bae7" }
                    : { color: "#676767" }
                }
                to={routes.signUp.path}
              >
                {"Sign Up"}
              </NavLink>
            </Nav>
          ) : (
            <Nav className={"dropdown-navbar"}>
              <NavDropdown
                title={`${user.name}`}
                id="basic-nav-dropdown"
                className={""}
              >
                <NavLink to={routes.profile.path} className={"dropdown-item"}>
                  Profile
                </NavLink>
                <NavLink
                  to={routes.security.path}
                  className={"dropdown-item border-bottom mb-2 pb-3"}
                >
                  Security
                </NavLink>

                {localStorage.adminToken ? (
                  <Button
                    variant={"primary"}
                    className="py-1 mx-4"
                    onClick={handloOnBackToMyAccountClick}
                  >
                    Back to my account
                  </Button>
                ) : (
                  <Button
                    variant={"primary"}
                    className="py-1 mx-4"
                    onClick={handleOnLogOutClick}
                  >
                    Log out
                  </Button>
                )}
              </NavDropdown>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
