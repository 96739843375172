import React, { useRef, memo } from "react";
import { Form, Button } from "react-bootstrap";
import { areEqual } from "../../../editor/utils/comparison";
import {
  setAudioUrlVal,
  setVideoFormatVals,
} from "../../../editor/utils/setStateValues";
import AssetsModal from "../../../core/AssetsModal";
import { FORMAT_LIST, CONTENT_TYPES } from "../../../editor/configs/constants";

const Audio = ({
  elId,
  cards,
  setCards,
  contentType,
  isEdited,
  setIsEdited,
}) => {
  const urlRef = useRef(null);

  const [autoplayRef, loopRef, controlRef] = [
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const arrOfRefs = [autoplayRef, loopRef, controlRef];
  const elData = cards[elId].elData;

  const handleOnURLChange = () => {
    const URL = urlRef.current.value.trim();
    const updatedData = setAudioUrlVal(URL, cards, elId);
    setCards(updatedData);
    !isEdited && setIsEdited(true);
  };

  const handleOnChangeFile = (params) => {
    const URL = params.files[0].file;
    const updatedData = setAudioUrlVal(URL, cards, elId);
    setCards(updatedData);
    !isEdited && setIsEdited(true);
  };

  const handleOnAudioFormatChange = () => {
    const updatedCards = setVideoFormatVals(
      { autoplayRef, loopRef, controlRef },
      cards,
      elId
    );

    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  return (
    <Form.Group className="audioSettings">
      <h4>Audio</h4>
      <Form.Group className="audioSettings-source">
        <Form.Label>Source: </Form.Label>
        <Form.Control
          value={elData.url.value}
          type="url"
          required
          placeholder="type url"
          onChange={handleOnURLChange}
          ref={urlRef}
        />
      </Form.Group>
      <Form.Group>
        <AssetsModal
          callBackFile={handleOnChangeFile}
          callLocation={`Audio-audio-one`}
        >
          <Button variant="primary">
            {contentType === CONTENT_TYPES[4] ? "Choose audio" : "Change audio"}
          </Button>
        </AssetsModal>
      </Form.Group>
      <Form.Group className="audioSettings-format">
        {FORMAT_LIST.map((item, i) => (
          <Form.Check
            key={item.id}
            type="checkbox"
            label={item.label}
            name={item.name}
            onChange={handleOnAudioFormatChange}
            defaultChecked={elData.videoFormat && elData.videoFormat[item.name]}
            ref={arrOfRefs[i]}
          />
        ))}
      </Form.Group>{" "}
      <hr />
    </Form.Group>
  );
};

export default memo(Audio, areEqual);
