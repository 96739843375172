import React, { useState } from "react";
import { FiSquare, FiList, FiPlus } from "react-icons/fi";
import { Col, Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTemplatesValue } from "../../context/reducers/templatesContext";
import AddTemplate from "./AddTemplate";
import SortingList from "../Sorting/SortingList";

const RightSide = (props) => {
  const [state] = useTemplatesValue();
  const [showAddTmpl, setShowAddTmpl] = useState(
    props.showInModal ? true : false
  );

  const folderList = state.folder.template.list;

  const handleOnTemplateClick = () => {
    props.showInModal && props.closeModal();
  };

  return (
    <>
      {showAddTmpl ? (
        <AddTemplate
          {...props}
          onCloseClick={() => setShowAddTmpl(!showAddTmpl)}
        />
      ) : (
        <Col lg={9} className={"px-0 h-100"}>
          <div
            className={
              "justify-content-between border-bottom d-flex align-items-center assets-navbar"
            }
          >
            <Col className={"col-auto"}>
              <Nav
                variant="tabs"
                defaultActiveKey="link-2"
                className={"justify-content-start align-items-center"}
              >
                <Nav.Item className={"tabs-title pl-0"}>VIEW</Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="link-1">
                    <FiList size={"25"} />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="link-2">
                    <FiSquare size={"23"} />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col className={"col-auto px-0"}>
              <Nav className="flex-column-reverse flex-sm-row">

                <SortingList
                    columns={[
                      {label: 'Created Date',columnName:'createdAt'},
                      {label: 'Name',columnName:'name'}
                    ]}
                    setSortValue={props.setSortValue}
                    value={props.sortQuery}
                />

                <Button
                  className={`add-btn btn btn-primary ${
                    state.folder.activeFolderId ? "" : "disabled"
                  }`}
                  onClick={() => setShowAddTmpl(!showAddTmpl)}
                >
                  <FiPlus size={16} /> Add new template
                </Button>
              </Nav>
            </Col>
          </div>

          {state.folder.activeFolderId ? (
            <div className={" my-3 row px-3"}>
              {folderList.length ? (
                folderList.map(({ name, id }) => (
                  <div key={id}>
                    <Col className={" col-auto"}>
                      <Link
                        key={id}
                        to={`/templates/${state.folder.activeFolderId}/editor/${id}`}
                        onClick={handleOnTemplateClick}
                      >
                        <div className={"template-build-box"}>
                          <div>
                            <div
                              className={"border template-build-box--inner"}
                            ></div>
                          </div>
                          <p>{name}</p>
                        </div>
                      </Link>
                    </Col>
                  </div>
                ))
              ) : (
                <div className={"editor-placeholder mb-0"}>
                  <p>No templates in folder !</p>
                </div>
              )}
            </div>
          ) : null}
        </Col>
      )}
    </>
  );
};

export default RightSide;
