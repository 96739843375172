import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required({ key: "name", value: "Name is required" }),
  password: yup
    .string()
    .min(6, {
      key: "password",
      value: "Password is too short - should be 6 chars minimum."
    })
    .required({ key: "password", value: "Password is required" }),

  email: yup
    .string()
    .email()
    .required({ key: "email", value: "Email is required" }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], {
      key: "confirmPassword",
      value: "Passwords must match"
    })
    .required({
      key: "confirmPassword",
      value: "Confirm password is required"
    }),
  company: yup
    .string()
    .required({ key: "company", value: "Company is required" })
});

export default schema;
