import React from "react";
import Main from "./Main";
import { ProjectProvider } from "../../context/reducers/projectContext";
import {
  initialState,
  reducer,
} from "../../context/reducers/projectContext/reducer";

const Projects = () => {
    
  return (
    <ProjectProvider initialState={initialState} reducer={reducer}>
      <Main />
    </ProjectProvider>
  );
};

export default Projects;
