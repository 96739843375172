import React from "react";
import { Redirect } from "react-router-dom";
import NavBar from "../components/NavBar";
import { useStateValue } from "../context";
import routes from "../routes/routesCode";
import ProjectBreadcrumb from "../core/ProjectBreadcrumb";

const LoggedInLayout = ({ path, children }) => {
  const [{ user }] = useStateValue();
  const role = user.user.role;

  if (
    role === "PUBLISHER" &&
    (path === routes.users.path || path === routes.tags.path)
  ) {
    return <Redirect to={{ pathname: routes.projects.path }} />;
  }

  return (
    <>
      <header className={"ay-header"}>
        <NavBar />
      </header>
      <ProjectBreadcrumb />
      <main className={"ay-main"}>{children}</main>
    </>
  );
};

export default LoggedInLayout;
