import React, { memo, useState } from "react";
import { Form } from "react-bootstrap";
import { setAddressVal } from "../../../editor/utils/setStateValues";
import { areEqual } from "../../../editor/utils/comparison";
import { StandaloneSearchBox, LoadScriptNext } from "@react-google-maps/api";
import {
  GOOGLE_MAPS_API_KEY,
  LIBRARIES,
} from "../../../editor/configs/constants";

const Map = ({ elId, cards, setCards, isEdited, setIsEdited }) => {
  const [searchBox, setSearchBox] = useState();
  const elData = cards[elId].elData;

  const onLoadSearch = (param) => {
    setSearchBox(param);
  };

  const onPlacesChanged = () => {
    const place = searchBox.getPlaces();
    const location = place[0].geometry.location;
    const name = place[0].formatted_address;
    const lat = location.lat();
    const lng = location.lng();

    const updatedCards = setAddressVal({ lat, lng, name }, cards, elId);
    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  return (
    <Form.Group className="map">
      <h4>Map</h4>
      <Form.Group>
        <Form.Label>Address: </Form.Label>
        <LoadScriptNext
          id="script-loader"
          googleMapsApiKey={GOOGLE_MAPS_API_KEY}
          libraries={LIBRARIES}
        >
          <StandaloneSearchBox
            onLoad={onLoadSearch}
            onPlacesChanged={onPlacesChanged}
          >
            <Form.Control
              defaultValue={elData.address.value.name}
              type="text"
              placeholder="Type address"
              required
            />
          </StandaloneSearchBox>
        </LoadScriptNext>
      </Form.Group>
      <hr />
    </Form.Group>
  );
};

export default memo(Map, areEqual);
