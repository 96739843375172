import React from "react";
import { Modal } from "react-bootstrap";
import TemplatesManagement from "../../components/TemplatesManagement";

function TemplatesModal(props) {
  const [modalShow, setModalShow] = React.useState(false);

  const closeModal = () => setModalShow(false);
  const openModal = () => setModalShow(true);

  return (
    <>
      <span className="modal-preview" onClick={openModal}>
        {props.children}
      </span>

      <Modal
        size="lg"
        onHide={closeModal}
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        // centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Save Template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TemplatesManagement
            {...props}
            showInModal={true}
            closeModal={closeModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TemplatesModal;
