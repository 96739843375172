import React, { memo } from "react";
import { areEqual } from "../../utils/comparison";

const YtPlayer = ({ elData }) => {
  const id = elData.provider.data.id;
  const { autoplay, loop, control } = elData.videoFormat;

  return (
    <div className="YtPlayer videoData-responsive">
      <iframe
        title="youtube-video"
        scrolling="no"
        src={`https://www.youtube.com/embed/${id}?controls=${Number(control)}&autoplay=${Number(autoplay)}&mute=1${loop ? `&loop=${Number(loop)}&playlist=${id}` : ""}`}
        allow={`${autoplay ? "autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" : ""}`}
        allowFullScreen
      />
    </div>
  );
};

export default memo(YtPlayer, areEqual);
