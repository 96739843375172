import React, { useState, useCallback } from "react";
import { Card } from "./Card";
// import update from "immutability-helper";

export const Container = (props) => {
  const initialState = props.children.map((Component, index) => ({
    id: index,
    Component,
  }));

  const { onMove } = props;
  const [cards, setCards] = useState(initialState);
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      let copyOfCards;

      setCards((cards) => {
        copyOfCards = [...cards];
        copyOfCards.splice(dragIndex, 1);
        copyOfCards.splice(hoverIndex, 0, dragCard);

        return copyOfCards;
      });

      onMove(copyOfCards);

      // setCards(
      //   update(cards, {
      //     $splice: [
      //       [dragIndex, 1],
      //       [hoverIndex, 0, dragCard],
      //     ],
      //   })
      // );
    },
    [cards, onMove]
  );
  const renderCard = (card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        Component={card.Component}
        moveCard={moveCard}
      />
    );
  };
  return (
    <>
      <div className="drag-container">
        {cards.map((card, i) => renderCard(card, i))}
      </div>
    </>
  );
};

export default Container;
