import Axios from "axios";

export default class AssetsServices {
  url = process.env.REACT_APP_API;

  getFolders = () => {
    return Axios({
      method: "GET",
      url: `${this.url}/folders/assets`,
      withToken: true,
    });
  };

  setFolder = (name) => {
    return Axios({
      method: "POST",
      url: `${this.url}/folders`,
      data: { name, type: "ASSETS" },
      withToken: true,
    });
  };

  editFolder = ({ folderId, folderName }) => {
    return Axios({
      method: "PATCH",
      url: `${this.url}/folders/${folderId}`,
      data: { name: folderName },
      withToken: true,
    });
  };

  removeFolder = (id) => {
    return Axios({
      method: "DELETE",
      url: `${this.url}/folders/${id}`,
      withToken: true,
    });
  };

  getFiles = (id, sortQuery) => {
    return Axios({
      method: "GET",
      url: `${this.url}/assets?folderId=${id}${sortQuery ? "&sort=" + sortQuery : ""}`,
      withToken: true,
    });
  };

  setFiles = (formData, i, onUploadProgress) => {
    return Axios({
      method: "POST",
      url: `${this.url}/assets`,
      data: formData,
      withToken: true,
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onUploadProgress(percent, i);
      },
      isUpload: true,
    });
  };

  removeFile = (id) => {
    return Axios({
      method: "DELETE",
      url: `${this.url}/assets/${id}`,
      withToken: true,
    });
  };
}
