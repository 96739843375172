import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import DragNDrop from "../../core/DragNDrop";

const AddModal = (props) => {
  const {
    show,
    setShow,
    files,
    onChangeFiles,
    handleOnUploadClick,
    progress,
    isDisable,
  } = props;
  const handleClose = () => {
    setShow(false);
    onChangeFiles([]);
  };
  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton={!isDisable}>
        <Modal.Title>Add file</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body" style={{ textAlign: "center" }}>
          <Form.Group controlId="formGroupFile" className="">
            <Form.Label className={"mb-4"}>
              Add a new file to be scanned and recognized
            </Form.Label>
            <div className={""}>
              <DragNDrop
                files={files}
                onChangeFiles={onChangeFiles}
                accept={"application/pdf, image/*"}
                progress={progress}
              />
            </div>
            <div className={"text-right mt-2"}>
              <Button
                onClick={handleOnUploadClick}
                variant="outline-primary "
                className={`btn btn-primary ${isDisable ? "disabled" : ""}`}
              >
                Upload
              </Button>
            </div>
          </Form.Group>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddModal;
