import {SOCIAL_MEDIAS} from "../../configs/constants";

const identSocMediaURL = (url = "", name = "") => {

    const isFacebookURL = (url) => { // good
        // const regExp = /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w-]*)?/gm;
        const regExp = /(?:(?:http|https):\/\/)?(?:www.)?facebook.com/gm;
        const match = url.match(regExp);
        return !!match;
    };

    const isInstagramURL = (url) => { // bad
        // const regExp = /(https?:\/\/(?:www\.)?instagram\.com\/p\/([^/?#&]+)).*/gm;
        const regExp = /(https?:\/\/(?:www\.)?instagram\.com)/gm;
        const match = url.match(regExp);
        return !!match;
    };

    const isPinterestURL = (url) => { // bad
        // const regExp = /(?:(?:http|https):\/\/)?(?:www.)?pinterest\.com\/pin\/([^/?#&]+).*/gm;
        const regExp = /(?:(?:http|https):\/\/)?(?:www.)?pinterest\.com/gm;
        const match = url.match(regExp);
        return !!match;
    };

    const isLinkedinURL = (url) => { // bad
        // const regExp = /(?:(?:http|https):\/\/)?(?:www.)?linkedin\.com\/posts\/([^/?#&]+).*/gm;
        const regExp = /(?:(?:http|https):\/\/)?(?:www.)?linkedin\.com/gm;
        const match = url.match(regExp);
        return !!match;
    };

    const isTwitterURL = (url) => { // good
        const regExp = /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w-]*)/;
        const match = url.match(regExp);
        return !!match;
    };

    const isYoutubeURL = (url) => { // good
        const regExp = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;
        const match = url.match(regExp);
        return !!match;
    };

    switch (name) {
        case SOCIAL_MEDIAS[0]: // Facebook
            return isFacebookURL(url);

        case SOCIAL_MEDIAS[1]: // Instagram
            return isInstagramURL(url);

        case SOCIAL_MEDIAS[2]: // Pinterest
            return isPinterestURL(url);

        case SOCIAL_MEDIAS[3]: // LinkedIn
            return isLinkedinURL(url);

        case SOCIAL_MEDIAS[4]: // Twitter
            return isTwitterURL(url);

        case SOCIAL_MEDIAS[5]: // Youtube
            return isYoutubeURL(url);

        default:
            return false;
    }
};

export default identSocMediaURL;
