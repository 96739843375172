import React, { memo, useRef } from "react";
import { ELEMENT_FOOTER_IDS, MAX_WIDTH } from "../../configs/constants";
import { setElementFooter, updateFooter } from "../../context/actions";
import SocialMediaData from "./SocialMediaData";
import { setFooterStyle, setFooterOrder } from "../../utils/setStateValues";
import { areEqual } from "../../utils/comparison";
import { Resizable } from "re-resizable";
import DropSortContainer from "../../utils/dragDropSort/Container";
import { getSortedComponents } from "../../../utils/getSortedComponents";

const Footer = ({ layout, dispatch }) => {
  const text = layout.footer.text;
  const image = layout.footer.image;
  const socialMedia = layout.footer.socialMedia;
  const imgSrc = image.imgSrc;
  const URL = image.url.value;
  const height = layout.footer.image.styles.height;

  const Image = () => {
    const imageRef = useRef(null);

    const handleOnImageAreaClick = () => {
      dispatch(setElementFooter(ELEMENT_FOOTER_IDS[0]));
    };

    const handleOnSizeChange = () => {
      const height = imageRef.current.height;
      const footerData = setFooterStyle({ height }, layout);
      dispatch(updateFooter(footerData));
    };

    return (
      <div
        className="image"
        onClick={handleOnImageAreaClick}
        onMouseDown={(e) => e.preventDefault()}
      >
        {imgSrc ? (
          <Resizable
            className="resizable"
            minHeight={10}
            maxHeight={260}
            maxWidth={MAX_WIDTH}
            lockAspectRatio={true}
            enable={{ top: true, bottom: false }}
            defaultSize={{
              height,
            }}
            onResizeStop={() => handleOnSizeChange()}
          >
            <a
              className="d-inline-block"
              href={URL}
              target="_blank"
              onClick={image.url.isValid ? null : (e) => e.preventDefault()}
              rel="noopener noreferrer"
            >
              <img src={imgSrc} alt="img" ref={imageRef} />
            </a>
          </Resizable>
        ) : (
          <div className="image-area">Load image +</div>
        )}
      </div>
    );
  };

  const Subhead = () => {
    const handleOnTextAreaClick = () => {
      dispatch(setElementFooter(ELEMENT_FOOTER_IDS[2]));
    };
    return (
      <div
        className="foot-text"
        onClick={handleOnTextAreaClick}
        onMouseDown={(e) => e.preventDefault()}
      >
        {text.hasText ? (
          <div dangerouslySetInnerHTML={{ __html: text.markup }} />
        ) : (
          <div className="foot-text-preview">
            Add text (Copyright, Company etc.) +
          </div>
        )}
      </div>
    );
  };

  const SocialMedias = () => {
    const handleOnSliderAreaClick = () => {
      dispatch(setElementFooter(ELEMENT_FOOTER_IDS[1]));
    };

    return (
      <div onClick={handleOnSliderAreaClick}>
        <SocialMediaData elData={socialMedia} />
      </div>
    );
  };

  const handleOnMove = (newOrder) => {
    const footer = setFooterOrder(layout, newOrder);
    dispatch(updateFooter(footer));
  };

  const Components = [
    { order: image.order, Item: Image },
    { order: text.order, Item: Subhead },
    { order: socialMedia.order, Item: SocialMedias },
  ];

  const getKey = () => {
    const grayscale = socialMedia.iconStyle.grayscale;
    const type = socialMedia.iconStyle.type;
    const list = JSON.stringify(socialMedia.list);
    return `${text.markup} ${imgSrc} ${URL} ${grayscale} ${type} ${list}`;
  };

  return (
    <div className="dashboard-main-footer">
      <div className={`label ${text.hasText || imgSrc ? "none" : ""}`}>
        FOOTER
      </div>

      <DropSortContainer key={getKey()} onMove={handleOnMove}>
        {getSortedComponents(Components)}
      </DropSortContainer>
    </div>
  );
};

const checkEquality = (preview, next) => {
  const previewFooter = preview.layout.footer;
  const nextFooter = next.layout.footer;
  return areEqual(previewFooter, nextFooter);
};

export default memo(Footer, checkEquality);
