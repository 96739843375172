import React, { useState, useEffect, useCallback, memo } from "react";
import { Form, Button } from "react-bootstrap";
import { areEqual } from "../../editor/utils/comparison";
import { setScreenData } from "../../context/fetch/fetchProjects";
import Video from "./Settings/Video";
import Image from "./Settings/Image";
import Slider from "./Settings/Slider";
import Map from "./Settings/Map";
import Text from "./Settings/Text";
import SocialMedia from "./Settings/SocialMedia";
import Audio from "./Settings/Audio";
import LinkButton from "./Settings/LinkButton";
import Web from "./Settings/Web";
import { popupCenter } from "../../utils/popupCenter";
import { filterOverwrittenData } from "../../utils/filterOverwrittenData";
import { successAlert } from "../../context/actions/userActions";
import { useStateValue } from "../../context";
import {
  directLinksInitial,
  CONTENT_TYPES,
} from "../../editor/configs/constants";
import { REACT_APP_PREVIEW_URL } from "../../configs/constants";

const GetTemplate = ({
  template,
  screen,
  activeTarget,
  activeTmplId,
  setProject,
}) => {
  const [cards, setCards] = useState({});

  const [isLoading, setLoading] = useState(false);
  const [, dispatch] = useStateValue();
  const [isEdited, setIsEdited] = useState(true);

  const readDirectLink = () => {
    let initialData = directLinksInitial[CONTENT_TYPES[activeTarget]];
    if (CONTENT_TYPES[activeTarget] === screen.contentType) {
      initialData = JSON.parse(screen.customContent);
    }
    setCards(initialData);
  };

  useEffect(() => {
    setIsEdited(true);
  }, [activeTarget, activeTmplId]);

  const readTemplate = () => {
    if (activeTmplId === -1) {
      setCards({});
      return;
    }

    if (screen.templateId === template.id) {
      const overwritenDataofScreen = JSON.parse(screen.overwrittenData);
      setCards(overwritenDataofScreen);
    } else {
      const templateData = JSON.parse(template.body);
      const cards = templateData
        ? filterOverwrittenData(templateData.elements)
        : {};
      setCards(cards);
    }
  };

  const formCards = () => {
    if (activeTarget) {
      readDirectLink();
    } else {
      readTemplate();
    }
  };

  const memoizedformCards = useCallback(formCards, [template, activeTarget]);
  useEffect(() => memoizedformCards(), [memoizedformCards]);
  const bundleSettings = () => {
    const ReaderList = {
      Text,
      Video,
      Audio,
      Image,
      Slider,
      SocialMedia,
      Map,
      LinkButton,
      Web,
    };

    const keyValueArray = Object.entries(cards);
    keyValueArray.sort((arr1, arr2) => arr1[1].order - arr2[1].order);

    return keyValueArray.map((item) => ({
      id: item[0],
      Reader: ReaderList[item[1].elLabel],
    }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const id = screen.id;

    const formData = () => {
      if (activeTarget === 0) {
        return {
          overwrittenData: JSON.stringify(cards),
          templateId: Object.keys(cards).length ? template.id : null,
          contentType: CONTENT_TYPES[activeTarget],
          customContent: null,
        };
      } else {
        return {
          overwrittenData: null,
          templateId: null,
          contentType: CONTENT_TYPES[activeTarget],
          customContent: JSON.stringify(cards),
        };
      }
    };

    const deliverScreenData = async () => {
      try {
        setLoading(true);
        await setScreenData(id, formData());
        setProject((project) => {
          return {
            ...project,
            screens: project.screens.map((item) => {
              if (item.id === screen.id) {
                item.templateId = activeTmplId;
                item.contentType = CONTENT_TYPES[activeTarget];
                item.customContent = JSON.stringify(cards);
              }
              return item;
            }),
          };
        });
        dispatch(successAlert("Template is submitted!"));
        setLoading(false);
        setIsEdited(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };

    deliverScreenData();
  };

  const handleOnPreviewClick = () => {
    popupCenter(
      `${REACT_APP_PREVIEW_URL}/screen/${screen.id}`,
      "preview",
      360,
      600
    );
  };

  const isDisable = () => {
    const currentType = CONTENT_TYPES[activeTarget];
    if (currentType === CONTENT_TYPES[0]) {
      return screen.templateId !== template?.id;
    } else {
      return CONTENT_TYPES[activeTarget] !== screen.contentType;
    }
  };

  return (
    <Form className="" onSubmit={handleOnSubmit}>
      <div className="project-overview-wrapper">
        {bundleSettings().map(({ Reader, id }) => (
          <Form.Group key={id} controlId="formBasicCheckbox">
            <Reader
              elId={id}
              cards={cards}
              setCards={setCards}
              contentType={CONTENT_TYPES[activeTarget]}
              setIsEdited={setIsEdited}
              isEdited={isEdited}
            />
          </Form.Group>
        ))}
      </div>

      <Form.Text
        className={`text-danger ${Object.keys(cards).length ? "none" : ""}`}
      >
        No editable settings in template
      </Form.Text>
      <Form.Group className="submit-screen-data">
        <>
          <Button
            variant={`primary ${isEdited ? "" : "disabled"}`}
            type="submit"
          >
            {isLoading ? "Loading" : "Submit"}
          </Button>
          <Button
            variant={`secondary ${isDisable() ? "disabled" : ""} `}
            type="button"
            onClick={handleOnPreviewClick}
          >
            Preview
          </Button>
        </>
      </Form.Group>
    </Form>
  );
};

export default memo(GetTemplate, areEqual);
