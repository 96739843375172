import axios from "axios";

export default class UsersService {
  url = process.env.REACT_APP_API;
  getAllUsers = (offset, limit,sort) => {
    return axios({
      method: "GET",
      url: `${this.url}/users`,
      params: {
        limit,
        offset,
        sort
      },
      withToken: true
    });
  };

  deleteUser = userId => {
    return axios({
      method: "DELETE",
      url: `${this.url}/users/${userId}`,
      withToken: true
    });
  };

  addUser = data => {
    return axios({
      method: "POST",
      url: `${this.url}/users/add`,
      data,
      withToken: true
    });
  };

  editUser = obj => {
    return axios({
      method: "PATCH",
      url: `${this.url}/users/${obj.id}`,
      data: obj.obj,
      withToken: true
    });
  };

  getUser = userId => {
    return axios({
      method: "GET",
      url: `${this.url}/users/${userId}`,
      withToken: true
    });
  };
}
