const routes = {
  // -------- Guest routes start -------- //

  home: {
    path: "/",
    breadcrumbTitle: "",
    title: "",
    exact: true,
  },

  logIn: {
    path: "/login",
    exact: true,
  },

  signUp: {
    path: "/registration",
    exact: true,
  },

  resetPasswordSuccess: {
    path: "/password/reset/success",
    exact: true,
  },

  verifyPassword: {
    path: "/reset-password/verify/token=:token",
    exact: true,
  },

  resetPassword: {
    path: "/password/reset",
    exact: true,
  },

  registrationSucceed: {
    path: "/registration/succeed",
    exact: true,
  },
  registrationConfirm: {
    path: "/registration/confirm",
    exact: true,
  },

  references: {
    path: "/references",
    exact: true,
  },

  howItWorks: {
    path: "/howItWorks",
    exact: true,
  },

  testimonials: {
    path: "/testimonials",
    exact: true,
  },

  prices: {
    path: "/prices",
    exact: true,
  },

  // -------- Guest routes end -------- //

  // -------- Authorized routes start -------- //

  projects: {
    path: "/project",
    exact: true,
  },

  projectAdd: {
    path: "/project/add",
    exact: true,
  },

  projectViewSlider: {
    path: "/project/view-slider/:id",
    exact: true,
  },

  projectViewList: {
    path: "/project/view-list/:id",
    exact: true,
  },

  tags: {
    path: "/tags",
    exact: true,
  },

  help: {
    path: "/help",
    exact: true,
  },

  screens: {
    path: "/project/screens/:id",
    exact: true,
  },

  projectTypes: {
    path: "/tegs/project-types",
    exact: true,
  },

  projectStatuses: {
    path: "/tegs/project-statuses",
    exact: true,
  },

  assets: {
    path: "/assets/:id?",
    exact: true,
  },

  templates: {
    path: "/templates/:id?",
    exact: true,
  },

  editor: {
    path: "/templates/:id/editor/:id",
    exact: true,
  },

  users: {
    path: "/users",
    exact: true,
  },

  security: {
    path: "/security",
    exact: true,
  },

  profile: {
    path: "/profile",
    exact: true,
  },

  // -------- Authorized routes end -------- //

  notFoundRoute: {
    path: "*",
    exact: false,
  },
};

export default routes;
