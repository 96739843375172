//User action types
// login
export const ATTEMPT_LOGIN = "ATTEMPT_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOG_OUT = "LOG_OUT";
// signup
export const ATTEMPT_SIGNUP = "ATTEMPT_SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
//profile
export const ATTEMPT_UPDATE_PROFILE = "ATTEMPT_UPDATE_PROFILE";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL = "PROFILE_UPDATE_FAIL";
export const ATTEMPT_UPLOAD_AVATAR = "ATTEMPT_UPLOAD_AVATAR";
export const UPLOAD_AVATAR_SUCCESS = "UPLOAD_AVATAR_SUCCESS";
export const UPLOAD_AVATAR_FAIL = "UPLOAD_AVATAR_FAIL";
//security
export const ATTEMPT_CHANGE_PASSWORD = "ATTEMPT_CHANGE_PASSWORD";
export const CAHNGE_PASSWORD_SUCCESS = "CAHNGE_PASSWORD_SUCCESS";
export const CAHNGE_PASSWORD_FAIL = "CAHNGE_PASSWORD_FAIL";
//verify password
export const ATTEMPT_VERIFY_PASSWORD = "ATTEMPT_VERIFY_PASSWORD";
export const VERIFY_PASSWORD_SUCCESS = "VERIFY_PASSWORD_SUCCESS";
export const VERIFY_PASSWORD_FAIL = "VERIFY_PASSWORD_FAIL";
//alert
export const ERROR_ALERT = "ERROR_ALERT";
export const WARNING_ALERT = "WARNING_ALERT";
export const SUCCESS_ALERT = "SUCCESS_ALERT";
export const UNSHOW_ALERT = "UNSHOW_ALERT";
///impersonate
export const ATTEMPT_IMPERSONATE = "ATTEMPT_IMPERSONATE";
export const IMPERSONATE_SUCCESS = "IMPERSONATE_SUCCESS";
export const IMPERSONATE_FAIL = "IMPERSONATE_FAIL";

// User actions
//login
export const attemptLogin = () => ({ type: ATTEMPT_LOGIN });
export const loginSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload });
export const loginFail = (payload) => ({ type: LOGIN_FAIL, payload });
export const logOut = () => ({ type: LOG_OUT });

//signup
export const attemptSignup = () => ({ type: ATTEMPT_SIGNUP });
export const signupSuccess = (payload) => ({ type: SIGNUP_SUCCESS, payload });
export const signupFail = (payload) => ({ type: SIGNUP_FAIL, payload });

// profile
export const attemptUpdateProfile = () => ({ type: ATTEMPT_UPDATE_PROFILE });
export const profileUpdateSuccess = (data) => ({
  type: PROFILE_UPDATE_SUCCESS,
  payload: data,
});
export const profileUpdateFail = (payload) => ({
  type: PROFILE_UPDATE_FAIL,
  payload,
});
export const attemptUploadAvatar = () => ({ type: ATTEMPT_UPLOAD_AVATAR });
export const uploadAvatarSuccess = (payload) => ({
  type: UPLOAD_AVATAR_SUCCESS,
  payload,
});
export const uploadAvatarFail = (payload) => ({
  type: UPLOAD_AVATAR_FAIL,
  payload,
});

//security
export const attemptChangePassword = () => ({ type: ATTEMPT_CHANGE_PASSWORD });
export const changePasswordSuccess = () => ({ type: CAHNGE_PASSWORD_SUCCESS });
export const changePasswordFail = (payload) => ({
  type: CAHNGE_PASSWORD_FAIL,
  payload,
});

// verify password
export const attemptVerifyPassword = () => ({ type: ATTEMPT_VERIFY_PASSWORD });
export const verifyPasswordSuccess = () => ({ type: VERIFY_PASSWORD_SUCCESS });
export const verifyPasswordFail = (payload) => ({
  type: VERIFY_PASSWORD_FAIL,
  payload,
});

//alert
export const errorAlert = (payload) => ({ type: ERROR_ALERT, payload });
export const warningAlert = (payload) => ({ type: WARNING_ALERT, payload });
export const successAlert = (payload) => ({ type: SUCCESS_ALERT, payload });
export const unshowAlert = () => ({ type: UNSHOW_ALERT });

///impersonate
export const attemptImpersonate = () => ({ type: ATTEMPT_IMPERSONATE });
export const impersonateSuccess = () => ({ type: IMPERSONATE_SUCCESS });
export const impersonateFail = () => ({ type: IMPERSONATE_FAIL });
