import React from "react";
import { useDrop } from "react-dnd";

const DropTarget = (props) => {
  const { children, className, accept, onDrop, dashMainBodyRef } = props;
  const [, drop] = useDrop({
    accept,
    drop(item, monitor) {
      const elements = dashMainBodyRef.current.children;

      const getElsMiddleOffset = () => {
        const result = [];
        let index = 0;
        for (const element of elements) {
          // Determine elements' position
          const position = element.getBoundingClientRect();
          const height = position.bottom - position.top;
          const middleOffset = position.top + height / 2;
          result.push({ middleOffset, index });
          index++;
        }
        return result;
      };

      // Collect middle points' offset of elements
      const middleOffsets = getElsMiddleOffset();

      // Determine drop item index base of current mouse position
      const getDropItemIndex = () => {
        if (middleOffsets.length) {
          // Determine mouse position
          const clientOffset = monitor.getClientOffset();
          for (const item of middleOffsets) {
            if (clientOffset.y < item.middleOffset) {
              return Number(item.index);
            }
          }
          return middleOffsets.length;
        }
        return 0;
      };

      const index = getDropItemIndex();

      onDrop(item, index);
      return undefined;
    },
    hover(item, monitor) {
      // ---- > mouse position
      // const clientOffset = monitor.getClientOffset();
      // console.log('clientOffset', clientOffset)
    },
  });

  return (
    <div className={className} ref={drop}>
      {children}
    </div>
  );
};

export default DropTarget;
