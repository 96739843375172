import React from "react";
import Main from "./Main";

import { UsersProvider } from "../../context/reducers/usersContext";
import {
  initialState,
  reducer,
} from "../../context/reducers/usersContext/reducer";

const UserManagment = () => {
  return (
    <UsersProvider initialState={initialState} reducer={reducer}>
      <Main />
    </UsersProvider>
  );
};

export default UserManagment;
