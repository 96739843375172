import React from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { registrationConfirm } from "../../context/fetch/fetchUser";
import { Loader } from "../../core/Loader";
import routes from "../../routes/routesCode";
import image from "../../assets/images/worning-icon.png";

const RegisterConfirm = () => {
  const [isError, setError] = React.useState(undefined);
  const { location, push } = useHistory();

  React.useEffect(() => {
    const token = location.search.split("=")[1];

    const processConfirm = async () => {
      try {
        await registrationConfirm(token);
        setError(false);
      } catch (error) {
        setError(true);
        console.log("error", error);
      }
    };

    processConfirm();
  }, [location.search]);

  const handleOnClick = () => {
    push(routes.logIn.path);
  };

  const handleOnRegisterClick = (e) => {
    e.preventDefault();
    push(routes.signUp.path);
  };

  if (isError === undefined) return <Loader />;

  return (
    <Container className={"login-container"}>
      <Row className={"justify-content-center login-section"}>
        <Col md={7} lg={6} xl={5} className={"login-box"}>
          {isError ? (
            <div className={`mt-3`}>
              <div className="d-flex align-items-center">
                <img src={image} width="25" alt="" className="mr-2" />
                <h4 className="m-0">Something went wrong!</h4>
              </div>
              <p className="pt-4 text-justify">
                There was an error during the verification process, please try{" "}
                <a
                  href="/#"
                  className="text-blue"
                  onClick={(e) => handleOnRegisterClick(e)}
                >
                  register again
                </a>{" "}
                or{" "}
                <a href="mailto:support@ayscan.com" className="text-blue">
                  contact to administrator
                </a>
                .
              </p>
            </div>
          ) : (
            <div className={`text-center mt-3`}>
              <p>
                Thank you, you are successfully verified, please click below to
                log in.
              </p>
              <Button onClick={handleOnClick} size="sm mt-4">
                Login{" "}
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterConfirm;
