import React, { memo } from "react";
import { filterComparableData } from "../../utils/comparison";
import { Resizable } from "re-resizable";

const TextData = ({ elData, handleOnStyleChange, keyVal }) => (
  <div className="textData">
    <Resizable
      className="resizable-container"
      minHeight={10}
      maxHeight={400}
      enable={{ top: false, bottom: true }}
      defaultSize={{
        width: "100%",
        height: elData.styles.height,
      }}
      onResizeStop={(params) => handleOnStyleChange(params, keyVal)}
    >
      <div
        className="textData-container"
        dangerouslySetInnerHTML={{ __html: elData.markup }}
      />
    </Resizable>
  </div>
);

export default memo(TextData, filterComparableData);
