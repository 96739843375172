import React, {useEffect, useState, useRef, useMemo} from "react";
import { useHistory } from "react-router-dom";
import { FiSquare, FiList } from "react-icons/fi";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { getProject } from "../../context/fetch/fetchProjects";
import { getTmplsNFolders } from "../../context/fetch/fetchTmplsNFolders";
import { PROJECT_PATH } from "../../configs/constants/paths";
import Header from "./Header";
import LisView from "./ListView";
import SliderView from "./SliderView";
import { Loader } from "../../core/Loader";
import SortingList from "../Sorting/SortingList";

const ProjectsView = (props) => {
  const [tmplsNFolders, setTmplsNFolders] = useState({ obtained: false });
  const [project, setProject] = useState({});
  const [isPrjLoading, setPrjLoading] = useState(false);
  const projectView = useRef(null);
  const history = useHistory();


  const id = props.match.params.id;
  const hash = props.location.hash;
  const view = history.location.pathname.split("/").slice(-2, -1)[0];

  const initialState = useMemo(() => {
    return {
      columnName: 'createdAt' ,
      sortAsc: true,
      initial:true,
    }
  }, [])
  const [sortQuery, setSortValue] = useState(initialState);

  useEffect(() => {
    let isCancelled = false;

    const fetchProjectNTemplates = async () => {
      try {
        setPrjLoading(true);
        const sort = `${sortQuery.columnName}:${sortQuery.sortAsc ? 'asc' : 'desc'}`;

        const project = await getProject(id,sort);
        const { folders, templates } = await getTmplsNFolders();
        !isCancelled &&
          setTmplsNFolders({ folders, templates, obtained: true });
        !isCancelled && setProject(project);
        !isCancelled && setPrjLoading(false);
      } catch (error) {
        setPrjLoading(false);
        setTmplsNFolders({ obtained: false });
      }
    };

    fetchProjectNTemplates();
    return () => (isCancelled = true);
  }, [project.id, id,sortQuery]);

  useEffect(() => {
    const elements = projectView.current?.children;
    if (elements && sortQuery?.initial) {
      const hashId = hash.slice(1);
      const element = [...elements].filter((item) => item.id === hashId);
      setTimeout(() => {
        element[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }, 500);
    }

  }, [isPrjLoading, hash]);

  if (isPrjLoading) return <Loader />;


  return (
    <Container className={"editor-container"}>


      <div className={"project-editor  project-overview-editor"}>
        <Header
          id={id}
          name={project?.name}
          projTitle={project.type?.title}
          length={project.screens?.length}
          published={project?.published}
        />
        {tmplsNFolders.obtained ? (
          <Row className={"justify-content-start"}>
            <Col col={12}>
              <div className={"project-view"} ref={projectView}>
                <Col col={12}>
                  <Row className={"justify-content-between border-bottom"}>
                    <Col className={"col-auto pl-0"}>
                      <Nav
                        variant="tabs"
                        defaultActiveKey="link-1"
                        className={"justify-content-start align-items-center"}
                      >
                        <Nav.Item className={"tabs-title pl-0"}>VIEW</Nav.Item>
                        <Nav.Item
                          onClick={() => {
                            history.push(`${PROJECT_PATH}/view-list/${id}`);
                          }}
                        >
                          <FiList
                            size={"25"}
                            style={
                              view === "view-list"
                                ? { color: "#1bbae7", padding: 0 }
                                : {}
                            }
                          />
                        </Nav.Item>
                        <Nav.Item
                          onClick={() => {
                            history.push(`${PROJECT_PATH}/view-slider/${id}`);
                          }}
                        >
                          <FiSquare
                            size={"23"}
                            style={
                              view === "view-slider"
                                ? { color: "#1bbae7", padding: 0 }
                                : {}
                            }
                          />
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <SortingList columns={[
                            {label: 'Created Date',columnName:'createdAt'},
                            {label: 'Name',columnName:'name'}
                         ]}
                         setSortValue={setSortValue}
                         value={sortQuery}
                    />
                  </Row>
                </Col>

                {view === "view-slider" ? (
                  <SliderView
                    screens={project.screens}
                    tmplsNFolders={tmplsNFolders}
                    setProject={setProject}
                  />
                ) : (
                  <LisView
                    screens={project.screens}
                    tmplsNFolders={tmplsNFolders}
                    setProject={setProject}
                  />
                )}
              </div>
            </Col>
          </Row>
        ) : null}
      </div>
    </Container>
  );
};
export default ProjectsView;
