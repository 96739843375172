import routes from "../routes/routesCode";

class Auth {
  constructor() {
    this.authenticated = Boolean(localStorage.token);
  }

  login(token, history, socketState) {
    localStorage.setItem("token", token.accessToken);
    localStorage.setItem("refreshToken", token.refreshToken);
    this.authenticated = Boolean(localStorage.token);
    socketState.connectWS();
    history.push(routes.projects.path);
  }

  logout(history, socketState) {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    this.authenticated = Boolean(localStorage.token);
    socketState.disconnectWS();
    history.push(routes.logIn.path);
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();
