import React from "react";
import { FiPlus } from "react-icons/fi";
import { useTemplatesValue } from "../../context/reducers/templatesContext";
import FolderList from "./FolderList";
import Spinner from "../../editor/core/Spinner";
import { Button } from "react-bootstrap";

const PreviewFolder = (props) => {
  const [state] = useTemplatesValue();

  const handleOnNewFolderClick = () => {
    props.setIndex(1);
  };

  return (
    <div className={"folder-editor "}>
      <div className="folder-btn-group text-right">
        <Button
          onClick={handleOnNewFolderClick}
          className={"add-btn btn btn-dark"}
        >
          <FiPlus size={16} />
          New folder
        </Button>
      </div>
      <div>
        {state.folder.isFolderLoading ? (
          <Spinner style={{ color: "#17bae7" }} />
        ) : (
          state.folder.list?.map((folder) => (
            <FolderList
              key={folder.id}
              name={folder.name}
              id={folder.id}
              {...props}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default PreviewFolder;
