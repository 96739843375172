import AssetsServices from "../../services/assetsServices";
import {
  attemptSetFolder,
  setFolderSuccess,
  attemptGetAssets,
  getAssetsSuccess,
  setActiveFolderId,
  attemptEditFolder,
  editFolderSuccess,
  attemptRemoveFolder,
  removeFolderSuccess,
} from "../actions/assetsActions";

const assetsServices = new AssetsServices();

export const getFolders = async (history, showInModal, sortQuery) => {
  const foldersRes = await assetsServices.getFolders();
  const folders = foldersRes.data.data;
  if (showInModal) return { folders, activeFolderId: null, assets: null };
  const currentPath = history.location.pathname.split("/").pop();
  const activeFolderId = currentPath === "assets" ? null : Number(currentPath);
  const result = { folders, activeFolderId, assets: null };
  if (activeFolderId) {
    const assetRes = await assetsServices.getFiles(activeFolderId, sortQuery);
    const asset = assetRes.data.data;
    result.assets = asset;
  }
  return result;
};

export const setFolder = async (name, dispatch, history, props) => {
  try {
    dispatch(attemptSetFolder());

    const response = await assetsServices.setFolder(name);
    const res = response.data;
    !props.showInModal && history.push(`/assets/${res.id}`);
    dispatch(setFolderSuccess(res));
  } catch (e) {
    console.log("e", e);
  }
};

export const editFolder = async (
  { folderId, folderName },
  list,
  dispatch,
  setIndex
) => {
  try {
    dispatch(attemptEditFolder());

    await assetsServices.editFolder({ folderId, folderName });
    const updatedList = list.map((item) => {
      if (folderId === item.id) {
        item.name = folderName;
        return item;
      }
      return item;
    });
    setIndex(0);
    dispatch(editFolderSuccess(updatedList));
  } catch (e) {
    console.log("e", e);
  }
};

export const removeFolder = async (id, dispatch, history, folder) => {
  try {
    dispatch(attemptRemoveFolder());

    await assetsServices.removeFolder(id);
    const filteredList = folder.list.filter((folder) => id !== folder.id);
    if (folder.activeFolderId === id) {
      history.push("/assets");
      dispatch(removeFolderSuccess({ id: null, filteredList }));
    } else {
      history.push(`/assets/${folder.activeFolderId}`);
      dispatch(
        removeFolderSuccess({ id: folder.activeFolderId, filteredList })
      );
    }
  } catch (e) {
    console.log("e", e);
  }
};

export const setFiles = async (data, i, onUploadProgress) => {
  try {
    return await assetsServices.setFiles(data, i, onUploadProgress);
  } catch (e) {
    console.log("e", e);
  }
};

export const removeFile = async (IDs, state) => {
  let list = [...state.folder.file.list];

  try {
    for await (const id of IDs) {
      await assetsServices.removeFile(id);
      const filteredList = list.filter((item) => item.id !== id);

      list = filteredList;
    }

    return list;
  } catch (error) {
    console.log("error", error);
  }
};
export const getFiles = async (
  id,
  dispatch,
  history,
  setShowSpinner,
  showInModal,
  sort
) => {
  try {
    if (id) {
      dispatch(attemptGetAssets());
      setShowSpinner(true);

      const assetsRes = await assetsServices.getFiles(id, sort);
      const assets = assetsRes.data.data;
      !showInModal && history.push(`/assets/${id}`);
      dispatch(getAssetsSuccess({ assets, id }));
      setShowSpinner(false);
    } else {
      !showInModal && history.push(`/assets`);
      dispatch(setActiveFolderId(id));
    }
  } catch (e) {
    console.log("e", e);
  }
};
