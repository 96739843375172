import {
  GET_SCREEN_SUCCESS,
  REMOVE_SCREEN_SUCCESS,
  RENAME_PROJECT_SUCCESS,
  EDIT_SCREEN_SUCCESS,
  UPLOAD_SCREENS_SUCCESS,
} from "../../actions/projectActions";
import { removeFilter } from "../../helpers";

export const initialState = {
  id: null,
  name: "",
  published: null,
  screens: [],
  type: {},
};

export const reducer = (state, { type, payload }) => {
  // console.log("type, payload", type, payload);
  switch (type) {
    case GET_SCREEN_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case REMOVE_SCREEN_SUCCESS:
      return {
        ...state,
        screens: removeFilter(state.screens, payload),
      };

    case RENAME_PROJECT_SUCCESS:
      return {
        ...state,
        name: payload,
      };

    case EDIT_SCREEN_SUCCESS:
      return {
        ...state,
        published: payload,
      };

    case UPLOAD_SCREENS_SUCCESS:
      return {
        ...state,
        screens: payload.concat(state.screens),
      };
    default:
      return state;
  }
};
