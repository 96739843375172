import React, { useState } from "react";
import { FiSquare, FiList, FiPlus } from "react-icons/fi";
import { Col, Nav, Button, Row } from "react-bootstrap";
import { useAssetsValue } from "../../context/reducers/assetsContext";
import { setFiles as deliverFiles } from "../../context/fetch/fetchAssets";
import DragNDrop from "../../core/DragNDrop";
import {
  setFileSuccess,
  attemptSetFile,
} from "../../context/actions/assetsActions";

const AddFile = ({ onCloseClick, callLocation }) => {
  const [state, dispatch] = useAssetsValue();
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState([]);

  const onUploadProgress = (percent, i) => {
    setProgress((progress) => {
      const copyProgress = [...progress];
      copyProgress[i] = percent;
      return copyProgress;
    });
  };

  const handleOnUploadClick = async () => {
    const id = state.folder.activeFolderId;

    if (files.length) {
      dispatch(attemptSetFile());
      const promises = files.map(async (file, i) => {
        const formData = new FormData();
        formData.append("folderId", id);
        formData.append("asset_file", file, file.name);
        return await deliverFiles(formData, i, onUploadProgress);
      });

      Promise.allSettled(promises).then((items) => {
        const files = items.map((item) => item.value.data);
        dispatch(setFileSuccess(files));
        onCloseClick();
      });
    }
  };

  const parseAcceptExtension = () => {
    if (callLocation) {
      const type = callLocation.split("-")[0];
      if (type === "Image") return "image/*";
      if (type === "Audio") return "audio/*";
    }
    return "audio/*, image/*";
  };

  return (
    <Col lg={9} className={"px-0 h-100"}>
      <div
        className={
          "justify-content-between border-bottom d-flex align-items-center assets-navbar"
        }
      >
        <Col className={"col-auto"}>
          <Nav
            variant="tabs"
            defaultActiveKey="link-2"
            className={"justify-content-start align-items-center"}
          >
            <Nav.Item className={"tabs-title pl-0"}>VIEW</Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-1" className={"disabled"}>
                <FiList size={"25"} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-2" className={"disabled"}>
                <FiSquare size={"23"} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col className={"col-auto px-0"}>
          <Nav className="flex-column-reverse flex-sm-row">
            <Button className={"add-btn btn btn-primary"}>
              <FiPlus size={16} /> Add new file
            </Button>
          </Nav>
        </Col>
      </div>
      <div className={"col-12 my-5"}>
        <Row className={"justify-content-center align-items-center my-5"}>
          <Col md={5} className={"drag-box col-10 "}>
            <DragNDrop
              files={files}
              onChangeFiles={setFiles}
              progress={progress}
              accept={parseAcceptExtension()}
            />
            <div className={"text-right mt-2"}>
              <Button
                onClick={onCloseClick}
                variant="outline-secondary "
                className={"mr-3"}
              >
                Cancel
              </Button>
              <Button
                onClick={handleOnUploadClick}
                variant="outline-primary "
                className={"btn btn-primary"}
              >
                Upload
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default AddFile;
