import * as yup from "yup";

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required({ key: "email", value: "Email is required" })
});

export default schema;
