import axios from "axios";

export default class EditorService {
  url = process.env.REACT_APP_API;

  setImage = (imageData) => {
    return axios({
      method: "POST",
      url: `${this.url}/templates/upload-image`,
      data: imageData,
      withToken: true,
    });
  };

  getTemplate = (id) => {
    return axios({
      method: "GET",
      url: `${this.url}/templates/${id}`,
      withToken: true,
    });
  };

  setTemplate = (template) => {
    return axios({
      method: "PATCH",
      url: `${this.url}/templates/${template.id}`,
      data: {
        name: template.name,
        body: template.body,
      },
      withToken: true,
    });
  };

  setTmplName = ({ name, id }) => {
    return axios({
      method: "PATCH",
      url: `${this.url}/templates/${id}`,
      data: { name },
      withToken: true,
    });
  };

  removeTemplate = (id) => {
    return axios({
      method: "DELETE",
      url: `${this.url}/templates/${id}`,
      withToken: true,
    });
  };
}
