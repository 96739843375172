import React, { useState, forwardRef } from "react";
import { Button, Modal } from "react-bootstrap";

const RemoveModal2 = forwardRef(
  ({ handleOnRemoveClick, isPinned, setShowPopup }, ref) => {
    const [show, setShow] = useState(false);

    const handleOnSave = () => {
      handleOnRemoveClick();
      setShow(false);
    };

    const handleOnClose = () => setShow(false);
    const handleShow = () => (isPinned ? setShowPopup(true) : setShow(true));

    return (
      <>
        <Button variant="outline-secondary" onClick={handleShow} ref={ref}>
          Delete
        </Button>
        <Modal show={show} onHide={handleOnClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure to delete this template?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleOnClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleOnSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
);

export default RemoveModal2;
