import React from "react";
import { StateProvider } from "./context";
import { initialState, reducer } from "./context/reducer";
import Main from "./components/Main";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function Editor() {
  return (
    <div className="App">
      <StateProvider initialState={initialState} reducer={reducer}>
        <DndProvider backend={HTML5Backend}>
          <Main />
        </DndProvider>
      </StateProvider>
    </div>
  );
}

export default Editor;
