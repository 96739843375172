import React from "react";
import { Button, Modal } from "react-bootstrap";

const RemoveModal = ({
  loading,
  setModalVis,
  isVisModal,
  handleOnRemoveClick,
}) => {
  const handleClose = () => {
    !loading && setModalVis({ state: false, ID: null });
  };

  const handleRemove = () => {
    handleOnRemoveClick();
  };

  return (
    <Modal show={isVisModal.state} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "20px" }}>Are you sure you want to delete ?</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={`secondary ${loading ? "eventDisabled" : ""}`}
          onClick={handleClose}
        >
          Close
        </Button>
        <Button disabled={loading} variant="primary" onClick={handleRemove}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveModal;
