import React, { memo } from "react";
import { Resizable } from "re-resizable";
import { filterComparableData } from "../../utils/comparison";
import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY, LIBRARIES } from "../../configs/constants";

const MapData = ({ elData, handleOnStyleChange, keyVal }) => {
  const center = elData.address.value;

  return (
    <div className="mapData">
      <Resizable
        className="resizable-container"
        minHeight={150}
        maxHeight={400}
        enable={{ top: false, bottom: true }}
        defaultSize={{
          width: "100%",
          height: elData.styles.height,
        }}
        onResizeStop={(params) => handleOnStyleChange(params, keyVal)}
      >
        <LoadScriptNext
          id="script-loader"
          googleMapsApiKey={GOOGLE_MAPS_API_KEY}
          libraries={LIBRARIES}
        >
          <GoogleMap
            id="ayscan-map"
            // mapContainerStyle={styles.container}
            // onClick={onClick}
            // onLoad={onMapLoad}
            StreetViewPanorama={false}
            mapContainerStyle={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
            zoom={elData.zoom}
            center={center}
          >
            <Marker
              // onLoad={onLoad}
              position={center}
              visible={Boolean(center.name)}
            />
          </GoogleMap>
        </LoadScriptNext>
      </Resizable>
    </div>
  );
};

export default memo(MapData, filterComparableData);
