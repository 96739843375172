import axios from "axios";

export default class UserService {
  url = process.env.REACT_APP_API;

  login = ({ email, password }) => {
    return axios({
      method: "POST", //GET
      url: `${this.url}/auth/login`,
      data: {
        email,
        password,
      },
    });
  };

  signUp = ({ name, company, email, password }) => {
    return axios({
      method: "POST",
      url: `${this.url}/auth/signup`,
      data: {
        name,
        company,
        email,
        password,
      },
    });
  };

  registrationConfirm = (token) => {
    return axios({
      method: "POST",
      url: `${this.url}/auth/registration/confirm`,
      data: {
        token,
      },
    });
  };

  fetchMe = () => {
    return axios({
      method: "GET",
      url: `${this.url}/users/me`,
      withToken: true,
    });
  };

  resetPassword = ({ email }) => {
    return axios({
      method: "POST",
      url: `${this.url}/auth/reset-password`,
      data: {
        email,
      },
    });
  };

  updateProfile = (data) => {
    return axios({
      method: "PATCH",
      url: `${this.url}/users/me`,
      data,
      withToken: true,
    });
  };

  uploadAvatar = (data) => {
    return axios({
      method: "POST",
      url: `${this.url}/users/upload-image`,
      data,
      withToken: true,
    });
  };

  getAvatar = (id) => {
    return axios({
      methode: "GET",
      url: `${this.url}/users/avatars/${id}`,
      withToken: true,
    });
  };

  changePassword = (data) => {
    return axios({
      method: "POST",
      url: `${this.url}/auth/change-password`,
      data,
      withToken: true,
    });
  };

  verifyPassword = (data) => {
    return axios({
      method: "POST",
      url: `${this.url}/auth/reset-password/verify`,
      data,
    });
  };

  impersonate = (userId) => {
    return axios({
      method: "GET",
      url: `${this.url}/auth/users/${userId}/impersonate`,
      withToken: true,
    });
  };

  refrashToken = () => {
    return fetch(`${process.env.REACT_APP_API}/auth/refresh-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        refreshToken: localStorage.getItem("refreshToken"),
      }),
    });
  };
}
