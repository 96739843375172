import React from 'react'
import { SOLID_SOCIAL_MEDIAS, OUTLINE_SOCIAL_MEDIAS } from '../../configs/constants'

export const getSolidSocialMediaIcon = label => {
  const Icon = SOLID_SOCIAL_MEDIAS.filter(item => item.label === label)[0].Icon;
  return <Icon />;
};

export const getOutlineSocialMediaIcon = label => {
  const Icon = OUTLINE_SOCIAL_MEDIAS.filter(item => item.label === label)[0].Icon;
  return <Icon />;
};
