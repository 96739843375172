import React, { createContext } from "react";
import io from "socket.io-client";
import { WS_BASE } from "../configs/constants";
import { Spinner } from "react-bootstrap";
import { useStateValue } from "../context";

const WebSocketContext = createContext(null);
export { WebSocketContext };
let socket = null;

const WebSocket = ({ children }) => {
  const [screen, setScreen] = React.useState();
  const [message, setMessage] = React.useState("");
  const [isOpenPopup, setOpenPopup] = React.useState(false);
  const [{ user }] = useStateValue();

  const connectWS = () => {
    if (localStorage.getItem("token")) {
      console.log("connectWS");
      try {
        socket = io.connect(WS_BASE, {
          query: { token: localStorage.getItem("token") },
          transports: ["websocket", "polling"],
        });
        socket.on("screen", (screen) => {
          setScreen(screen);
          setOpenPopup(true);
        });
        socket.on("pdf_parsing_finished", (message) => {
          setMessage(message);
          setOpenPopup(false);
          setTimeout(() => setMessage(""), 4000);
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const disconnectWS = () => {
    socket && socket.disconnect(true);
    console.log("disconnectWS");
  };

  const reconnectWS = () => {
    console.log("reconnectWS");
    disconnectWS();
    connectWS();
  };

  if (!socket && user.user.id) connectWS();

  return (
    <WebSocketContext.Provider
      value={{ socket, connectWS, reconnectWS, disconnectWS, screen, message }}
    >
      <>
        <div className={`popup-message${isOpenPopup ? "-open" : ""}`}>
          <Spinner animation="border" variant="primary" />
        </div>
        {children}
      </>
    </WebSocketContext.Provider>
  );
};

export default WebSocket;
