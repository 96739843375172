export const CHANGE_ORDER = "CHANGE_ORDER";

export const changeOrder = (els, item, index) => {
  const copyOfEls = { ...els };
  const dragItemOrder = copyOfEls[item.id].order;
  let newOrder = index;

  if (newOrder === dragItemOrder) return copyOfEls;
  if (newOrder > dragItemOrder + 1) {
    // drag down
    for (const key in copyOfEls) {
      const currentItemOrder = copyOfEls[key].order;
      if (
        currentItemOrder > dragItemOrder &&
        currentItemOrder <= newOrder - 1
      ) {
        copyOfEls[key].order = copyOfEls[key].order - 1;
      } else if (currentItemOrder === dragItemOrder) {
        copyOfEls[key].order = newOrder - 1;
      }
    }
  } else if (newOrder < dragItemOrder) {
    // drag up
    for (const key in copyOfEls) {
      const currentItemOrder = copyOfEls[key].order;
      if (currentItemOrder === dragItemOrder) {
        copyOfEls[key].order = newOrder;
      } else if (
        currentItemOrder > newOrder - 1 &&
        currentItemOrder <= dragItemOrder
      ) {
        copyOfEls[key].order = copyOfEls[key].order + 1;
      }
    }
  }
  return copyOfEls;
};

export const increaseOrder = (els, index) => {
  const copyOfEls = { ...els };
  for (const key in copyOfEls) {
    if (copyOfEls[key].order >= index) {
      copyOfEls[key].order = copyOfEls[key].order + 1;
    }
  }
  return copyOfEls;
};

export const decreaseOrder = (filteredEls, els, key) => {
  const copyOfEls = { ...filteredEls };
  const removeItemOrder = els[key].order;
  for (const key in copyOfEls) {
    if (copyOfEls[key].order >= removeItemOrder) {
      copyOfEls[key].order = copyOfEls[key].order - 1;
    }
  }
  return copyOfEls;
};
