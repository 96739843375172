import React, {useState} from "react";
import RemoveModal from "../../core/removeModal";
import EditModal from "./editModal";
import {Button, Table as BTable} from "react-bootstrap";
import {impersonateUser} from "../../context/fetch/fetchUser";
import {useStateValue} from "../../context";
import {FaUserSecret, FaEdit, FaTimes} from "react-icons/fa";
import {removeUser} from "../../context/fetch/fetchUsers";
import {useUsers} from "../../context/reducers/usersContext";
import {useHistory} from "react-router-dom";
import {TiArrowSortedDown, TiArrowSortedUp} from "react-icons/ti";

const Table = ({
                   data,
                   header,
                   editToggle,
                   setEditToggle,
                   removeToggle,
                   setRemoveToggle,
                   sortQuery,
                   setSortValue
               }) => {
    const [activeUserId, setActiveUserId] = useState(null);
    const [userState, dispatch] = useStateValue();
    const [{users}, dispatchUsers] = useUsers();
    const history = useHistory();

    const handleUserEdit = (userId) => {
        setActiveUserId(userId);
        setEditToggle(true);
    };
    const handleUserRemove = (userId) => {
        setActiveUserId(userId);
        setRemoveToggle(true);
    };
    const handleImpersonate = (userId) => {
        impersonateUser(userId, dispatch, history);
    };

    const onOrder = (columnName) => (e) => {
        setSortValue((current)=>({
            columnName,
            sortAsc: (current.columnName !== columnName ? false : !current.sortAsc)
        }))
    }

    const row = (x, i, header) => {
        return (
            <tr key={i}>
                {header.map((y, k) => (
                    <td key={k}>{x[y.prop]}</td>
                ))}
                <td className="text-right user-btn-action">
                    <Button
                        variant={`outline-info ${
                            x.id === userState.user.user.id ? "none" : ""
                        }`}
                        className="mr-2"
                        onClick={() => handleUserEdit(x.id)}
                    >
                        <FaEdit/>
                    </Button>
                    <Button
                        variant={`outline-danger ${x.role === "ADMIN" ? "none" : ""}`}
                        className="mr-2"
                        onClick={() => handleUserRemove(x.id)}
                    >
                        <FaTimes/>
                    </Button>
                    {x.role === "PUBLISHER" && (
                        <Button
                            variant="outline-dark"
                            onClick={() => handleImpersonate(x.id)}
                        >
                            <FaUserSecret/>
                        </Button>
                    )}
                </td>
            </tr>
        );
    };

    return (
        <>
            <div>
                <div className="table-responsive">
                    {data ? (
                        <BTable
                            striped
                            bordered
                            hover
                            id="example1"
                            className="table data-table table-hover mb-0"
                            role="grid"
                        >
                            <thead className="thead-dark">
                            <tr>
                                {header.map((x, i) => (
                                    <th
                                        tabIndex="0"
                                        aria-controls="example"
                                        rowSpan="1"
                                        colSpan="1"
                                        key={i}
                                    >
                                        <div
                                            className="table-ordered-column d-flex justify-content-center align-items-center"
                                            {...(x.order && {onClick:onOrder(x.prop)})}
                                        >
                                            <div>
                                                {x.name}
                                            </div>
                                            {
                                                x.order
                                                    ? <div className="sorting-order-sign">
                                                            <TiArrowSortedUp className={(x.prop === sortQuery.columnName && !sortQuery.sortAsc) ? 'active':''}/>
                                                            <TiArrowSortedDown className={(x.prop === sortQuery.columnName && sortQuery.sortAsc) ? 'active':''}/>
                                                    </div>
                                                    : ''
                                            }
                                        </div>
                                    </th>
                                ))}
                                <th
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    style={{textAlign: "right"}}
                                >
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody>{data ? data.map((x, i) => row(x, i, header)) : ""}</tbody>
                        </BTable>
                    ) : (
                        <p>No some data</p>
                    )}
                </div>

                {editToggle && (
                    <EditModal id={activeUserId} onChangeToggle={setEditToggle}/>
                )}
                {removeToggle && (
                    <RemoveModal
                        onChangeToggle={setRemoveToggle}
                        remove={() => removeUser(activeUserId, dispatchUsers)}
                        loading={users.userRemoveLoading}
                    />
                )}
            </div>
        </>
    );
};
export default Table;
