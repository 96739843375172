import React, { memo } from "react";
import { filterComparableData } from "../../utils/comparison";
import {
  getSolidSocialMediaIcon,
  getOutlineSocialMediaIcon,
} from "../../utils/getSocialMediaIcon";
import { SOCIAL_MEDIA_ICONS_STYLE } from "../../configs/constants";

const SocialMediaData = ({ elData }) => {
  const getOrderedKeys = (list) => {
    const keys = Object.keys(list);
    keys.sort((a, b) => +a - +b);
    return keys;
  };

  return (
    <div className="socialMedia">
      <div className="socialMediaData social-media-icons">
        {getOrderedKeys(elData.list).map((key) => (
          <a
            className={`${elData.list[key].name}-${elData.iconStyle.type} ${
              elData.iconStyle.grayscale ? "gray" : ""
            } ${elData.list[key].url.identified ? "active" : "disabled"}  `}
            key={key}
            href={elData.list[key].url.value}
            target="_blank"
            onClick={
              elData.list[key].url.identified ? null : (e) => e.preventDefault()
            }
            rel="noopener noreferrer"
          >
            {elData.iconStyle.type === SOCIAL_MEDIA_ICONS_STYLE[0]
              ? getSolidSocialMediaIcon(elData.list[key].name)
              : getOutlineSocialMediaIcon(elData.list[key].name)}
          </a>
        ))}
      </div>
    </div>
  );
};

export default memo(SocialMediaData, filterComparableData);
