import React, { createContext, useContext, useReducer } from "react";

export const UsersContext = createContext(null);
export const UsersProvider = ({ reducer, initialState, children }) => (
  <UsersContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </UsersContext.Provider>
);
export const useUsers = () => useContext(UsersContext);

