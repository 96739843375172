import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required({ key: "name", value: "Name is required" }),
  email: yup
    .string()
    .email()
    .required({ key: "email", value: "Email is required" }),
  company: yup
    .string()
    .required({ key: "company", value: "Company is required" })
});

export default schema;
