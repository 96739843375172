import React from "react";
import { Form, Col, Row, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
const RegisterSucceed = () => {
  const { state } = useLocation();

  return (
    <Container className={"login-container"}>
      <Row className={"justify-content-center login-section"}>
        <Col md={7} lg={6} xl={5} className={"login-box"}>
          <Form>
            <h4 className="mb-4">Thank you for signing up with Ayscan</h4>
            <div className="text-justify mb-2">
              A confirmation email has been sent to you at{" "}
              <strong>{state?.email}</strong>. Please click on the link in the
              email to activate your account.
            </div>
            <div className="text-justify">
              If you do not receive the confirmation email within a few minutes
              please check your spam folder. If you still cannot find it please
              contact our <strong> support@ayscan.com</strong>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterSucceed;
