import React from "react";
import image from "../../assets/images/ayscan_logo-404.png";

const NotFound = () => {
  return (
    <div className="text-center not-found pt-2">
      <div className="position-relative my-3">
        <img src={image} alt="Ayscan Logo" width="250" />
        <p className="not-found-404 position-absolute">
          4<span className="invisible">0</span>4
        </p>
      </div>
      <p className="not-found-text">Oops! Sorry, we can't find the page!</p>
      <a href="/" className="btn btn-primary">
        Go back to main
      </a>
    </div>
  );
};

export default NotFound;
