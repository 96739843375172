import React, { useRef } from "react";
import { Col, Form, Button } from "react-bootstrap";
import { editFolder } from "../../context/fetch/fetchAssets";
import { useAssetsValue } from "../../context/reducers/assetsContext";
import { useHistory } from "react-router-dom";

const EditFolder = ({ setIndex, folderId }) => {
  const [state, dispatch] = useAssetsValue();
  const history = useHistory();
  const nameRef = useRef(null);

  const handleOnSaveClick = () => {
    const folderName = nameRef.current.value;
    const list = state.folder.list;
    editFolder({ folderId, folderName }, list, dispatch, setIndex, history);
  };

  const handleOnCancelClick = () => {
    setIndex(0);
  };

  const name = state.folder.list.filter((item) => item.id === folderId)[0].name;

  return (
    <div className={"folder-editor "}>
      <div className={"text-right folder-btn-group"}>
        <Button onClick={handleOnCancelClick} className={"btn btn-dark"}>
          Cancel
        </Button>
      </div>
      <Col>
        <Form className={"my-3"} onSubmit={(e) => e.preventDefault()}>
          <Form.Group controlId="formBasicText" className={"position-relative"}>
            <Form.Control
              type="text"
              defaultValue={name}
              autoFocus
              className={"focus-effect cursor-pointer"}
              ref={nameRef}
            />
            <span className={"focus-border"}></span>
          </Form.Group>
          <div className={"text-right"}>
            <Button
              type="submit"
              className={"btn-light"}
              onClick={handleOnSaveClick}
            >
              Save
            </Button>
          </div>
        </Form>
      </Col>
    </div>
  );
};

export default EditFolder;
