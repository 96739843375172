import axios from "axios";

export default class ProjectService {
  getProjectTypes = () => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/tags`,
      params: {
        type: "project_type",
      },
      withToken: true,
    });
  };

  getProjectStatuses = () => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/tags`,
      params: {
        type: "status",
      },
      withToken: true,
    });
  };

  addProject = (data) => {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/projects`,
      data,
      headers: {
        "Content-Type": "application/json",
      },
      withToken: true,
      // onUploadProgress: (progressEvent) => {
      //   const percent = Math.round(
      //     (progressEvent.loaded * 100) / progressEvent.total
      //   );
      //   onUploadProgress(percent, i);
      // },
      // isUpload: true,
    });
  };

  getProjects = (sortQuery) => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/projects${sortQuery ? "?sort=" + sortQuery : ''}`,
      withToken: true,
    });
  };

  getProject = (id,sortQuery) => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/projects/${id}${sortQuery ? "?sort=" + sortQuery : ''}`,
      withToken: true,
    });
  };

  deleteProject = (id) => {
    return axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API}/projects/${id}`,
      withToken: true,
    });
  };

  editProject = (data) => {
    return axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API}/projects/${data.id}`,
      data: data.data,
      withToken: true,
    });
  };

  addTag = (data) => {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/tags`,
      data,
      withToken: true,
    });
  };

  uploadScreens = (data, i, onUploadProgress) => {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/screens`,
      data,
      timeout: 0,
      withToken: true,
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onUploadProgress(percent, i);
      },
      isUpload: true,
    });
  };

  deleteScreen = ({ projectId, id }) => {
    return axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API}/screens/${id}`,
      data: {
        projectId,
      },
      withToken: true,
    });
  };

  editScreen = (data) => {
    return axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API}/screens/${data.id}`,
      data: data.data,
      withToken: true,
    });
  };

  setScreenData = (id, data) => {
    return axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API}/screens/${id}`,
      data: data,
      withToken: true,
    });
  };

  editTag = (data) => {
    return axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API}/tags/${data.id}`,
      data: data.obj,
      withToken: true,
    });
  };
  removeTag = (id) => {
    return axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API}/tags/${id}`,
      withToken: true,
    });
  };
}
