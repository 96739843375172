import {
  ATTEMPT_GET_FOLDERS,
  GET_FOLDERS_SUCCESS,
  ATTEMPT_SET_FOLDER,
  SET_FOLDER_SUCCESS,
  ATTEMPT_REMOVE_FOLDER,
  REMOVE_FOLDER_SUCCESS,
  ATTEMPT_GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  SET_ACTIVE_FOLDER_ID,
  ATTEMPT_SET_TEMPLATE,
  SET_TEMPLATE_SUCCESS,
  ATTEMPT_EDIT_FOLDER,
  EDIT_FOLDER_SUCCESS,
  REMOVE_FOLDER_FAIL,
} from "../../actions/templatesActions";

export const initialState = {
  folder: {
    list: [],
    activeFolderId: null,
    isFolderLoading: false,
    template: { list: [], isTmplsLoading: false },
  },
};

export const reducer = (state, { type, payload }) => {
  // console.log("type, payload", type, payload);
  switch (type) {
    case ATTEMPT_GET_FOLDERS:
      return {
        ...state,
        folder: { ...state.folder, isFolderLoading: true },
      };

    case GET_FOLDERS_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          list: payload.folders,
          activeFolderId: payload.activeFolderId,
          isFolderLoading: false,
        },
      };

    case ATTEMPT_SET_FOLDER:
      return {
        ...state,
        folder: { ...state.folder, isFolderLoading: true },
      };

    case SET_FOLDER_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          list: [...state.folder.list, payload],
          activeFolderId: payload.id,
          isFolderLoading: false,
          template: { list: [], isTmplsLoading: false },
        },
      };

    case ATTEMPT_EDIT_FOLDER:
      return {
        ...state,
        folder: { ...state.folder, isFolderLoading: true },
      };

    case EDIT_FOLDER_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          list: payload,
          isFolderLoading: false,
        },
      };

    case ATTEMPT_REMOVE_FOLDER:
      return {
        ...state,
        folder: { ...state.folder, isFolderLoading: true },
      };

    case REMOVE_FOLDER_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          list: payload.filteredList,
          activeFolderId: payload.id,
          isFolderLoading: false,
        },
      };

    case REMOVE_FOLDER_FAIL:
      return {
        ...state,
        folder: {
          ...state.folder,
          isFolderLoading: false,
        },
      };

    case ATTEMPT_GET_TEMPLATES:
      return {
        ...state,
        folder: {
          ...state.folder,
          template: { ...state.folder.template, isTmplsLoading: true },
        },
      };

    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          activeFolderId: payload.id,
          template: { list: [...payload.templates], isTmplsLoading: false },
        },
      };

    case SET_ACTIVE_FOLDER_ID:
      return {
        ...state,
        folder: {
          ...state.folder,
          activeFolderId: payload,
        },
      };

    case ATTEMPT_SET_TEMPLATE:
      return {
        ...state,
        folder: {
          ...state.folder,
          isFolderLoading: true,
          template: { ...state.folder.template, isTmplsLoading: true },
        },
      };

    case SET_TEMPLATE_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          isFolderLoading: false,
          template: {
            list: [...state.folder.template.list, payload],
            isTmplsLoading: false,
          },
        },
      };

    default:
      return state;
  }
};
