import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Loader } from "./core/Loader";
import logger from './utils/logger';
import * as Sentry from "@sentry/react";


logger.initalize();
ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <App />
    </Sentry.ErrorBoundary>
  </Suspense>,
  document.getElementById("root")
);

serviceWorker.unregister();
