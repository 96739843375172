import {
  GET_PROJECT_TYPES_SUCCESS,
  ADD_PROJECT_SUCCESS,
  GET_PROJECTS_SUCCESS,
  REMOVE_PROJECT_SUCCESS,
  PROJECT_EDITED_SUCCESS,
  SET_CURRENT_PATH,
} from "../../actions/projectActions";
import { removeFilter } from "../../helpers";

export const initialState = {
  prjList: [],
  typeList: [],
  currentPath: "/project",
};

export const reducer = (state, { type, payload }) => {
  // console.log("type, payload", type, payload);
  switch (type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        prjList: payload.prjList,
        currentPath: payload.currentPath,
      };

    case GET_PROJECT_TYPES_SUCCESS:
      return {
        ...state,
        typeList: payload,
        currentPath: "/project/add",
      };

    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        prjList: [...state.prjList, payload],
      };

    case REMOVE_PROJECT_SUCCESS:
      return {
        ...state,
        prjList: removeFilter(state.prjList, payload),
      };

    case PROJECT_EDITED_SUCCESS:
      return {
        ...state,
        prjList: payload,
      };

    case SET_CURRENT_PATH:
      return {
        ...state,
        currentPath: payload,
      };

    default:
      return state;
  }
};
