import {
  ATTEMPT_GET_FOLDERS,
  GET_FOLDERS_SUCCESS,
  ATTEMPT_SET_FOLDER,
  SET_FOLDER_SUCCESS,
  ATTEMPT_REMOVE_FOLDER,
  REMOVE_FOLDER_SUCCESS,
  ATTEMPT_GET_ASSETS,
  GET_ASSETS_SUCCESS,
  SET_ACTIVE_FOLDER_ID,
  ATTEMPT_SET_FILE,
  SET_FILE_SUCCESS,
  ATTEMPT_EDIT_FOLDER,
  EDIT_FOLDER_SUCCESS,
  REMOVE_FILE_SUCCESS,
} from "../../actions/assetsActions";

export const initialState = {
  folder: {
    list: [],
    activeFolderId: null,
    isFolderLoading: false,
    file: { list: [], isAssetsLoading: false },
  },
};

export const reducer = (state, { type, payload }) => {
  // console.log("type, payload", type, payload);
  switch (type) {
    case ATTEMPT_GET_FOLDERS:
      return {
        ...state,
        folder: { ...state.folder, isFolderLoading: true },
      };

    case GET_FOLDERS_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          list: payload.folders,
          activeFolderId: payload.activeFolderId,
          isFolderLoading: false,
        },
      };

    case ATTEMPT_SET_FOLDER:
      return {
        ...state,
        folder: { ...state.folder, isFolderLoading: true },
      };

    case SET_FOLDER_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          list: [...state.folder.list, payload],
          activeFolderId: payload.id,
          isFolderLoading: false,
          file: { list: [], isAssetsLoading: false },
        },
      };

    case ATTEMPT_EDIT_FOLDER:
      return {
        ...state,
        folder: { ...state.folder, isFolderLoading: true },
      };

    case EDIT_FOLDER_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          list: payload,
          isFolderLoading: false,
        },
      };

    case ATTEMPT_REMOVE_FOLDER:
      return {
        ...state,
        folder: { ...state.folder, isFolderLoading: true },
      };

    case REMOVE_FOLDER_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          list: payload.filteredList,
          activeFolderId: payload.id,
          isFolderLoading: false,
        },
      };

    case ATTEMPT_GET_ASSETS:
      return {
        ...state,
        folder: {
          ...state.folder,
          file: { ...state.folder.file, isAssetsLoading: true },
        },
      };

    case GET_ASSETS_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          activeFolderId: payload.id,
          file: { list: [...payload.assets], isAssetsLoading: false },
        },
      };

    case SET_ACTIVE_FOLDER_ID:
      return {
        ...state,
        folder: {
          ...state.folder,
          activeFolderId: payload,
        },
      };

    case ATTEMPT_SET_FILE:
      return {
        ...state,
        folder: {
          ...state.folder,
          isFolderLoading: true,
          file: { ...state.folder.file, isAssetsLoading: true },
        },
      };

    case SET_FILE_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          isFolderLoading: false,
          file: {
            list: [...state.folder.file.list, ...payload],
            isAssetsLoading: false,
          },
        },
      };

    case REMOVE_FILE_SUCCESS:
      return {
        ...state,
        folder: {
          ...state.folder,
          file: {
            list: payload,
            isAssetsLoading: false,
          },
        },
      };

    default:
      return state;
  }
};
