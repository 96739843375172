import React, { useRef } from "react";
import { useStateValue } from "../../context";
import { getActiveEl } from "../../utils/getActiveEl";
import { updateElState } from "../../context/actions";
import {
  setDurationVal,
  setRemoveImage,
  setSliderImageVal,
  setAddImage,
} from "../../utils/setStateValues";
import AssetsModal from "../../../core/AssetsModal";

const Slider = () => {
  const [{ layout }, dispatch] = useStateValue();
  const durRef = useRef(null);

  const SD = getActiveEl(layout);
  const els = layout.elements;
  const elId = layout.activeEl.id;

  const handleOnFileChange = (data) => {
    const URL = data.files[0].file;
    const shellID = data.shellID;
    const result = setSliderImageVal(URL, els, elId, shellID);
    dispatch(updateElState(result));
  };

  const handleOnDurationChange = () => {
    const result = setDurationVal(durRef, els, elId);
    dispatch(updateElState(result));
  };

  const handleOnRemoveImage = (key) => {
    const result = setRemoveImage(key, els, elId);
    dispatch(updateElState(result));
  };

  const handleOnAddImages = (params) => {
    const result = params.files.reduce(
      (obj, file) => setAddImage(obj, elId, file.file),
      els
    );
    dispatch(updateElState(result));
  };

  return (
    <div className="slider">
      <h4>Slider</h4>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="slider-row-1 mb-3">
          <label className="mb-0 mr-3">Duration (milliseconds): </label>
          <input
            className="custom-input"
            type="number"
            min="0"
            value={SD.elData.duration}
            placeholder="seconds"
            ref={durRef}
            onChange={handleOnDurationChange}
          />
        </div>
        <div className="slider-images">
          {Object.keys(SD.elData.imgSrc).map((key) => (
            <div className="image-uploader" key={key}>
              <AssetsModal
                callBackFile={handleOnFileChange}
                callLocation={`${SD.elLabel}-image-one`}
                shellID={key}
              >
                <div className="image-preview">
                  {SD.elData.imgSrc[key] && (
                    <img src={SD.elData.imgSrc[key]} alt="img" />
                  )}
                </div>
              </AssetsModal>
              <div className="image-tools">
                <span
                  className="image-remove"
                  onClick={() => handleOnRemoveImage(key)}
                >
                  Remove
                </span>
              </div>
            </div>
          ))}
        </div>
        <AssetsModal
          callBackFile={handleOnAddImages}
          callLocation={`${SD.elLabel}-image-multiple`}
        >
          <input type="button" value="Add Images" />
        </AssetsModal>
      </form>
    </div>
  );
};

export default Slider;
