import React, { useState, useEffect } from "react";
import Table from "./tagsTable";
import { useTeg } from "../../context/reducers/tegContext";
import AddModal from "./addModal";
import {
  getProjectTypesSuccess,
  attemptProjectTypes,
  getProjectTypesFail,
} from "../../context/actions/projectActions";
import { getProjectTypes } from "../../context/fetch/fetchProjects";
import { Loader } from "../../core/Loader";

const ProjectTypes = ({ btnName }) => {
  const [editIdx, setEditIdx] = useState(-1);
  const [removeToggle, setRemoveToggle] = useState(false);

  const [project, dispatch] = useTeg();
  useEffect(() => {
    let isCancelled = false;

    const fetchTypes = async () => {
      try {
        attemptProjectTypes();
        const data = await getProjectTypes(dispatch);
        !isCancelled && dispatch(getProjectTypesSuccess(data));
      } catch (error) {
        getProjectTypesFail();
      }
    };

    fetchTypes();

    return () => (isCancelled = true);
  }, [dispatch]);

  const startEditing = (i) => {
    setEditIdx(i);
  };

  const stopEditing = () => {
    setEditIdx(-1);
  };
  return (
    <div className={"mt-2 text-right"}>
      <AddModal type="PROJECT_TYPE" value={btnName} />
      {project.project.projectTypesLoading ? (
        <Loader />
      ) : (
        <>
          <Table
            data={project.project.projectTypes}
            header={[
              {
                name: "Name",
                prop: "title",
                type: "text",
              },
            ]}
            editIdx={editIdx}
            startEditing={startEditing}
            stopEditing={stopEditing}
            removeToggle={removeToggle}
            setRemoveToggle={setRemoveToggle}
            dispatch={dispatch}
          />
        </>
      )}
    </div>
  );
};

export default ProjectTypes;
