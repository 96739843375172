import React from "react";
import { Col, Row, Container } from "react-bootstrap";

const ResetPasswordSuccess = () => {
  return (
    <Container className={"login-container"}>
      <Row className={"justify-content-center login-section"}>
        <Col md={7} lg={6} xl={5} className={"login-box"}>
          <p>
            We've emailed you instructions for setting your password, if an
            account exists with the email you entered. You should receive them
            shortly.
          </p>
          <p>
            If you don't receive an email, please make sure you've entered the
            address you registerd with, and check your spam folder.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordSuccess;
