import React from "react";
import { Form } from "react-bootstrap";
import { setWebUrlVal } from "../../../editor/utils/setStateValues";
import { areEqual } from "../../../editor/utils/comparison";
import {updateElementData} from "../../../editor/utils/updateElData";

const Web = ({ elId, cards, setCards, isEdited, setIsEdited }) => {
  const urlRef = React.useRef(null);
  const elData = cards[elId].elData;

  const handleOnURLChange = () => {
    const updatedCards = setWebUrlVal(urlRef, cards, elId);
    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  const showInBrowser = (event) => {
    const new_window = event.target.checked;
    const updatedCards = updateElementData(cards, elId,{new_window});
    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  }

  return (
    <Form.Group className="image">
      <h4>Web</h4>
      <Form.Group>
        <Form.Label>Link Web: </Form.Label>
        <Form.Control
          type="url"
          required
          placeholder="URL"
          ref={urlRef}
          onChange={handleOnURLChange}
          defaultValue={elData.url.value}
          style={{ borderBottomColor: elData.url.isValid ? "green" : "red" }}
        />
        <Form.Check
            type="checkbox"
            label={'Open in a new window'}
            name={'new_window'}
            onChange={showInBrowser}
            defaultChecked={elData.new_window}
        />
      </Form.Group>
    </Form.Group>
  );
};

export default React.memo(Web, areEqual);
