export const setScrollPosition = (dashMainBodyRef, dashMainRef, activeElID) => {
  const cardsHTMLCollection = dashMainBodyRef.current.children;
  const activeElement = cardsHTMLCollection[activeElID];

  if (activeElID && activeElement) {
    // eslint-disable-next-line
    const position = activeElement.getBoundingClientRect();
    // const top = position.top;
    // const body = dashMainRef.current.children[1];
    // body.scrollTop = top - 400;
    // const currentOffset = activeElement[0].offsetTop;
    // dashMainBodyRef.current.scrollTop =
    //   currentOffset - (100 + dashMainRef.current.offsetTop);
  }
};
