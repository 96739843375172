import * as yup from "yup";

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required({ key: "email", value: "Email is required" }),
  password: yup
    .string()
    .min(6, {
      key: "password",
      value: "Password is too short - should be 6 chars minimum."
    })
    .required({ key: "password", value: "Password is required" })
});

export default schema;
