import React from "react";
import {TiArrowSortedDown, TiArrowSortedUp} from "react-icons/ti";


const SortingList = (props) => {

    const {label,columns,value} = props;

    const onChange = (columnName) => (e) => {
        props.setSortValue((current)=>({
            columnName,
            sortAsc: (current.columnName !== columnName ? false : !current.sortAsc)
        }))
    }

    return (
        <div className="sorting-tools-wrapper">
            <strong>{label}:</strong>
            {
                value && columns && columns.length
                ?
                    columns.map((column,index)=>{
                        return(
                            <div className={`sorting-item ${column.columnName === value.columnName ? 'active' : ''}`} key={index} onClick={onChange(column.columnName)}>
                                <div>{column.label}</div>
                                <div className="sorting-order-sign">
                                    {
                                        !value.sortAsc && column.columnName === value.columnName
                                        ? <TiArrowSortedUp/>
                                        : <TiArrowSortedDown/>
                                    }
                                </div>
                            </div>
                        )
                    })
                :''
            }
        </div>
    );
};

SortingList.defaultProps = {
    label: "Sort By",
    columns: [],
    value: null
}


export default SortingList;
