import {
  ATTEMPT_USERS,
  FETCH_USERS_SUCCEED,
  ATTEMPT_EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  ATTEMPT_ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  ATTEMPT_REMOVE_USER,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_FAIL,
  ATTEMPT_USER,
  FETCH_USER_SUCCEED,
} from "../../actions/usersActions";
import { removeFilter, editFilter } from "../../helpers";

export const initialState = {
  users: {
    users: [],
    user: {},
    usersLoading: true,
    userLoading: true,
    userRemoveLoading: false,
    userEditLoading: false,
    userAddLoading: false,
    count: 0,
  },
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case ATTEMPT_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          usersLoading: true,
        },
      };
    case FETCH_USERS_SUCCEED:
      return {
        ...state,
        users: {
          ...state.users,
          users: payload.data,
          count: payload.count,
          usersLoading: false,
        },
      };
    case ATTEMPT_USER:
      return {
        ...state,
        users: {
          ...state.users,
          userLoading: true,
        },
      };
    case FETCH_USER_SUCCEED:
      return {
        ...state,
        users: {
          ...state.users,
          user: payload,
          userLoading: false,
        },
      };
    case ATTEMPT_EDIT_USER:
      return {
        ...state,
        users: {
          ...state.users,
          userEditLoading: true,
        },
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          users: editFilter(state.users.users, payload.obj, payload.id),
          userEditLoading: false,
        },
      };
    case EDIT_USER_FAIL:
      return {
        ...state,
        users: {
          ...state.users,
          userEditLoading: false,
        },
      };
    case ATTEMPT_ADD_USER:
      return {
        ...state,
        users: {
          ...state.users,
          userAddLoading: true,
        },
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          users: [...state.users.users, payload],
          userAddLoading: false,
        },
      };
    case ADD_USER_FAIL:
      return {
        ...state,
        users: {
          ...state.users,
          userAddLoading: false,
        },
      };
    case ATTEMPT_REMOVE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          userRemoveLoading: true,
        },
      };
    case REMOVE_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          users: removeFilter(state.users.users, payload),
          userRemoveLoading: false,
        },
      };
    case REMOVE_USER_FAIL:
      return {
        ...state,
        users: {
          ...state.users,
          userRemoveLoading: false,
        },
      };

    default:
      return state;
  }
};
