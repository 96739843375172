import React, { memo, useEffect, useState } from "react";
import { filterComparableData } from "../../utils/comparison";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const SliderData = ({ elData }) => {
  const [isDidMount, setDidMount] = useState(false);
  useEffect(() => setDidMount(true), []);

  const list = Object.values(elData.imgSrc).filter((imgSrc) => imgSrc);
  const keys = Object.keys(elData.imgSrc);

  return (
    <div className="sliderData">
      {isDidMount && list.length ? (
        <Carousel
          interval={elData.duration}
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
        >
          {list.map((src, i) => (
            <div key={keys[i]}>
              <img src={src} alt="img" />
            </div>
          ))}
        </Carousel>
      ) : (
        <div className="sliderData-initialView">Choose image from assets</div>
      )}
    </div>
  );
};

export default memo(SliderData, filterComparableData);
