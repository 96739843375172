import React, {useEffect, useMemo, useState} from "react";
import Pagination from "react-js-pagination";
import Table from "./table";
import {useUsers} from "../../context/reducers/usersContext";
import {fetchUsers, removeUser} from "../../context/fetch/fetchUsers";
import AddModal from "./addModal";
import {Container, Row, Col, Spinner} from "react-bootstrap";
import {fetchUsersSucceed} from "../../context/actions/usersActions";

const Main = () => {
    const [editToggle, setEditToggle] = useState(false);
    const [removeToggle, setRemoveToggle] = useState(false);

    const [activePage, setActivePage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [users, dispatch] = useUsers();

    const [limit, setLimit] = useState(10);

    const initialState = useMemo(() => {
        return {
            columnName: 'name' ,
            sortAsc: true
        }
    }, [])
    const [sortQuery, setSortValue] = useState(initialState);

    useEffect(() => {
        let isCancelled = false;
        const sort = `${sortQuery.columnName}:${sortQuery.sortAsc ? 'asc' : 'desc'}`;

        fetchUsers(dispatch, offset, limit,sort).then(
            (data) => !isCancelled && dispatch(fetchUsersSucceed(data))
        );
        return () => (isCancelled = true);
    }, [dispatch, offset, limit, users.count,sortQuery]);

    const handlePageChange = (page) => {
        setActivePage(page);
        setOffset(limit * (page - 1));
    };

    const onPaginationSizeChange = (event) => {
        const newLimit = Number(event.target.value);
        const page = Math.ceil(users.users.count / Number(newLimit));
        if (activePage > page){
            setActivePage(page);
        }
        setOffset(0);
        setLimit(newLimit)
    }

    return (
        <>
            <Container className={"editor-container user-manag"}>
                <Row className={"justify-content-between align-items-end mb-2"}>
                    <Col className={"col-auto"}>
                        <h4 className={"text-uppercase editor-title mb-0"}>Users List</h4>
                    </Col>
                    <Col className={"text-right"}>
                        <AddModal/>
                    </Col>
                </Row>
                {users.users.users && (
                    <div className="data-table-wrapper">
                        <div style={{position: "relative"}}>
                            {users.users.usersLoading && (
                                <div className="loader-table-wrapper">
                                    <Spinner
                                        animation="border"
                                        variant="primary"
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                        }}
                                    />
                                </div>
                            )}
                            <Table
                                data={users.users.users}
                                header={[
                                    {
                                        name: "Name",
                                        prop: "name",
                                        type: "text",
                                        order: true,
                                    },
                                    {
                                        name: "Email",
                                        prop: "email",
                                        type: "email",
                                        order: false
                                    },
                                    {
                                        name: "Role",
                                        prop: "role",
                                        type: "text",
                                        order: false
                                    },
                                ]}
                                changeItem={removeUser}
                                dispatch={dispatch}
                                editToggle={editToggle}
                                setEditToggle={setEditToggle}
                                removeToggle={removeToggle}
                                setRemoveToggle={setRemoveToggle}
                                sortQuery={sortQuery}
                                setSortValue={setSortValue}
                            />
                            <div
                                className="border-top pt-5 d-flex justify-content-sm-start justify-content-center align-items-center">
                                <div style={{display: users.users.usersLoading && "none"}}
                                     className="d-flex align-items-center">
                                    <Pagination
                                        innerClass="pagination"
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        hideFirstLastPages
                                        pageRangeDisplayed={limit}
                                        activePage={activePage}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={users.users.count}
                                        onChange={handlePageChange}
                                    />
                                </div>
                                <div>
                                    <select className="pagination-options-size-changer"
                                            onChange={onPaginationSizeChange}>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Container>
        </>
    );
};
export default Main;
