import React, { useRef } from "react";
import { useStateValue } from "../../context";
import {
  updateElState,
  updateFooter,
  updateHeader,
} from "../../context/actions";
import { getActiveEl } from "../../utils/getActiveEl";
import { FaRegImage } from "react-icons/fa";
import {
  setImageUrlVal,
  setFooterImageUrlVal,
  setFooterImageSrclVal,
  setHeaderFileVal,
} from "../../utils/setStateValues";

import { updateElementData } from "../../utils/updateElData";
import {
  ELEMENT_FOOTER_IDS,
  ELEMENT_HEADER_IDS,
} from "../../configs/constants";
import AssetsModal from "../../../core/AssetsModal";
import { calculateHeight } from "../../utils/calculateHeight";

const Imagee = () => {
  const [{ layout }, dispatch] = useStateValue();
  const urlRef = useRef(null);

  const els = layout.elements;
  const elId = layout.activeEl.id;

  const ID = getActiveEl(layout);

  const handleOnFileChanage = async (data) => {
    const src = data?.files[0].file || "";

    if (ID.elLabel === ELEMENT_FOOTER_IDS[0]) {
      const newHeight = await calculateHeight(src);
      const footerData = setFooterImageSrclVal(src, newHeight, layout);
      dispatch(updateFooter(footerData));
    } else if (ID.elLabel === ELEMENT_HEADER_IDS[0]) {
      const newHeight = await calculateHeight(src);
      const result = setHeaderFileVal(src, newHeight, layout);
      dispatch(updateHeader(result));
    } else {
      const result = updateElementData(els, elId, {
        imgSrc: src,
      });
      dispatch(updateElState(result));
    }
  };

  const handleOnURLChange = () => {
    if (ID.elLabel === ELEMENT_FOOTER_IDS[0]) {
      const footerData = setFooterImageUrlVal(urlRef, layout);
      dispatch(updateFooter(footerData));
    } else {
      const elData = setImageUrlVal(urlRef, els, elId);
      dispatch(updateElState(elData));
    }
  };

  const isHidden = ID.elLabel === ELEMENT_HEADER_IDS[0];

  return (
    <div className="image">
      <h4>{ID.elLabel}</h4>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={`image-source ${isHidden ? "none" : ""} `}>
          <label className="pt-4 mb-0 mr-3">Link Image: </label>
          <input
            className="custom-input"
            type="url"
            placeholder="URL"
            ref={urlRef}
            onChange={handleOnURLChange}
            value={ID.elData.url.value}
            style={{
              borderBottomColor: ID.elData.url.isValid ? "green" : "red",
            }}
          />
        </div>
      </form>

      <AssetsModal
        callBackFile={handleOnFileChanage}
        callLocation={`${ID.elLabel}-image-one`}
      >
        <div className="image-preview">
          {ID.elData.imgSrc ? (
            <img src={ID.elData.imgSrc} alt="img" />
          ) : (
            <div className="icon-wrapper">
              <FaRegImage size="70px" />
            </div>
          )}
        </div>
      </AssetsModal>
      <button onClick={() => handleOnFileChanage()}>Clear Image</button>
    </div>
  );
};

export default Imagee;
