import {
  updateElementData,
  updateHeaderData,
  updateFooterData,
} from "../updateElData";
import { detectProvider } from "../detectProvider";
import { isUrlValid } from "../urlValidation";
import identSocMediaURL from "../identSocMediaURL";
import uuid from "uuid";
import { ascendingKey } from "../ascendingKey";

export const setHeaderOrder = (layout) => {
  const logoOrder = layout.header.image.order;
  const headlineOrder = layout.header.text.order;

  const header = {
    text: { ...layout.header.text, order: logoOrder },
    image: { ...layout.header.image, order: headlineOrder },
  };
  return header;
};

export const setHeaderFileVal = (logoURL, height, layout) => {
  return updateHeaderData(layout, "image", {
    imgSrc: logoURL,
    styles: { height },
  });
};

export const setHeaderStyle = (styleValues, layout) => {
  return updateHeaderData(layout, "image", {
    styles: { ...styleValues },
  });
};

export const setHeaderTextVal = (markup, hasText, layout) => {
  return updateHeaderData(layout, "text", {
    markup,
    hasText,
  });
};

export const setFooterOrder = (layout, newOrder) => {
  const image = layout.footer.image;
  const text = layout.footer.text;
  const socialMedia = layout.footer.socialMedia;

  let imageOrder = null;
  let textOrder = null;
  let socialMediaOrder = null;

  newOrder.forEach((item, order) => {
    if (item.Component.type.name === "Image") {
      imageOrder = order;
    } else if (item.Component.type.name === "Subhead") {
      textOrder = order;
    } else if (item.Component.type.name === "SocialMedias") {
      socialMediaOrder = order;
    }
  });

  const footer = {
    image: { ...image, order: imageOrder },
    text: { ...text, order: textOrder },
    socialMedia: { ...socialMedia, order: socialMediaOrder },
  };
  return footer;
};

export const setFooterTextVal = (markup, hasText, layout) => {
  return updateFooterData(layout, "text", {
    markup,
    hasText,
  });
};

export const setFooterStyle = (styleValues, layout) => {
  return updateFooterData(layout, "image", {
    styles: { ...styleValues },
  });
};

export const setFooterImageSrclVal = (imgSrc, height, layout) => {
  return updateFooterData(layout, "image", {
    imgSrc,
    styles: { height },
  });
};

export const setFooterImageUrlVal = (urlRef, layout) => {
  const url = urlRef.current.value.trim();
  return updateFooterData(layout, "image", {
    url: { value: url, isValid: isUrlValid(url) },
  });
};

export const setFooterSocMediaGrayscale = (grayscale, layout) => {
  const iconStyle = layout.footer.socialMedia.iconStyle;

  return updateFooterData(layout, "socialMedia", {
    iconStyle: { ...iconStyle, grayscale },
  });
};

export const setFooterSocMediaStyleType = (type, layout) => {
  const iconStyle = layout.footer.socialMedia.iconStyle;

  return updateFooterData(layout, "socialMedia", {
    iconStyle: { ...iconStyle, type },
  });
};

export const setFooterAddSocialMedia = (layout, name) => {
  const list = {
    ...layout.footer.socialMedia.list,
    [uuid.v4()]: {
      name,
      url: { value: "", identified: null },
    },
  };

  return updateFooterData(layout, "socialMedia", {
    iconStyle: layout.footer.socialMedia.iconStyle,
    list,
  });
};

export const setFooterRemoveSocialMedia = (layout, key) => {
  const { [key]: value, ...filteredList } = layout.footer.socialMedia.list;

  return updateFooterData(layout, "socialMedia", {
    list: filteredList,
  });
};

export const setFooterSocMediaUrlVal = (url, layout, key) => {
  const socialMedias = layout.footer.socialMedia.list;
  const name = socialMedias[key].name;

  const list = {
    ...socialMedias,
    [key]: {
      ...socialMedias[key],
      url: {
        value: url,
        identified: identSocMediaURL(url, name),
      },
    },
  };
  return updateFooterData(layout, "socialMedia", {
    list,
  });
};

export const setTextVal = (markup, els, elId) => {
  return updateElementData(els, elId, {
    markup,
  });
};

export const setUrlVal = (urlRef, els, elId) => {
  const url = urlRef.current.value.trim();
  const provider = detectProvider(url);
  return updateElementData(els, elId, {
    url: { value: url },
    provider,
  });
};

export const setStyleVals = (height, els, elId) => {
  return updateElementData(els, elId, {
    styles: { height },
  });
};

export const setVideoFormatVals = (
  { autoplayRef, loopRef, controlRef },
  els,
  elId
) => {
  const autoplay = autoplayRef.current.checked;
  const loop = loopRef.current.checked;
  const control = controlRef.current.checked;

  return updateElementData(els, elId, {
    videoFormat: { autoplay, loop, control },
  });
};

export const setDurationVal = (durRef, els, elId) => {
  const duration = Number(durRef.current.value);
  return updateElementData(els, elId, { duration });
};

export const setRemoveImage = (key, els, elId) => {
  const imgSrc = els[elId].elData.imgSrc;
  const { [key]: value, ...filteredImgSrces } = imgSrc;

  return updateElementData(els, elId, {
    imgSrc: filteredImgSrces,
  });
};

export const setAddImage = (els, elId, URL = "") => {
  const imgSrc = els[elId].elData.imgSrc;
  return updateElementData(els, elId, {
    imgSrc: { ...imgSrc, [uuid.v4()]: URL },
  });
};

export const setAudioUrlVal = (url, els, elId) => {
  return updateElementData(els, elId, {
    url: { value: url, isValid: isUrlValid(url) },
  });
};

export const setImageUrlVal = (urlRef, els, elId) => {
  const url = urlRef.current.value.trim();
  return updateElementData(els, elId, {
    url: { value: url, isValid: isUrlValid(url) },
  });
};

export const setWebUrlVal = (urlRef, els, elId) => {
  const url = urlRef.current.value.trim();
  return updateElementData(els, elId, {
    url: { value: url, isValid: isUrlValid(url) },
  });
};

export const setSliderImageVal = (url, els, elId, key) => {
  const imgSrc = {
    ...els[elId].elData.imgSrc,
    [key]: url,
  };

  return updateElementData(els, elId, { imgSrc });
};

export const setSocialMediaUrlVal = (url, els, elId, key) => {
  const activeEl = els[elId];
  const name = activeEl.elData.list[key].name;

  const list = {
    ...activeEl.elData.list,
    [key]: {
      ...activeEl.elData.list[key],
      url: {
        value: url,
        identified: identSocMediaURL(url, name),
      },
    },
  };

  return updateElementData(els, elId, {
    list,
  });
};

export const setSocialMediaStyleType = (type, els, elId) => {
  const iconStyle = els[elId].elData.iconStyle;

  return updateElementData(els, elId, {
    iconStyle: { ...iconStyle, type },
  });
};

export const setSocialMediaGrayscale = (grayscale, els, elId) => {
  const iconStyle = els[elId].elData.iconStyle;

  return updateElementData(els, elId, {
    iconStyle: { ...iconStyle, grayscale },
  });
};

export const setRemoveSocialMedia = (els, elId, key) => {
  const activeEl = els[elId];

  const { [key]: value, ...filteredList } = activeEl.elData.list;

  return updateElementData(els, elId, {
    list: filteredList,
  });
};

export const setAddSocialMedia = (els, elId, name) => {
  const activeEl = els[elId];

  const list = {
    ...activeEl.elData.list,
    [ascendingKey()]: {
      name,
      url: { value: "", identified: null },
    },
  };

  return updateElementData(els, elId, {
    iconStyle: activeEl.elData.iconStyle,
    list,
  });
};

export const setZoomVal = (addressRef, els, elId) => {
  const zoom = addressRef.current.state.value;
  const asd = updateElementData(els, elId, {
    zoom,
  });
  return asd;
};

export const setAddressVal = (value, els, elId) => {
  // const url = addressRef.current.value;
  return updateElementData(els, elId, {
    address: { value },
  });
};

export const setBtnUrlVal = (urlRef, els, elId) => {
  const url = urlRef.current.value.trim();
  return updateElementData(els, elId, {
    url: { value: url, isValid: isUrlValid(url) },
  });
};

export const setBorderTypeVal = (borderTypeRef, els, elId) => {
  const borderType = borderTypeRef.current.value;
  return updateElementData(els, elId, {
    borderType,
  });
};
export const setFontSizeVal = (fontSizeRef, els, elId) => {
  const fontSize = Number(fontSizeRef.current.value);
  return updateElementData(els, elId, {
    fontSize,
  });
};

export const setBorderWidthVal = (borderWidthRef, els, elId) => {
  const borderWidth = borderWidthRef.current.value;
  return updateElementData(els, elId, {
    borderWidth,
  });
};

export const setBorderColorVal = (borderColorRef, els, elId) => {
  const borderColor = borderColorRef.current.value;
  return updateElementData(els, elId, {
    borderColor,
  });
};

export const setBtnTextVal = (btnTextRef, els, elId) => {
  const btnText = btnTextRef.current.value;
  return updateElementData(els, elId, {
    btnText,
  });
};

export const setBgColorVal = (bgColorRef, els, elId) => {
  const bgColor = bgColorRef.current.value;
  return updateElementData(els, elId, {
    bgColor,
  });
};

export const setTextColorVal = (textColorRef, els, elId) => {
  const textColor = textColorRef.current.value;
  return updateElementData(els, elId, {
    textColor,
  });
};
