import React, { memo } from "react";
import { filterComparableData } from "../../utils/comparison";

const DividerData = ({ elData }) => {
  const { borderType, borderWidth, borderColor } = elData;
  return (
    <div
      className="dividerData"
      style={{ margin: "30px 0", padding: "1px 0 0 0" }}
    >
      <hr
        style={{ borderTop: `${borderWidth}px ${borderType} ${borderColor}` }}
      />
    </div>
  );
};

export default memo(DividerData, filterComparableData);
