import React, { useRef, memo, useState, useEffect } from "react";
import { useStateValue } from "../../context";
import { setTemplate, setTmplName, removeTemplate } from "../../middleware";
import { areEqual } from "../../utils/comparison";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import RemoveModal from "./RemoveModal";
import {
  attemptSetTemplateName,
  setTemplateNameSuccess,
  attemptRemoveTemplate,
  setIsEdited,
} from "../../context/actions";
import TemplatesModal from "../../../core/TemplatesModal";
import CustomPopup from "../../../core/CustomPopup";
import {popupCenter} from "../../../utils/popupCenter";
import {REACT_APP_PREVIEW_URL} from "../../../configs/constants";

const Header = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setLoading] = useState(false);
  const [isRenamed, setRenamed] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();
  const nameRef = useRef(null);
  const deleteRef = useRef(null);

  useEffect(() => {
    const id = setTimeout(() => {
      setShowPopup(false);
    }, 2000);
    return () => {
      clearTimeout(id);
    };
  }, [showPopup]);

  const handleOnNameChange = () => {
    if (!isRenamed) {
      setRenamed(true);
      dispatch(setIsEdited(true));
    }
  };

  const handleOnSaveClick = async (e) => {
    const name = nameRef.current.value;
    const body = JSON.stringify(state.layout);

    try {
      setLoading(true);
      dispatch(setIsEdited(false));
      await setTemplate({ name, body, id: state.id });
      history.goBack();
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const handleOnRenameClick = async () => {
    const name = nameRef.current.value;
    const id = state.id;

    try {
      dispatch(attemptSetTemplateName());
      const response = await setTmplName({ name, id });
      setRenamed(false);
      dispatch(setTemplateNameSuccess(response.data.name));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleOnRemoveClick = async () => {
    if (state.isPinned) {
      setShowPopup(true);
    } else {
      const id = state.id;
      try {
        dispatch(attemptRemoveTemplate());
        await removeTemplate(id);
        history.goBack();
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleOnPreviewClick = () => {
    const token = localStorage.token;
    console.log('state', state)
    popupCenter(
        `${REACT_APP_PREVIEW_URL}/template/${state.id}?token=${token}`,
        "preview",
        360,
        600
    );
  };

  return (
    <div
      className={`main-header row justify-content-between align-items-md-center flex-column flex-md-row ${
        state.isHeaderDisable || isLoading ? "eventDisabled" : ""
      }`}
    >
      <div className="template-name col-auto mb-3 mb-md-0 pl-0">
        <input
          defaultValue={state.templateName}
          onChange={handleOnNameChange}
          type="text"
          placeholder="Type template name"
          ref={nameRef}
          className="custom-input"
        />
      </div>
      <div className="col-auto mb-3 mb-md-0 p-0 d-flex">
        <Button
          variant={`outline-secondary ${
            !isRenamed || state.isHeaderDisable ? "disabled" : ""
          }`}
          onClick={handleOnRenameClick}
          style={{ width: "110px" }}
        >
          {state.isHeaderDisable ? "Renaming" : "Rename"}
        </Button>
        <CustomPopup
          title="Not Allowed"
          message="Pinned Template"
          show={showPopup}
          targetRef={deleteRef}
        >
          <RemoveModal
            handleOnRemoveClick={handleOnRemoveClick}
            isPinned={state.isPinned}
            ref={deleteRef}
            setShowPopup={setShowPopup}
          />
        </CustomPopup>
      </div>
      <Button className="col-auto mb-3 mb-md-0 btn-preview" variant="secondary" onClick={handleOnPreviewClick}>
        Preview
      </Button>
      <div className="col-auto mb-3 mb-md-0 p-0">
        <TemplatesModal callLocation={"header-save-as"} layout={state.layout}>
          <Button className="btn-save-as" variant="primary" disabled={false}>
            Save As
          </Button>
        </TemplatesModal>
        <Button
          className="btn-save-template"
          variant="primary"
          disabled={isLoading ? true : false}
          onClick={handleOnSaveClick}
        >
          {isLoading ? "Saving" : "Save Template"}
        </Button>
      </div>
    </div>
  );
};

export default memo(Header, areEqual);
