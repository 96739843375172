import React from "react";
import { Route } from "react-router-dom";
import Auth from "../../configs/Auth";
import LoggedInLayout from "../../layouts/LoggedInLayout";
import GuestLayout from "../../layouts/GuestLayout";

const NotFoundRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.isAuthenticated() ? (
          <LoggedInLayout {...rest}>
            <Component {...props} />
          </LoggedInLayout>
        ) : (
          <GuestLayout>
            <Component {...props} />
          </GuestLayout>
        )
      }
    />
  );
};

export default NotFoundRoute;
