import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import { useStateValue } from "../../context";
import { getActiveEl } from "../../utils/getActiveEl";
import { setAudioUrlVal } from "../../utils/setStateValues";
import { updateElState } from "../../context/actions";
import { FORMAT_LIST } from "../../configs/constants";
import { setVideoFormatVals } from "../../utils/setStateValues";
import AssetsModal from "../../../core/AssetsModal";

const Audio = () => {
  const [{ layout }, dispatch] = useStateValue();
  const els = layout.elements;
  const elId = layout.activeEl.id;

  const urlRef = useRef(null);

  const [autoplayRef, loopRef, controlRef] = [
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const arrOfRefs = [autoplayRef, loopRef, controlRef];

  const AD = getActiveEl(layout);

  const handleOnAudioFormatChange = () => {
    const result = setVideoFormatVals(
      { autoplayRef, loopRef, controlRef },
      els,
      elId
    );
    dispatch(updateElState(result));
  };

  const handleOnURLChange = () => {
    const url = urlRef.current.value.trim();
    const elData = setAudioUrlVal(url, els, elId);
    dispatch(updateElState(elData));
  };

  const handleOnFileChange = (params) => {
    const URL = params.files[0].file;
    const elData = setAudioUrlVal(URL, els, elId);
    dispatch(updateElState(elData));
  };

  return (
    <div className="audioSettings">
      <h4>Audio</h4>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="audioSettings-source">
          <label className="pt-4 mb-0 mr-3">Source: </label>
          <input
            className="custom-input"
            placeholder="URL"
            ref={urlRef}
            onChange={handleOnURLChange}
            value={AD.elData.url.value}
            style={{
              borderBottomColor: AD.elData.url.isValid ? "green" : "red",
            }}
          />
        </div>
        <AssetsModal
          callBackFile={handleOnFileChange}
          callLocation={`${AD.elLabel}-audio-one`}
        >
          <Button>Insert File</Button>
        </AssetsModal>
        <div className="audioSettings-format">
          {FORMAT_LIST.map((item, i) => (
            <span className="position-relative" key={item.id}>
              <label className={"settings-checkbox"}>
                {item.label}
                <input
                  type="checkbox"
                  name={item.name}
                  checked={AD.elData.videoFormat[item.name]}
                  onChange={handleOnAudioFormatChange}
                  ref={arrOfRefs[i]}
                />
                <span></span>
              </label>
            </span>
          ))}
        </div>
      </form>
    </div>
  );
};

export default Audio;
