import React, { useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useStateValue } from "../../context";
import { updateElState } from "../../context/actions";
import { getActiveEl } from "../../utils/getActiveEl";
import { setAddressVal, setZoomVal } from "../../utils/setStateValues";
import { StandaloneSearchBox, LoadScriptNext } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY, LIBRARIES } from "../../configs/constants";

const Map = () => {
  const [{ layout }, dispatch] = useStateValue();
  const [searchBox, setSearchBox] = useState();
  const addressRef = React.useRef(null);
  const sliderRef = React.useRef(null);

  const MD = getActiveEl(layout).elData;
  const els = layout.elements;
  const elId = layout.activeEl.id;

  const handleOnZoomChange = () => {
    const result = setZoomVal(sliderRef, els, elId);
    dispatch(updateElState(result));
  };

  const onLoadSearch = (param) => {
    setSearchBox(param);
  };

  const onPlacesChanged = () => {
    const place = searchBox.getPlaces();
    const location = place[0].geometry.location;
    const name = place[0].formatted_address;
    const lat = location.lat();
    const lng = location.lng();

    const result = setAddressVal({ lat, lng, name }, els, elId);
    dispatch(updateElState(result));
  };

  return (
    <div className="map">
      <h4>Map</h4>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="address">
          <label className="pt-2 mb-0 mr-3">Address: </label>
          <LoadScriptNext
            id="script-loader"
            googleMapsApiKey={GOOGLE_MAPS_API_KEY}
            libraries={LIBRARIES}
          >
            <StandaloneSearchBox
              onLoad={onLoadSearch}
              onPlacesChanged={onPlacesChanged}
            >
              <input
                className="custom-input"
                defaultValue={MD.address.value.name}
                type="text"
                placeholder="Type address"
                ref={addressRef}
              />
            </StandaloneSearchBox>
          </LoadScriptNext>
        </div>
        <div className="zoom">
          <label>Zoom: </label>
          <div className="map-slider">
            <Slider
              min={0}
              max={20}
              defaultValue={MD.zoom}
              ref={sliderRef}
              onChange={handleOnZoomChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Map;
