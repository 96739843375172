import React, { useState } from "react";
import { Card, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { PROJECT_PATH } from "../../configs/constants/paths";
import { editProject } from "../../context/fetch/fetchProjects";
import { projectEditedSuccess } from "../../context/actions/projectActions";

const ProjectCard = ({ project, projects, setModalVis, dispatchPrj }) => {
  const [isLoading, setLoadng] = useState(false);

  const handleOnRemoveClick = () => {
    setModalVis({ state: true, ID: project.id });
  };

  const handleOnPublishClick = async (id) => {
    const sendingData = {
      data: {
        published: !projects.prjList.filter((item) => item.id === id)[0]
          .published,
      },
      id,
    };

    const getEditedProjects = () => {
      const editedProjects = projects.prjList.map((project) => {
        if (project.id === id) {
          const copyOfProject = { ...project };
          copyOfProject.published = !project.published;
          project = copyOfProject;
        }
        return project;
      });

      return editedProjects;
    };

    try {
      setLoadng(true);
      await editProject(sendingData);
      dispatchPrj(projectEditedSuccess(getEditedProjects()));
      setLoadng(false);
    } catch (error) {
      setLoadng(false);
    }
  };

  return (
    <Col lg={4} sm={6} className={" mb-3"}>
      <Card className={"cursor-pointer"}>
        <NavLink to={`${PROJECT_PATH}/screens/${project.id}`}>
          <Card.Body
            className={"d-flex justify-content-between align-items-center p-2"}
          >
            <Card.Title className={"mb-0"}>{project.name}</Card.Title>
            <Card.Text className={"card-img-number"}>
              {project.screenCount} IMAGES
            </Card.Text>
          </Card.Body>
          <Card.Body className={"p-0"}>
            <div className={"project-img-box"}>
              {project.screenCount ? (
                <Card.Img
                  variant="top"
                  src={project.previewImage}
                  alt={"project-image"}
                />
              ) : (
                <p>No Preview</p>
              )}
            </div>
          </Card.Body>
        </NavLink>
        <Card.Body
          className={
            "d-flex justify-content-between align-items-center p-2 card-footer"
          }
        >
          <Card.Link
            className={isLoading ? "eventDisabled" : ""}
            onClick={handleOnRemoveClick}
          >
            Delete
          </Card.Link>
          <Card.Link
            className={isLoading ? "eventDisabled" : ""}
            style={{ color: isLoading ? "#8ccadb" : "#17bae7" }}
            onClick={() => handleOnPublishClick(project.id)}
          >
            {isLoading
              ? "Loading"
              : project.published
              ? "Unpublish"
              : "Publish"}
          </Card.Link>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ProjectCard;
