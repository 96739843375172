export const removeFilter = (objects, id) =>
  objects.filter((object) => object.id !== id);

export const editFilter = (objects, data, id) => {
  const editableObj = objects.filter((object) => object.id === id);
  const objIdx = objects.findIndex((object) => object.id === id);
  const editedObj = { ...editableObj[0], ...data };
  objects[objIdx] = editedObj;
  return objects;
};
