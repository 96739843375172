import React, { useState } from "react";
import TableInput from "./tableInput";
import { Button, Spinner, Table as BTable } from "react-bootstrap";
import RemoveModal from "../../core/removeModal";
import { editTag, removeTag } from "../../context/fetch/fetchProjects";
import { useTeg } from "../../context/reducers/tegContext";
import { FaTimes, FaRegCheckCircle, FaBan, FaEdit } from "react-icons/fa";

const Table = ({
  data,
  header,
  startEditing,
  stopEditing,
  editIdx,
  removeToggle,
  setRemoveToggle,
  dispatch,
}) => {
  const [
    {
      project: { removeTagLoading, editTagLoading },
    },
  ] = useTeg();
  const [value, setValue] = useState({ id: null, obj: {} });
  const [activeUserId, setActiveUserId] = useState(null);
  const handleUserRemove = (userId) => {
    setActiveUserId(userId);
    setRemoveToggle(true);
  };
  const handleUpdateItem = (e) => {
    setValue({ id: e.id, obj: { ...value.obj, ...e.obj } });
  };
  const onUpdateItem = () => {
    if (value.id && Object.keys(value.obj).length) {
      editTag(dispatch, value);
    }
    setValue({ id: null, obj: {} });
  };

  const row = (x, i, header) => {
    const currentlyEditing = editIdx === i;
    return (
      <tr key={i}>
        {header.map((y, k) => (
          <td key={k} style={{ display: "flex", justifyContent: "center" }}>
            {currentlyEditing ? (
              <TableInput
                id={x.id}
                updateValue={(e) => handleUpdateItem(e)}
                value={x[y.prop]}
                name={y.prop}
                type={y.type}
              />
            ) : (
              <p
                onClick={() => {
                  startEditing(i);
                }}
              >
                {x[y.prop]}
              </p>
            )}
          </td>
        ))}
        <td className={"user-btn-action text-center"}>
          {currentlyEditing ? (
            <Button
              className="mr-2"
              variant="outline-info"
              onClick={() => {
                onUpdateItem();
                stopEditing();
              }}
            >
              <FaRegCheckCircle />
            </Button>
          ) : (
            <Button
              variant="outline-info"
              className="mr-2"
              onClick={() => {
                startEditing(i);
              }}
            >
              <FaEdit />
            </Button>
          )}

          {currentlyEditing ? (
            <Button
              variant="outline-warning"
              className="mr-2"
              onClick={() => {
                setValue([]);
                stopEditing();
              }}
            >
              <FaBan />
            </Button>
          ) : (
            <Button
              variant="outline-danger"
              onClick={() => handleUserRemove(x.id)}
            >
              <FaTimes />
            </Button>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className="table-section tags-table">
      {data.length > 0 ? (
        <BTable striped hover>
          <thead className="thead-dark">
            <tr>
              {header.map((x, i) => (
                <th key={i} style={{ position: "relative" }}>
                  {editTagLoading && (
                    <Spinner
                      animation="border"
                      variant="light"
                      style={{ position: "absolute", left: 10 }}
                    />
                  )}
                  {x.name}
                </th>
              ))}
              <th style={{ textAlign: "center" }}>Actions</th>
            </tr>
          </thead>
          <tbody>{data ? data.map((x, i) => row(x, i, header)) : ""}</tbody>
        </BTable>
      ) : (
        <p style={{ textAlign: "center" }}>No data</p>
      )}

      {removeToggle && (
        <RemoveModal
          onChangeToggle={setRemoveToggle}
          remove={() => removeTag(dispatch, activeUserId)}
          loading={removeTagLoading}
        />
      )}
    </div>
  );
};

export default Table;
