import { LABELS } from "../editor/configs/constants";

export const filterOverwrittenData = (cards) => {

  for (const key in cards) {
    switch (cards[key].elLabel) {
      case LABELS[0]: // Text
        const filterTextData = () => {
          const { markup } = cards[key].elData;
          cards[key] = {
            elLabel: cards[key].elLabel,
            elData: { markup },
            order: cards[key].order,
          };
        };
        filterTextData();
        break;

      case LABELS[1]: // Video
        const filterVideoData = () => {
          const { provider, url, videoFormat } = cards[key].elData;
          cards[key] = {
            elLabel: cards[key].elLabel,
            elData: { provider, url, videoFormat },
            order: cards[key].order,
          };
        };
        filterVideoData();
        break;

      case LABELS[5]: // Audio
        const filterAudioData = () => {
          const { url, videoFormat } = cards[key].elData;
          cards[key] = {
            elLabel: cards[key].elLabel,
            elData: { url, videoFormat },
            order: cards[key].order,
          };
        };
        filterAudioData();
        break;

      case LABELS[4]: // SocialMedia
        const filterSocialMediaData = () => {
          const { list } = cards[key].elData;
          cards[key] = {
            elLabel: cards[key].elLabel,
            elData: { list },
            order: cards[key].order,
          };
        };
        filterSocialMediaData();
        break;

      case LABELS[3]: // Slider
        const filterSliderData = () => {
          const { imgSrc, duration } = cards[key].elData;
          cards[key] = {
            elLabel: cards[key].elLabel,
            elData: { imgSrc, duration },
            order: cards[key].order,
          };
        };
        filterSliderData();
        break;

      case LABELS[6]: // Map
        const filterMapaData = () => {
          const { address } = cards[key].elData;
          cards[key] = {
            elLabel: cards[key].elLabel,
            elData: { address },
            order: cards[key].order,
          };
        };
        filterMapaData();
        break;

      case LABELS[7]: // LinkButton
        const filterLinkButtonData = () => {
          const { btnText, url } = cards[key].elData;
          cards[key] = {
            elLabel: cards[key].elLabel,
            elData: { btnText, url },
            order: cards[key].order,
          };
        };
        filterLinkButtonData();
        break;

      case LABELS[8]: // Divider
        delete cards[key];
        break;

      default:
        break;
    }
  }

  return cards;
};
