import UsersService from "../../services/usersService";
import {
  attemptUsers,
  attemptUser,
  getUserSucceed,
  attemptRemoveUser,
  removeUserFail,
  attemptEditUser,
  editUserFail,
  attemptAddUser,
  addUserFail,
  editUserSuccess,
  addUserSuccess,
  removeUserSuccess,
} from "../actions/usersActions";

const usersService = new UsersService();

export const fetchUsers = async (dispatch, offset, limit,sort) => {
  try {
    dispatch(attemptUsers());
    const data = await usersService.getAllUsers(offset, limit,sort);
    return data.data;
  } catch (e) {
    console.log("e", e);
  }
};
export const removeUser = async (id, dispatch) => {
  try {
    dispatch(attemptRemoveUser());
    await usersService.deleteUser(id);
    dispatch(removeUserSuccess(id));
  } catch (e) {
    dispatch(removeUserFail());
    console.log("e", e);
  }
};
export const getUser = async (id, dispatch) => {
  try {
    dispatch(attemptUser());
    const data = await usersService.getUser(id);
    dispatch(getUserSucceed(data.data));
  } catch (e) {
    console.log("e", e);
  }
};
export const editUser = async (obj, dispatch) => {
  try {
    dispatch(attemptEditUser());
    await usersService.editUser(obj);
    dispatch(editUserSuccess(obj));
  } catch (e) {
    dispatch(editUserFail());
    console.log("e", e);
  }
};

export const addUser = async (obj, dispatch) => {
  try {
    dispatch(attemptAddUser());
    const res = await usersService.addUser(obj);
    dispatch(addUserSuccess(res.data));
  } catch (e) {
    dispatch(addUserFail());
    console.log("e", e);
  }
};
