import axios from "axios";
import { errorAlert, successAlert } from "../context/actions/userActions";
import jwt_decode from "jwt-decode";
import { EXPIRATION_INTERVAL } from "../configs/constants";
import Auth from "../configs/Auth";
import { refreshToken } from "../context/fetch/fetchUser";
import logger from '../utils/logger';

const interceptor = (dispatch, history, socketState) => {
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      const token = localStorage.token;

      if (config.withToken) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      if (config.isUpload) {
        if (token) {
          const expirationDate = jwt_decode(token).exp * 1000;
          const leftTimeMillisecond = expirationDate - Date.now();

          if (leftTimeMillisecond < EXPIRATION_INTERVAL) {
            refreshToken(socketState, history).catch((error) => {
              console.log("error", error);
              return null;
            });
          }
        } else {
          Auth.logout(history, socketState);
          return;
        }
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      // console.log("res", response);
      if (response.status === 201) dispatch(successAlert(response.statusText));
      return response;
    },
    (err) => {
      if (err.message === "Network Error") {
        dispatch(errorAlert("Network Timeout Error"));
        return Promise.reject(err);
      }

      return new Promise((resolve, reject) => {
        let originalReq = err.config;
        if (
          err.response.status === 401 &&
          err.config &&
          !err.config.__isRetryRequest
        ) {
          originalReq._retry = true;

          const res = refreshToken(socketState, history)
            .then((token) => {
              originalReq.headers["Authorization"] = token.accessToken;
              return axios(originalReq);
            })
            .catch((e) => {
              console.log("e", e);
              originalReq = null;
              return;
            });
          resolve(res);
        }
        if (!(err.response.status === 401 || err.response.status === 403)) {
          if (err.response.data.errors && err.response.data.errors.length) {
            dispatch(errorAlert(err.response.data.errors[0].message));
          } else {
            dispatch(errorAlert(err.response.data.message));
          }
        }
        logger.sendMessage(err);
        return reject(err);
      });
    }
  );
};

export default interceptor;
