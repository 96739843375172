import React, {useEffect, useMemo, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getFolders } from "../../context/fetch/fetchTemplates";
import { useTemplatesValue } from "../../context/reducers/templatesContext";
import { useHistory } from "react-router-dom";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import {
  getTemplatesSuccess,
  getFoldersSuccess,
  attemptGetFolders,
} from "../../context/actions/templatesActions";

const Main = (props) => {
  const [state, dispatch] = useTemplatesValue();

  const initialState = useMemo(() => {
    return {
      columnName: 'createdAt' ,
      sortAsc: true
    }
  }, [])
  const [sortQuery, setSortValue] = useState(initialState);

  const history = useHistory();
  const { showInModal } = props;

  useEffect(() => {
    let didCancel = false;

    const fetchFolders = async () => {
      dispatch(attemptGetFolders());

      try {
        const sort = `${sortQuery.columnName}:${sortQuery.sortAsc ? 'asc' : 'desc'}`;

        const result = await getFolders(history, showInModal, sort);

        const { folders, activeFolderId, templates } = result;
        if (activeFolderId) {
          !didCancel &&
            dispatch(getTemplatesSuccess({ templates, activeFolderId }));
        }
        !didCancel && dispatch(getFoldersSuccess({ folders, activeFolderId }));
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchFolders();
    return () => (didCancel = true);
  }, [dispatch, history, showInModal, sortQuery]);

  return (
    <>
      <Container className={"info-container"}>
        <Row className={"justify-content-start"}>
          <Col md={7} lg={5} className={"info-section assets-section"}>
            {!props.showInModal && (
              <>
                <p className={"info-title text-uppercase fbold"}>
                  Create New Template
                </p>
                <p className={"info-text"}>
                  Create your individual mobile screen with the ayscan template
                  creator for any images within minutes. Use ayscan predefined
                  templates or create your own templates. Drag and drop elements
                  such as link-buttons, video and map, text boxes or social
                  media buttons without coding, and preview the results
                  instantly.
                </p>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Container className={"editor-container"}>
        <div className={"overview-editor templates-view"}>
          <Row className={"justify-content-start"}>
            <Col>
              <div className={"editor-view assets-view"}>
                <Row className={"justify-content-start"}>
                  <Col col={12}>
                    <div className={"file-editor"}>
                      <div
                        className={`align-items-stretch d-flex flex-column flex-lg-row h-100 ${
                          state.folder.template.isTmplsLoading
                            ? "eventDisabled"
                            : ""
                        }`}
                      >
                        <LeftSide {...props} />
                        <RightSide
                            sortQuery={sortQuery}
                            setSortValue={setSortValue}
                            {...props}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Main;
