import { MAX_WIDTH } from "../../configs/constants";

export const calculateHeight = async (src) => {
  const getMeta = (url) => {
    if (!url) return url;
    return new Promise((resolve) => {
      let img = new Image();
      img.onload = function() {
        resolve({ width: this.width, height: this.height });
      };
      img.src = url;
    });
  };

  const dimensions = await getMeta(src);
  const aspectRatio = dimensions.height / dimensions.width;
  return Math.trunc(aspectRatio * MAX_WIDTH);
};
