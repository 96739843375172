import React, { useState } from "react";
import { Col } from "react-bootstrap";
import AddFolder from "./AddFolder";
import PreviewFolder from "./PreviewFolder";
import EditFolder from "./EditFolder";

const LeftSide = (props) => {
  const [index, setIndex] = useState(0);
  const [folderId, setFolderId] = useState(null);

  let Component = null;
  switch (index) {
    case 0:
      Component = (
        <PreviewFolder
          {...props}
          setIndex={setIndex}
          setFolderId={setFolderId}
        />
      );
      break;
    case 1:
      Component = <AddFolder {...props} setIndex={setIndex} />;
      break;
    case 2:
      Component = <EditFolder setIndex={setIndex} folderId={folderId} />;
      break;
    default:
  }

  return (
    <Col lg={3} className={"px-0 h-100"}>
      {Component}
    </Col>
  );
};

export default LeftSide;
