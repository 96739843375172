import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import AUDIO from "../../assets/images/audio.png";
import PDF from "../../assets/images/pdf.png";
import { ProgressBar } from "react-bootstrap";
import "./index.scss";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  maxHeight: "300px",
  overflow: "auto",
};

const thumb = {
  position: "relative",
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  flexDirection: "column",
  width: "100%",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
};

const DragNDrop = ({ files, onChangeFiles, progress, accept }) => {
  const { getRootProps, getInputProps } = useDropzone({
    // accept: "audio/*, image/*, application/pdf",
    accept,
    onDrop: (acceptedFiles) => {
      onChangeFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file, key) => {
    const fileType = file.type.split("/")[0];

    const getSource = (fileType) => {
      switch (fileType) {
        case "audio":
          return AUDIO;
        case "application":
          return PDF;
        default:
          return file.preview;
      }
    };

    return (
      <div style={thumb} key={key}>
        <div style={thumbInner}>
          <div className="add-image">
            <img alt="file" src={getSource(fileType)} />
          </div>

          <ProgressBar now={progress[key]} />
        </div>
      </div>
    );
  });

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
      <section {...getRootProps({ className: "dnd-container dropzone" })}>
        <input {...getInputProps()} />
        <p className={"mb-0"}>Drag files here or upload:</p>

        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
    </>
  );
};

export default DragNDrop;
