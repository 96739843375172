import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";

const ProfileInput = (
  { value, updateValue = () => {}, name, type, placeholder },
  ref
) => {
  const onBlur = e => {
    updateValue({ obj: { [ref.current.name]: ref.current.value } });
  };

  return (
    <Form.Control
      name={name}
      onBlur={onBlur}
      defaultValue={value}
      type={type}
      placeholder={placeholder}
      ref={ref}
    />
  );
};

export default forwardRef(ProfileInput);
