import React from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "../../configs/Auth";
import GuestLayout from "../../layouts/GuestLayout";
import routes from "../routesCode";

const GuestRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.isAuthenticated() ? (
          <Redirect to={{ pathname: routes.projects.path }} />
        ) : (
          <GuestLayout>
            <Component {...props} />
          </GuestLayout>
        )
      }
    />
  );
};

export default GuestRoute;
