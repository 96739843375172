import React, { memo } from "react";
import { Form, Button } from "react-bootstrap";
import { SOCIAL_MEDIAS } from "../../../editor/configs/constants";
import { areEqual } from "../../../editor/utils/comparison";
import {
  setSocialMediaUrlVal,
  setAddSocialMedia,
  setRemoveSocialMedia,
} from "../../../editor/utils/setStateValues";

const SocialMedia = ({ elId, cards, setCards, isEdited, setIsEdited }) => {
  let urlRef = [];
  const elData = cards[elId].elData;

  const handleOnAdd = () => {
    const currentMedias = Object.values(elData.list).map((item) => item.name);
    if (currentMedias.length < SOCIAL_MEDIAS.length) {
      let name = "";
      for (const media of SOCIAL_MEDIAS) {
        if (!currentMedias.includes(media)) {
          name = media;
          break;
        }
      }
      const updatedCards = setAddSocialMedia(cards, elId, name);
      setCards(updatedCards);
      !isEdited && setIsEdited(true);
    }
  };

  const handleOnRemove = (key) => {
    if (Object.keys(elData.list).length > 1) {
      const updatedCards = setRemoveSocialMedia(cards, elId, key);
      setCards(updatedCards);
      !isEdited && setIsEdited(true);
    }
  };

  const handleOnUrlChange = (e, key) => {
    const URL = e.target.value;
    const updatedCards = setSocialMediaUrlVal(URL, cards, elId, key, elData);
    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  const getOrderedKeys = (list) => {
    const keys = Object.keys(list);
    keys.sort((a, b) => +a - +b);
    return keys;
  };

  return (
    <Form.Group className="socialMedia social-media-icons">
      <h4>Social Media</h4>
      {getOrderedKeys(elData.list).map((key) => (
        <Form.Group className="socialMedia-main" key={key} id={key}>
          <Form.Group>
            <Form.Label>{elData.list[key].name}</Form.Label>
            <Form.Control
              type="url"
              placeholder={`type ${elData.list[key].name.toLowerCase()} url`}
              ref={(el) => urlRef.push(el)}
              onChange={(e) => handleOnUrlChange(e, key)}
              defaultValue={elData.list[key].url.value}
              style={{
                borderColor: elData.list[key].url.identified ? "green" : "red",
              }}
            />
          </Form.Group>

          <Form.Group className="delete-row">
            <Form.Text
              onClick={() => handleOnRemove(key)}
              style={{ cursor: "pointer", color: "red" }}
            >
              Delete
            </Form.Text>
          </Form.Group>
        </Form.Group>
      ))}
      <Form.Group>
        <Button variant="secondary" type="button" onClick={handleOnAdd}>
          Add another service
        </Button>
      </Form.Group>
      <hr />
    </Form.Group>
  );
};
export default memo(SocialMedia, areEqual);
