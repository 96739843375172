import {
  ADD_NEW_ELEMENT,
  REMOVE_ELEMENT,
  UPDATE_ELEMENT,
  CHANGE_ACTIVE_ELEMENT,
  SET_BAR_INDEX,
  ATTEMPT_SET_TEMPLATE_NAME,
  SET_TEMPLATE_NAME_SUCCESS,
  GET_TEMPLATE_SUCCESS,
  ATTEMPT_REMOVE_TEMPLATE,
  ATTEMPT_GET_TEMPLATE,
  UPDATE_HEADER,
  SET_ELEMENT_HEADER,
  SET_ELEMENT_FOOTER,
  UPDATE_FOOTER,
  SET_IS_EDITED,
} from "./actions";
import {
  FOOTER_INITIAL_STATE,
  HEADER_INITIAL_STATE,
} from "../configs/constants";

export const initialState = {
  layout: {
    header: HEADER_INITIAL_STATE,
    footer: FOOTER_INITIAL_STATE,
    elements: [],
    activeEl: { id: null },
    activeBarIndex: 0,
    fetched: false,
  },
  isPinned: null,
  templateName: "",
  isLoading: false,
  isHeaderDisable: false,
  id: null,
  folderId: null,
  isEdited: false,
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case UPDATE_HEADER:
      return {
        ...state,
        isEdited: true,
        layout: { ...state.layout, header: payload },
      };

    case SET_ELEMENT_HEADER:
      return {
        ...state,
        layout: {
          ...state.layout,
          activeBarIndex: 1,
          activeEl: { id: payload },
        },
      };

    case SET_ELEMENT_FOOTER:
      return {
        ...state,
        layout: {
          ...state.layout,
          activeBarIndex: 1,
          activeEl: { id: payload },
        },
      };

    case UPDATE_FOOTER:
      return {
        ...state,
        isEdited: true,
        layout: {
          ...state.layout,
          footer: payload,
        },
      };

    case ADD_NEW_ELEMENT:
      return {
        ...state,
        isEdited: true,
        layout: {
          ...state.layout,
          elements: payload.elements,
          activeEl: {
            id: payload.id,
          },
          activeBarIndex: 1,
        },
      };

    case REMOVE_ELEMENT: {
      return {
        ...state,
        isEdited: true,
        layout: {
          ...state.layout,
          elements: payload.els,
          activeEl: {
            id: payload.id,
          },
          activeBarIndex: payload.id ? 1 : 0,
        },
      };
    }
    case UPDATE_ELEMENT: {
      return {
        ...state,
        isEdited: true,
        layout: { ...state.layout, elements: payload },
      };
    }
    case CHANGE_ACTIVE_ELEMENT: {
      return {
        ...state,
        layout: {
          ...state.layout,
          activeEl: { id: payload },
          activeBarIndex: 1,
        },
      };
    }

    case SET_BAR_INDEX: {
      return { ...state, layout: { ...state.layout, activeBarIndex: payload } };
    }

    case ATTEMPT_GET_TEMPLATE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_TEMPLATE_SUCCESS: {
      return {
        ...state,
        layout: { ...state.layout, ...payload.body, fetched: true },
        templateName: payload.name,
        isLoading: false,
        id: payload.id,
        folderId: payload.folderId,
        isPinned: payload.isPinned,
      };
    }

    case ATTEMPT_SET_TEMPLATE_NAME: {
      return { ...state, isHeaderDisable: true };
    }

    case SET_TEMPLATE_NAME_SUCCESS: {
      return {
        ...state,
        templateName: payload,
        isHeaderDisable: false,
      };
    }

    case ATTEMPT_REMOVE_TEMPLATE: {
      return { ...state, isLoading: true };
    }

    case SET_IS_EDITED: {
      return { ...state, isEdited: payload };
    }

    default:
      return state;
  }
};
