import React, { useState } from "react";
import { useStateValue } from "../../context";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { changePassword } from "../../context/fetch/fetchUser";
import schema from "./schema";

const Security = () => {
  const [{ user }, dispatch] = useStateValue();
  const changePasswordLoading = user.changePasswordLoading;

  const [state, setState] = useState({
    fields: { oldPassword: "", password: "", confirmPassword: "" },
    errors: { oldPassword: "", password: "", confirmPassword: "" },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    schema
      .validate(state.fields, { abortEarly: false })
      .then((valid) => {
        const data = {
          currentPassword: state.fields.oldPassword,
          newPassword: state.fields.password,
        };
        changePassword(data, dispatch);
      })
      .catch((err) => {
        const newState = {
          errors: { oldPassword: "", password: "", confirmPassword: "" },
        };
        err.errors.forEach((err) => {
          newState.errors[err.key] = err.value;
        });
        setState({ ...state, ...newState });
      });
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      fields: { ...state.fields, [name]: value },
      errors: { ...state.errors, [name]: "" },
    });
  };

  return (
    <Container className={"login-container"}>
      <Row className={"justify-content-center login-section"}>
        <Col md={6} className={"login-box col-10"}>
          <Form onSubmit={handleSubmit}>
            <p className={"text-center text-uppercase login-title fbold"}>
              Change Password
            </p>
            <Form.Group>
              <Form.Control
                type="password"
                name="oldPassword"
                placeholder="Old Password"
                id="oldPassword"
                onChange={onChange}
                value={state.fields.oldPassword}
              />
              {!!state.errors.oldPassword && (
                <Form.Text className="text-danger">
                  {state.errors.oldPassword}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                id="password"
                onChange={onChange}
                value={state.fields.password}
              />
              {!!state.errors.password && (
                <Form.Text className="text-danger">
                  {state.errors.password}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={state.fields.confirmPassword}
                onChange={onChange}
                id="confirmPassword"
              />
              {!!state.errors.confirmPassword && (
                <Form.Text className="text-danger">
                  {state.errors.confirmPassword}
                </Form.Text>
              )}
            </Form.Group>
            <p className={"text-center mb-0"}>
              <Button type="submit" disabled={changePasswordLoading}>
                {changePasswordLoading ? "Loading" : "Change"}
              </Button>
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Security;
