export const areEqual = (prevProps, nextProps) => {
  const prevVal = JSON.stringify(prevProps);
  const nextVal = JSON.stringify(nextProps);
  return prevVal === nextVal;
};

export const filterComparableData = (prev, next) => {
  const {elData: {styles: h1, ...PrevRest} } = prev;
  const {elData: {styles: h2, ...NextRest} } = next;
  return areEqual(PrevRest, NextRest)
}
