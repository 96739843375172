import React, { useRef } from "react";
import { useStateValue } from "../../context";
import { INITIAL_STATE, LABELS } from "../../configs/constants";
import { filterElement } from "../../utils/filterElement";
import { Col } from "react-bootstrap";
import {
  changeActiveElement,
  addNewElement,
  removeElement,
  updateElState,
} from "../../context/actions";
import uuid from "uuid";
import Toolbar from "../../core/Toolbar";
import Header from "./Header";
import Footer from "./Footer";
import DropTarget from "../../core/useDrop";
import {
  changeOrder,
  decreaseOrder,
  increaseOrder,
  CHANGE_ORDER,
} from "../../utils/ordering";
import { setScrollPosition } from "../../utils/setScrollPosition";
import { GetElementData } from "../../utils/GetElementData";
import { setStyleVals } from "../../utils/setStateValues";

const Dashboard = () => {
  const [{ layout, isPinned }, dispatch] = useStateValue();
  const dashMainRef = useRef(null);
  const dashMainBodyRef = useRef(null);
  const els = layout.elements;

  React.useEffect(() => {
    // handle scroll bar position based on active card's position
    setScrollPosition(dashMainBodyRef, dashMainRef, layout.activeEl.id);
  }, [layout.activeEl.id]);

  const handleOnBodyDrop = (item, index) => {
    if (item.type === CHANGE_ORDER) {
      const elements = changeOrder(els, item, index);
      dispatch(updateElState(elements));
    } else {
      const elements = increaseOrder(els, index);
      const ID = uuid.v4();

      elements[ID] = {
        elLabel: item.type,
        elData: INITIAL_STATE(item.type),
        order: index,
      };

      const data = {
        elements,
        id: ID,
      };

      !isPinned && dispatch(addNewElement(data));
    }
  };

  const handleOnToolClick = (ev, key, i) => {
    ev.stopPropagation();
    const filteredEls = filterElement(layout, key);
    const activeElId = i !== 0 ? Object.keys(filteredEls)[i - 1] : null;
    const elements = decreaseOrder(filteredEls, els, key);
    !isPinned && dispatch(removeElement(elements, activeElId));
  };

  const handleOnElementClick = (key) => {
    dispatch(changeActiveElement(key));
  };

  const SortElementKeys = () => {
    const keys = Object.keys(layout.elements);
    keys.sort((a, b) => layout.elements[a].order - layout.elements[b].order);
    return keys;
  };

  const handleOnStyleChange = (params, keyVal) => {
    const height = params.path[1].clientHeight;
    const result = setStyleVals(height, els, keyVal);
    dispatch(updateElState(result));
  };

  return (
    <Col lg={6} className="h-100 pr-lg-0 mb-3 mb-lg-0">
      <div className="dashboard">
        <div className="dashboard-title border-bottom">
          <h2>View</h2>
        </div>
        <div className="dashboard-main" ref={dashMainRef}>
          <Header layout={layout} dispatch={dispatch} />
          <DropTarget
            className="dashboard-main-body text-uppercase"
            accept={[...LABELS, CHANGE_ORDER]}
            onDrop={handleOnBodyDrop}
            dashMainBodyRef={dashMainBodyRef}
          >
            <div ref={dashMainBodyRef}>
              {SortElementKeys().map((key, i) => (
                <div
                  className={`element-wrapper ${
                    key === layout.activeEl.id ? "element-wrapper-active" : ""
                  }`}
                  key={key}
                  id={key}
                  onClick={() => handleOnElementClick(key)}
                >
                  <Toolbar
                    onClick={(ev) => handleOnToolClick(ev, key, i)}
                    isPinned={isPinned}
                    keyVal={key}
                  />
                  {GetElementData(els, key, handleOnStyleChange)}
                </div>
              ))}
            </div>
          </DropTarget>
          <Footer layout={layout} dispatch={dispatch} />
        </div>
      </div>
    </Col>
  );
};

export default Dashboard;
