import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { PROJECT_PATH } from "../../configs/constants/paths";

const ProjectBreadcrumb = () => {
  const history = useHistory();
  const pathname = history.location.pathname;
  const location = pathname.split("/");
  
  return (
    <div
      className={`project-breadcrumb container ${
        location[2] === "screens" || location[2] === "view-list" ? "" : "hidden"
      }`}
    >
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push(PROJECT_PATH)}>
          My projects
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => history.push(`${PROJECT_PATH}/screens/${location[3]}`)}
          active={location[2] !== "view-list"}
        >
          Overview project images
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={location[2] !== "view-list" ? "hidden" : ""}
          active
        >
          Image link-settings{" "}
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default ProjectBreadcrumb;
