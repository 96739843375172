import React from "react";
import "./index.scss";
export const Loader = () => (
  <div className="lds-roller-wrapper">
    <div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);
