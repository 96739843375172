/* eslint-disable */
import React, { useState } from "react";
import { useUsers } from "../../context/reducers/usersContext";
import * as yup from "yup";
import { Form, Button, Modal } from "react-bootstrap";
import { addUser } from "../../context/fetch/fetchUsers";

const schema = yup.object().shape({
  name: yup.string().required({ key: "name", value: "Name is required" }),
  password: yup
    .string()
    .min(6, {
      key: "password",
      value: "Password is too short - should be 6 chars minimum.",
    })
    .required({ key: "password", value: "Password is required" }),

  email: yup
    .string()
    .email()
    .required({ key: "email", value: "Email is required" }),

  company: yup
    .string()
    .required({ key: "company", value: "Company is required" }),
});

const initialState = {
  fields: {
    name: "",
    password: "",
    email: "",
    role: "PUBLISHER",
    status: "INACTIVE",
    company: "",
    zip: "",
    street: "",
    city: "",
    country: "",
    phone: "",
  },
  errors: {
    name: "",
    password: "",
    email: "",
  },
};

const AddModal = () => {
  const [show, setShow] = useState(false);
  const [users, dispatch] = useUsers();
  const [state, setState] = useState(initialState);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setState((state) => ({
      ...state,
      errors: {
        name: "",
        password: "",
        email: "",
      },
    }));
  };

  const handleSubmit = () => {
    schema
      .validate(state.fields, { abortEarly: false })
      .then(async (valid) => {
        await addUser(state.fields, dispatch);
        if (!users.users.userAddLoading) {
          setState(initialState);
          handleClose();
        }
      })
      .catch((err) => {
        const newState = {
          errors: {
            name: "",
            password: "",
            email: "",
          },
        };
        err.errors.forEach((err) => {
          newState.errors[err.key] = err.value;
        });
        setState({ ...state, ...newState });
      });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      fields: { ...state.fields, [name]: value },
      errors: { ...state.errors, [name]: "" },
    });
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add User
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-left">
          <Form>
            <Form.Group>
              <Form.Control
                type="text"
                name="email"
                placeholder="Email"
                value={state.fields.email}
                onChange={onChange}
                id="email"
              />
              {state.errors.email && (
                <Form.Text className="text-danger">
                  {state.errors.email}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                value={state.fields.name}
                onChange={onChange}
                id="name"
              />
              {state.errors.name && (
                <Form.Text className="text-danger">
                  {state.errors.name}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                id="password"
                onChange={onChange}
                value={state.fields.password}
              />
              {state.errors.password && (
                <Form.Text className="text-danger">
                  {state.errors.password}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <select
                className="w-100"
                onChange={(e) =>
                  setState({
                    ...state,
                    fields: { ...state.fields, role: e.target.value },
                  })
                }
              >
                <option value="PUBLISHER">Publisher</option>
                <option value="ADMIN">Admin</option>
              </select>
            </Form.Group>
            <Form.Group>
              <select
                className="w-100"
                onChange={(e) =>
                  setState({
                    ...state,
                    fields: { ...state.fields, status: e.target.value },
                  })
                }
              >
                <option name="status" value="INACTIVE">
                  Inactive
                </option>
                <option name="status" value="ACTIVE">
                  Active
                </option>
              </select>
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="company"
                placeholder="Company"
                value={state.fields.company}
                onChange={onChange}
                id="company"
              />
              {state.errors.company && (
                <Form.Text className="text-danger">
                  {state.errors.company}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="street"
                placeholder="Street"
                value={state.fields.street}
                onChange={onChange}
                id="street"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="city"
                placeholder="City"
                value={state.fields.city}
                onChange={onChange}
                id="city"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="country"
                placeholder="Country"
                value={state.fields.country}
                onChange={onChange}
                id="country"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="zip"
                placeholder="Zip"
                value={state.fields.zip}
                onChange={onChange}
                id="zip"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Phone"
                value={state.fields.phone}
                onChange={onChange}
                id="phone"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={users.users.userAddLoading}
          >
            Close
          </Button>
          <Button
            disabled={users.users.userAddLoading}
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            {users.users.userAddLoading ? "Loading" : "Add"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AddModal;
