export const ATTEMPT_USERS = "ATTEMPT_USERS";
export const FETCH_USERS_SUCCEED = "FETCH_USERS_SUCCEED";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";
export const FETCH_USER_SUCCEED = "FETCH_USER_SUCCEED";
export const ATTEMPT_USER = "ATTEMPT_USER";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";
///edit
export const ATTEMPT_EDIT_USER = "ATTEMPT_EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";
///add
export const ATTEMPT_ADD_USER = "ATTEMPT_ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";
///remove
export const ATTEMPT_REMOVE_USER = "ATTEMPT_REMOVE_USER";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export const REMOVE_USER_FAIL = "REMOVE_USER_FAIL";

export const attemptUsers = () => ({ type: ATTEMPT_USERS });
export const fetchUsersSucceed = (payload) => ({
  type: FETCH_USERS_SUCCEED,
  payload,
});
export const attemptUser = () => ({ type: ATTEMPT_USER });
export const getUserSucceed = (payload) => ({
  type: FETCH_USER_SUCCEED,
  payload,
});

///edit
export const attemptEditUser = () => ({ type: ATTEMPT_EDIT_USER });
export const editUserSuccess = (payload) => ({
  type: EDIT_USER_SUCCESS,
  payload,
});
export const editUserFail = () => ({ type: EDIT_USER_FAIL });

///add
export const attemptAddUser = () => ({ type: ATTEMPT_ADD_USER });
export const addUserSuccess = (payload) => ({
  type: ADD_USER_SUCCESS,
  payload,
});
export const addUserFail = () => ({ type: ADD_USER_FAIL });

///remove
export const attemptRemoveUser = () => ({ type: ATTEMPT_REMOVE_USER });
export const removeUserSuccess = (payload) => ({
  type: REMOVE_USER_SUCCESS,
  payload,
});
export const removeUserFail = () => ({ type: REMOVE_USER_FAIL });
