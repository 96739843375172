import React from "react";
import Main from "./Main";
import { StateProvider } from "../../context/reducers/assetsContext";
import {
  initialState,
  reducer,
} from "../../context/reducers/assetsContext/reducer";

const AssetsManagement = (props) => {
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Main {...props} />
    </StateProvider>
  );
};

export default AssetsManagement;
