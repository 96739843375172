// ----- > folders' constants
export const ATTEMPT_GET_FOLDERS = "ATTEMPT_GET_FOLDERS";
export const GET_FOLDERS_SUCCESS = "GET_FOLDERS_SUCCESS";
export const GET_FOLDERS_FAIL = "GET_FOLDERS_FAIL";

export const ATTEMPT_SET_FOLDER = "ATTEMPT_SET_FOLDER";
export const SET_FOLDER_SUCCESS = "SET_FOLDER_SUCCESS";
export const SET_FOLDER_FAIL = "SET_FOLDER_FAIL";

export const ATTEMPT_EDIT_FOLDER = "ATTEMPT_EDIT_FOLDER";
export const EDIT_FOLDER_SUCCESS = "EDIT_FOLDER_SUCCESS";
export const EDIT_FOLDER_FAIL = "EDIT_FOLDER_FAIL";

export const ATTEMPT_REMOVE_FOLDER = "ATTEMPT_REMOVE_FOLDER";
export const REMOVE_FOLDER_SUCCESS = "REMOVE_FOLDER_SUCCESS";
export const REMOVE_FOLDER_FAIL = "REMOVE_FOLDER_FAIL";

// ----- > templates' constants
export const ATTEMPT_GET_TEMPLATES = "ATTEMPT_GET_TEMPLATES";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAIL = "GET_TEMPLATES_FAIL";

export const ATTEMPT_SET_TEMPLATE = "ATTEMPT_SET_TEMPLATE";
export const SET_TEMPLATE_SUCCESS = "SET_TEMPLATE_SUCCESS";
export const SET_TEMPLATE_FAIL = "SET_TEMPLATE_FAIL";

// ----- > constants of templates and folders in project
export const ATTEMPT_GET_TMPLS_AND_FOLDERS = "ATTEMPT_GET_TMPLS_AND_FOLDERS";
export const GET_TMPLS_AND_FOLDERS_SUCCESS = "GET_TMPLS_AND_FOLDERS_SUCCESS";
export const GET_TMPLS_AND_FOLDERS_FAIL = "GET_TMPLS_AND_FOLDERS_FAIL";

// -----> other' constants
export const SET_ACTIVE_FOLDER_ID = "SET_ACTIVE_FOLDER_ID";
export const SET_ELEMENTS = "SET_ELEMENTS";

// ----- > folders' actions
export const attemptGetFolders = () => ({ type: ATTEMPT_GET_FOLDERS });
export const getFoldersSuccess = (payload) => ({
  type: GET_FOLDERS_SUCCESS,
  payload,
});
export const getFoldersFail = () => ({ type: GET_FOLDERS_FAIL });

export const attemptSetFolder = () => ({ type: ATTEMPT_SET_FOLDER });
export const setFolderSuccess = (payload) => ({
  type: SET_FOLDER_SUCCESS,
  payload,
});
export const setFolderFail = () => ({ type: SET_FOLDER_FAIL });

export const attemptEditFolder = () => ({ type: ATTEMPT_EDIT_FOLDER });
export const editFolderSuccess = (payload) => ({
  type: EDIT_FOLDER_SUCCESS,
  payload,
});
export const editFolderFail = () => ({ type: EDIT_FOLDER_FAIL });

export const attemptRemoveFolder = () => ({ type: ATTEMPT_REMOVE_FOLDER });
export const removeFolderSuccess = (payload) => ({
  type: REMOVE_FOLDER_SUCCESS,
  payload,
});
export const removeFolderFail = () => ({ type: REMOVE_FOLDER_FAIL });

// ----- > templates' actions
export const attemptGetTemplates = () => ({ type: ATTEMPT_GET_TEMPLATES });
export const getTemplatesSuccess = (payload) => ({
  type: GET_TEMPLATES_SUCCESS,
  payload,
});
export const getTemplatesFail = () => ({ type: GET_TEMPLATES_FAIL });

export const attemptSetTemplate = () => ({ type: ATTEMPT_SET_TEMPLATE });
export const setTemplateSuccess = (payload) => ({
  type: SET_TEMPLATE_SUCCESS,
  payload,
});
export const setTemplateFail = () => ({ type: SET_TEMPLATE_FAIL });

// -----> actions of get templates and folders in project
export const attemptGetTmplsNFolders = () => ({
  type: ATTEMPT_GET_TMPLS_AND_FOLDERS,
});
export const getTmplsNFoldersSuccess = (payload) => ({
  type: GET_TMPLS_AND_FOLDERS_SUCCESS,
  payload,
});
export const getTmplsNFoldersFail = () => ({
  type: GET_TMPLS_AND_FOLDERS_FAIL,
});

// -----> other' actions
export const setActiveFolderId = (payload) => ({
  type: SET_ACTIVE_FOLDER_ID,
  payload,
});

export const setElements = (payload) => ({
  type: SET_ELEMENTS,
  payload,
});
