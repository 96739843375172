import React, { useEffect } from "react";
import { useStateValue } from "../../context";
import { unshowAlert } from "../../context/actions/userActions";

const Alert = () => {
  const [state, dispatch] = useStateValue();
  const { alertType, alertMessage, alertShow } = state.alert;

  useEffect(() => {
    let unshow;
    const show = () => {
      unshow = setTimeout(() => dispatch(unshowAlert()), 7000);
    };

    alertShow && show();
    return () => {
      clearTimeout(unshow);
    };
  }, [alertShow, dispatch]);
  return (
    <div
      className="alert"
      style={{
        right: !alertShow && "-100%",
        backgroundColor:
          alertType === "error"
            ? "#f44336"
            : alertType === "warning"
            ? "#ff9800"
            : alertType === "success" && "#4CAF50",
      }}
    >
      <span className="closebtn" onClick={() => dispatch(unshowAlert())}>
        &times;
      </span>
      {alertMessage}
    </div>
  );
};

export default Alert;
