import React, { useState } from "react";
import { FiSquare, FiList, FiPlus } from "react-icons/fi";
import { Col, Nav, Button } from "react-bootstrap";
import { useAssetsValue } from "../../context/reducers/assetsContext";
import { removeFile } from "../../context/fetch/fetchAssets";
import { removeFileSuccess } from "../../context/actions/assetsActions";
import AddFile from "./AddFile";
import RemoveFileModal from "./RemoveFileModal";
import AUDIO from "../../assets/images/audio.png";
import { TiDelete } from "react-icons/ti";
import SortingList from "../Sorting/SortingList";

const RightSide = (props) => {
  const [state, dispatch] = useAssetsValue();
  const [showAddTmpl, setShowAddTmpl] = useState(false);
  const [fileState, setFileState] = useState([]);

  const handleOnRemoveClick = async () => {
    const checkedFilesID = fileState.map((file) => file.id);
    const list = await removeFile(checkedFilesID, state);
    setFileState([]);
    dispatch(removeFileSuccess(list));
  };

  const handleOnFileRemoveClick = async (fileID) => {
    const list = await removeFile([fileID], state);
    const filteredList = fileState.filter((file) => file.id !== fileID);
    setFileState(filteredList);
    dispatch(removeFileSuccess(list));
  };

  const handleOnFileClick = (item) => {
    const quantitiy = props.callLocation?.split("-").splice(-1)[0];
    if (quantitiy === "one") setFileState([item]);
    else {
      if (fileState.length) {
        for (let i = 0; i < fileState.length; i++) {
          if (fileState[i].id === item.id) {
            setFileState((fileState) =>
              fileState.filter((file) => file.id !== item.id)
            );
            break;
          }
          if (i + 1 === fileState.length) setFileState([...fileState, item]);
        }
      } else setFileState([...fileState, item]);
    }
  };

  const getImageSrc = (item) =>
    item.ext.split("/")[0] === "audio" ? AUDIO : item.file;

  const isChecked = (item) => {
    const file = fileState.filter((file) => file.id === item.id);
    return file.length > 0;
  };

  const getFileList = (files) => {
    if (props.callLocation) {
      const ext = props.callLocation.split("-").splice(-2)[0];
      return files.filter((item) => item.ext.split("/")[0] === ext);
    }
    return files;
  };

  const handleOnOkClick = () => {
    props.callBackFile && props.callBackFile({ files: fileState, ...props });
    props.closeModal && props.closeModal();
  };

  return (
    <>
      {showAddTmpl ? (
        <AddFile
          onCloseClick={() => setShowAddTmpl(!showAddTmpl)}
          callLocation={props.callLocation}
        />
      ) : (
        <Col lg={9} className={"px-0 h-100"}>
          <div
            className={
              "justify-content-between border-bottom d-flex align-items-center assets-navbar"
            }
          >
            <Col className={"col-auto"}>
              <Nav
                variant="tabs"
                defaultActiveKey="link-2"
                className={"justify-content-start align-items-center"}
              >
                <Nav.Item className={"tabs-title pl-0"}>VIEW</Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="link-1">
                    <FiList size={"25"} />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="link-2">
                    <FiSquare size={"23"} />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col className={"col-auto px-0"}>
              <Nav className="flex-column-reverse flex-sm-row">

                <SortingList columns={[
                    {label: 'Created Date',columnName:'createdAt'},
                    {label: 'Name',columnName:'fileName'}
                  ]}
                   setSortValue={props.setSortValue}
                   value={props.sortQuery}
                />

                {props.showInModal ? (
                  <Button
                    className={`btn btn-primary ${
                      fileState.length ? "" : "none"
                    }`}
                    onClick={handleOnOkClick}
                  >
                    Ok
                  </Button>
                ) : (
                  <RemoveFileModal
                    className={`btn-gray ${fileState.length ? "" : "none"} `}
                    handleOnRemoveClick={handleOnRemoveClick}
                  />
                )}
                <Button
                  className={`add-btn btn btn-primary ${
                    state.folder.activeFolderId ? "" : "disabled"
                  } ${fileState.length ? "none" : ""}`}
                  onClick={() => setShowAddTmpl(!showAddTmpl)}
                >
                  <FiPlus size={16} /> Add new file
                </Button>
              </Nav>
            </Col>
          </div>
          {state.folder.activeFolderId ? (
            <div className={" my-3 row px-3"}>
              {state.folder.file.list.length ? (
                getFileList(state.folder.file.list).map((item) => (
                  <Col
                    md={4}
                    sm={6}
                    xs={12}
                    key={item.id}
                    className="col-auto mb-3 image-box"
                  >
                    <div className={"assets-build-box"}>
                      <TiDelete
                        size="20px"
                        onClick={() => handleOnFileRemoveClick(item.id)}
                        color={"red"}
                        style={{ cursor: "pointer" }}
                      />

                      <div
                        className={`image-container ${
                          isChecked(item) ? "checked" : ""
                        }`}
                        onClick={() => handleOnFileClick(item)}
                        onDoubleClick={handleOnOkClick}
                      >
                        <div>
                          <img
                            height="100"
                            alt="asset-imgs"
                            src={getImageSrc(item)}
                          />
                        </div>
                      </div>
                      <span>{item.fileName}</span>
                    </div>
                  </Col>
                ))
              ) : (
                <div className={"editor-placeholder mb-0"}>
                  <p>No files in folder !</p>
                </div>
              )}
            </div>
          ) : null}
        </Col>
      )}
    </>
  );
};

export default RightSide;
