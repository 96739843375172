import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import GetTemplate from "./GetTemplate";
import { Link } from "react-router-dom";
import { CONTENT_TYPES } from "../../editor/configs/constants";

const SelectTemplates = ({ screen, tmplsNFolders, setProject }) => {
  const [activeTarget, setActiveTarget] = useState(0);
  const [activeTmplId, setActiveTmplId] = useState(-1);

  const { folders, templates } = tmplsNFolders;
  const sortFolders = folders.sort((a, b) => a.name.localeCompare(b.name))

  useEffect(() => {
    const defaultTmplId = screen.templateId || -1;
    setActiveTmplId(defaultTmplId);
    setActiveTarget(CONTENT_TYPES.indexOf(screen.contentType));
  }, [screen]);

  const getOptions = (id) => {
    if (templates && templates.length) {
      const filteredTmpls = templates.filter(({ folderId }) => folderId === id);
      filteredTmpls.sort((a, b) => a.name.localeCompare(b.name))
      return filteredTmpls.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ));
    }
    return (
      <option disabled style={{ color: "red" }}>
        folder is empty !
      </option>
    );
  };

  const filterTemplates = () => {
    return templates.filter((item) => item.id === activeTmplId)[0];
  };

  const handleOnTargetClick = (i) => {
    setActiveTarget(i);
  };

  return (
    <>
      <Col xl={4} md={6} className={"border-right mb-5 mb-md-3"}>
        <div className={"template-box"}>
          <p className={"template-title"}>
            Connect target image with direct link to:
          </p>
          <div className={"template-group"}>
            {CONTENT_TYPES.map((item, i) => (
              <div
                className={`text-uppercase template ${i === activeTarget ? "active" : ""
                  }`}
                style={{ cursor: "pointer" }}
                key={i}
                onClick={() => handleOnTargetClick(i)}
              >
                {item}
              </div>
            ))}
          </div>

          <Form.Group controlId="ControlSelect1" className={"select-form"}>
            {sortFolders && sortFolders.length ? (
              <>
                <Form.Label className={"template-title"}>
                  Choose your own template
                </Form.Label>
                <Form.Control
                  as="select"
                  disabled={activeTarget}
                  value={activeTmplId}
                  onChange={(e) => setActiveTmplId(Number(e.target.value))}
                >
                  <option value={-1} default>
                    - - -
                  </option>
                  {sortFolders.map(({ id, name }) => (
                    <optgroup label={name} key={id}>
                      {getOptions(id)}
                    </optgroup>
                  ))}
                </Form.Control>
              </>
            ) : (
              <Form.Text className="text-danger">No templates !</Form.Text>
            )}

            <Link to={"/templates"} className={"btn btn-primary mt-3"}>
              <FiPlus size={"16"} /> Create own template
            </Link>
          </Form.Group>
        </div>
      </Col>
      <Col>
        <GetTemplate
          activeTmplId={activeTmplId}
          activeTarget={activeTarget}
          template={filterTemplates()}
          screen={screen}
          setProject={setProject}
        />
      </Col>
    </>
  );
};

export default SelectTemplates;
