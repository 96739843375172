import React from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "../../configs/Auth";
import LoggedInLayout from "../../layouts/LoggedInLayout";
import routes from "../routesCode";

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.isAuthenticated() ? (
          <LoggedInLayout {...rest}>
            <Component {...props} />
          </LoggedInLayout>
        ) : (
          <Redirect to={{ pathname: routes.logIn.path }} />
        )
      }
    />
  );
};

export default AuthRoute;
