import TemplatesService from "../../services/templatesServices";

const templateService = new TemplatesService();

export const getTmplsNFolders = async () => {
  const [foldersResponse, templatesResponse] = await Promise.all([
      await templateService.getFolders(),
      await templateService.getAllTemplates()
  ])
  const folders = foldersResponse.data.data;
  const templates = templatesResponse.data.data;

  return {
    folders,
    templates,
  };
};
