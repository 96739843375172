import React, { useRef, memo } from "react";
import { FaRegImage } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { setImageUrlVal } from "../../../editor/utils/setStateValues";
import { areEqual } from "../../../editor/utils/comparison";
import AssetsModal from "../../../core/AssetsModal";
import { updateElementData } from "../../../editor/utils/updateElData";

const Image = ({ elId, cards, setCards, isEdited, setIsEdited }) => {
  const urlRef = useRef(null);
  const elData = cards[elId].elData;

  const handleOnURLChange = () => {
    const updatedCards = setImageUrlVal(urlRef, cards, elId);
    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  const handleOnFileChanage = (data) => {
    const src = data.files[0].file;
    const updatedCards = updateElementData(cards, elId, {
      imgSrc: src,
    });
    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  return (
    <Form.Group className="image">
      <h4>Image</h4>
      <Form.Group>
        <Form.Label>Link Image: </Form.Label>
        <Form.Control
          type="url"
          placeholder="URL"
          ref={urlRef}
          onChange={handleOnURLChange}
          defaultValue={elData.url.value}
          style={{ borderBottomColor: elData.url.isValid ? "green" : "red" }}
        />
      </Form.Group>
      <Form.Group className="image-preview">
        <AssetsModal
          callBackFile={handleOnFileChanage}
          callLocation={`Image-image-one`}
        >
          {elData.imgSrc ? (
            <img src={elData.imgSrc} alt="img" />
          ) : (
            <div className="icon-wrapper">
              <FaRegImage size="70px" />
            </div>
          )}
        </AssetsModal>
      </Form.Group>
      <hr />
    </Form.Group>
  );
};

export default memo(Image, areEqual);
