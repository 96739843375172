import React from "react";
import "./style/index.scss";
import { StateProvider } from "./context/index";
import { initialState, reducer } from "./context/reducer";
import Routes from "./routes";
import Alert from "./components/Alert";

const App = () => {  
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Alert />
      <Routes />
    </StateProvider>
  );
};

export default App;
