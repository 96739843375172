import React, { useRef } from "react";
import {
  SOCIAL_MEDIA_ICONS_STYLES,
  SOLID_SOCIAL_MEDIAS,
  SOCIAL_MEDIA_ICONS_STYLE,
  OUTLINE_SOCIAL_MEDIAS,
  ELEMENT_FOOTER_IDS,
} from "../../configs/constants";
import {
  setSocialMediaGrayscale,
  setSocialMediaStyleType,
  setFooterSocMediaStyleType,
  setFooterSocMediaGrayscale,
} from "../../utils/setStateValues";
import { updateElState, updateFooter } from "../../context/actions";

const SocialMediaConfigure = ({ SMD, dispatch, layout }) => {
  const iconsStyleRef = useRef(null);

  const els = layout.elements;
  const elId = layout.activeEl.id;

  const handleOnStyleTypeChange = () => {
    const type = iconsStyleRef.current.value;

    if (SMD.elLabel === ELEMENT_FOOTER_IDS[1]) {
      const footerData = setFooterSocMediaStyleType(type, layout);
      dispatch(updateFooter(footerData));
    } else {
      const result = setSocialMediaStyleType(type, els, elId);
      dispatch(updateElState(result));
    }
  };

  const handleOnGrayscaleChange = (grayscale) => {
    if (SMD.elData.iconStyle.grayscale !== grayscale) {
      if (SMD.elLabel === ELEMENT_FOOTER_IDS[1]) {
        const footerData = setFooterSocMediaGrayscale(grayscale, layout);
        dispatch(updateFooter(footerData));
      } else {
        const result = setSocialMediaGrayscale(grayscale, els, elId);
        dispatch(updateElState(result));
      }
    }
  };

  return (
    <div className="socialMedia-configure">
      <div className="socialMedia-configure-style">
        <label>Icon Style: </label>
        <select
          value={SMD.elData.iconStyle.type}
          ref={iconsStyleRef}
          onChange={handleOnStyleTypeChange}
        >
          {SOCIAL_MEDIA_ICONS_STYLES.map(({ name, id }) => (
            <option key={id} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>
      <div className="socialMedia-configure-icon">
        <div
          className={`color${SMD.elData.iconStyle.grayscale ? "" : "-active"}`}
          onClick={() => handleOnGrayscaleChange(false)}
        >
          {(SMD.elData.iconStyle.type === SOCIAL_MEDIA_ICONS_STYLE[0]
            ? SOLID_SOCIAL_MEDIAS
            : OUTLINE_SOCIAL_MEDIAS
          )
            .slice(0, 3)
            .map(({ Icon, id, label }) => (
              <span
                key={id}
                className={`${label}-${SMD.elData.iconStyle.type}`}
              >
                <Icon />
              </span>
            ))}
        </div>{" "}
        <div
          className={`gray${SMD.elData.iconStyle.grayscale ? "-active" : ""}`}
          onClick={() => handleOnGrayscaleChange(true)}
        >
          {(SMD.elData.iconStyle.type === SOCIAL_MEDIA_ICONS_STYLE[0]
            ? SOLID_SOCIAL_MEDIAS
            : OUTLINE_SOCIAL_MEDIAS
          )
            .slice(0, 3)
            .map(({ Icon, id, label }) => (
              <span
                className={`${label}-${SMD.elData.iconStyle.type} gray`}
                key={id}
              >
                <Icon />
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SocialMediaConfigure;
