import React, { useRef, useState, useEffect } from "react";
import { Col, Form, Button } from "react-bootstrap";
import { setFolder } from "../../context/fetch/fetchTemplates";
import { useTemplatesValue } from "../../context/reducers/templatesContext";
import { useHistory } from "react-router-dom";
import CustomPopup from "../../core/CustomPopup";

const AddFolder = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const nameRef = useRef(null);
  const [, dispatch] = useTemplatesValue();
  const history = useHistory();

  useEffect(() => {
    const id = setTimeout(() => {
      setShowPopup(false);
    }, 2000);
    return () => {
      clearTimeout(id);
    };
  }, [showPopup]);

  const handleOnSaveClick = () => {
    const folderName = nameRef.current.value.trim();
    if (folderName) {
      setFolder(nameRef.current.value, dispatch, history, props);
      props.setIndex(0);
    } else {
      setShowPopup(true);
    }
  };

  const hanldeOnCancelClick = () => {
    props.setIndex(0);
  };

  return (
    <div className={"folder-editor "}>
      <div className={"text-right folder-btn-group"}>
        <Button onClick={hanldeOnCancelClick} className={"btn btn-dark"}>
          Cancel
        </Button>
      </div>
      <Col>
        <Form className={"my-3"} onSubmit={(e) => e.preventDefault()}>
          <Form.Group controlId="formBasicText" className={"position-relative"}>
            <CustomPopup
              title="Invalid Name"
              message="Please set valid name"
              show={showPopup}
              targetRef={nameRef}
            >
              <Form.Control
                type="text"
                placeholder="Name Template Folder"
                className={"focus-effect cursor-pointer"}
                ref={nameRef}
              />
            </CustomPopup>

            <span className={"focus-border"}></span>
          </Form.Group>
          <div className={"text-right"}>
            <Button
              type="submit"
              className={"btn-light"}
              onClick={handleOnSaveClick}
            >
              Save
            </Button>
          </div>
        </Form>
      </Col>
    </div>
  );
};

export default AddFolder;
