import React, { useRef } from "react";
import {
  // VIDEO_PROVIDER_LIST,
  FORMAT_LIST,
} from "../../configs/constants";
import { useStateValue } from "../../context";
import { updateElState } from "../../context/actions";
import { getActiveEl } from "../../utils/getActiveEl";
import { setUrlVal, setVideoFormatVals } from "../../utils/setStateValues";

const Video = () => {
  const [{ layout }, dispatch] = useStateValue();
  const els = layout.elements;
  const elId = layout.activeEl.id;
  const urlRef = useRef(null);

  const [autoplayRef, loopRef, controlRef] = [
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const arrOfRefs = [autoplayRef, loopRef, controlRef];
  const VD = getActiveEl(layout).elData;

  const handleOnVideoFormatChange = () => {
    const result = setVideoFormatVals(
      { autoplayRef, loopRef, controlRef },
      els,
      elId
    );
    dispatch(updateElState(result));
  };

  const handleOnURLChange = () => {
    const result = setUrlVal(urlRef, els, elId);
    dispatch(updateElState(result));
  };

  return (
    <div className="videoSettings">
      <h4>Video</h4>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="videoSettings-source">
          <label className="mb-0 mr-3">Source: </label>
          <input
            className="custom-input"
            placeholder="URL"
            ref={urlRef}
            onChange={handleOnURLChange}
            value={VD.url.value}
            style={{
              borderBottomColor: VD.provider?.name ? "green" : "red",
            }}
          />
        </div>

        <div className="videoSettings-format">
          {FORMAT_LIST.map((item, i) => (
            <span
              key={item.id}
              className={`position-relative ${
                item.for.includes(VD.provider?.name) ? "" : "none"
              }`}
            >
              <label className={"settings-checkbox"}>
                {item.label}
                <input
                  type="checkbox"
                  name={item.name}
                  onChange={handleOnVideoFormatChange}
                  checked={VD.videoFormat[item.name]}
                  ref={arrOfRefs[i]}
                />
                <span></span>
              </label>
            </span>
          ))}
        </div>
      </form>
    </div>
  );
};

export default Video;
