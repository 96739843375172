import React from "react";
import { useStateValue } from "../../context";
import {
  updateElState,
  updateHeader,
  updateFooter,
} from "../../context/actions";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { htmlToDraftJs } from "../../utils/htmlToDraftJs";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { getActiveEl } from "../../utils/getActiveEl";
import {
  setTextVal,
  setHeaderTextVal,
  setFooterTextVal,
} from "../../utils/setStateValues";
import {
  ELEMENT_HEADER_IDS,
  ELEMENT_FOOTER_IDS,
} from "../../configs/constants";

const Text = () => {
  const [{ layout }, dispatch] = useStateValue();

  const TD = getActiveEl(layout);

  const els = layout.elements;
  const elId = layout.activeEl.id;

  const handleOnStateChange = (editorState) => {
    const currentContent = editorState.getCurrentContent();
    const hasText = currentContent.hasText();
    const markup = draftToHtml(convertToRaw(currentContent));

    if (TD.elLabel === ELEMENT_HEADER_IDS[1]) {
      const result = setHeaderTextVal(markup, hasText, layout);
      dispatch(updateHeader(result));
    } else if (TD.elLabel === ELEMENT_FOOTER_IDS[2]) {
      const footerData = setFooterTextVal(markup, hasText, layout);
      dispatch(updateFooter(footerData));
    } else {
      const result = setTextVal(markup, els, elId);
      dispatch(updateElState(result));
    }
  };

  const getHTML = () => {
    switch (TD.elLabel) {
      case ELEMENT_HEADER_IDS[1]:
        return layout.header.text.markup;
      case ELEMENT_FOOTER_IDS[2]:
        return layout.footer.text.markup;

      default:
        return TD.elData.markup;
    }
  };

  const customToolbar = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "textAlign",
      "colorPicker",
      "link",
      "remove",
      "history",
    ],
    inline: {
      inDropdown: true,
      strikethrough: { className: "none" },
      monospace: { className: "none" },
      superscript: { className: "none" },
      subscript: { className: "none" },
    },

    textAlign: { inDropdown: true },
    // list: { inDropdown: true },
    // link: { inDropdown: true },
    // history: { inDropdown: true },
  };

  return (
    <div className="textSettings">
      <h4>{TD.elLabel}</h4>
      <Editor
        defaultEditorState={htmlToDraftJs(getHTML())}
        placeholder="type your text"
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={handleOnStateChange}
        toolbar={TD.elLabel === "Text" ? {} : customToolbar}
      />
    </div>
  );
};

export default Text;
