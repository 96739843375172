import React from "react";
import {
  SOCIAL_MEDIAS,
  SOCIAL_MEDIA_ICONS_STYLE,
  ELEMENT_FOOTER_IDS,
} from "../../configs/constants";
import { getActiveEl } from "../../utils/getActiveEl";
import { useStateValue } from "../../context";
import { updateElState, updateFooter } from "../../context/actions";
import {
  getSolidSocialMediaIcon,
  getOutlineSocialMediaIcon,
} from "../../utils/getSocialMediaIcon";
import {
  setSocialMediaUrlVal,
  setRemoveSocialMedia,
  setAddSocialMedia,
  setFooterSocMediaUrlVal,
  setFooterRemoveSocialMedia,
  setFooterAddSocialMedia,
} from "../../utils/setStateValues";
import SocialMediaConfigure from "./_SocialMediaConfigure";

const SocialMedia = () => {
  const [{ layout }, dispatch] = useStateValue();
  const els = layout.elements;
  const elId = layout.activeEl.id;

  const urlRef = [];

  const SMD = getActiveEl(layout);

  const handleOnAdd = () => {
    const currentMedias = Object.values(SMD.elData.list).map(
      (item) => item.name
    );

    if (currentMedias.length < SOCIAL_MEDIAS.length) {
      let name = "";
      for (const media of SOCIAL_MEDIAS) {
        if (!currentMedias.includes(media)) {
          name = media;
          break;
        }
      }

      if (SMD.elLabel === ELEMENT_FOOTER_IDS[1]) {
        const footerData = setFooterAddSocialMedia(layout, name);
        dispatch(updateFooter(footerData));
      } else {
        const result = setAddSocialMedia(els, elId, name);
        dispatch(updateElState(result));
      }
    }
  };

  const handleOnRemove = (key) => {
    if (Object.keys(SMD.elData.list).length > 1) {
      if (SMD.elLabel === ELEMENT_FOOTER_IDS[1]) {
        const footerData = setFooterRemoveSocialMedia(layout, key);
        dispatch(updateFooter(footerData));
      } else {
        const result = setRemoveSocialMedia(els, elId, key);
        dispatch(updateElState(result));
      }
    }
  };

  const handleOnUrlChange = (e, key) => {
    const URL = e.target.value;
    if (SMD.elLabel === ELEMENT_FOOTER_IDS[1]) {
      const footerData = setFooterSocMediaUrlVal(URL, layout, key);
      dispatch(updateFooter(footerData));
    } else {
      const result = setSocialMediaUrlVal(URL, els, elId, key);
      dispatch(updateElState(result));
    }
  };

  const getOrderedKeys = (list) => {
    const keys = Object.keys(list);
    keys.sort((a, b) => +a - +b);
    return keys;
  };

  return (
    <div className="socialMedia social-media-icons">
      <h2>Social Media</h2>
      <SocialMediaConfigure SMD={SMD} dispatch={dispatch} layout={layout} />
      {getOrderedKeys(SMD.elData.list).map((key) => (
        <div className="socialMedia-main" key={key} id={key}>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="socialMedia-input">
              <div className="socialMedia-icon">
                <span
                  className={`${SMD.elData.list[key].name}-${
                    SMD.elData.iconStyle.type
                  } ${SMD.elData.iconStyle.grayscale ? "gray" : ""}`}
                >
                  {SMD.elData.iconStyle.type === SOCIAL_MEDIA_ICONS_STYLE[0]
                    ? getSolidSocialMediaIcon(SMD.elData.list[key].name)
                    : getOutlineSocialMediaIcon(SMD.elData.list[key].name)}
                </span>
              </div>
              <input
                className="custom-input"
                type="url"
                placeholder="URL"
                ref={(el) => urlRef.push(el)}
                onChange={(e) => handleOnUrlChange(e, key)}
                value={SMD.elData.list[key].url.value}
                style={{
                  borderBottomColor: SMD.elData.list[key].url.identified
                    ? "green"
                    : "red",
                }}
              />
            </div>
            <div className="delete-row">
              <input
                type="button"
                value="Delete"
                onClick={() => handleOnRemove(key)}
              />
            </div>
          </form>
          <hr />
        </div>
      ))}
      <button onClick={handleOnAdd}>Add another service</button>
    </div>
  );
};
export default SocialMedia;
