import React from "react";
import { MdDeleteForever } from "react-icons/md";
import DraggableComponent from "../useDrag";
import { CHANGE_ORDER } from "../../utils/ordering";
import CustomPopup from "../../../core/CustomPopup";

const Toolbar = ({ onClick, isPinned, keyVal }) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const removeRef = React.useRef(null);

  React.useEffect(() => {
    const id = setTimeout(() => {
      setShowPopup(false);
    }, 2000);
    return () => {
      clearTimeout(id);
    };
  }, [showPopup]);

  const handleOnClick = (e) => {
    isPinned ? setShowPopup(true) : onClick(e);
  };

  return (
    <>
      <CustomPopup
        title="Not Allowed"
        message="Pinned Template"
        show={showPopup}
        targetRef={removeRef}
      />
      <DraggableComponent className="toolbar" type={CHANGE_ORDER} id={keyVal}>
        <div ref={removeRef}>
          <MdDeleteForever
            className={`remove`}
            onClick={(e) => handleOnClick(e)}
          />
        </div>
      </DraggableComponent>
    </>
  );
};

export default Toolbar;
