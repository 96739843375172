import React from "react";
import { Modal } from "react-bootstrap";
import AssetsManagement from "../../components/AssetsManagement";

function AssetsModal(props) {
  const [modalShow, setModalShow] = React.useState(false);

  const closeModal = () => setModalShow(false);
  const openModal = () => setModalShow(true);

  return (
    <>
      <span className="modal-preview" onClick={openModal}>
        {props.children}
      </span>

      <Modal
        size="lg"
        onHide={closeModal}
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Select File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssetsManagement
            {...props}
            showInModal={true}
            closeModal={closeModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AssetsModal;
