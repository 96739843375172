import React, { useRef } from "react";
import { Popover, Overlay } from "react-bootstrap";

function CustomPopup(props) {
  const ref = useRef(null);

  const { targetRef, show, children, title, message } = props;

  return (
    <div ref={ref}>
      <div>{children}</div>
      <Overlay
        show={show}
        target={targetRef}
        placement="bottom"
        container={ref.current}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Title as="h3">{title}</Popover.Title>
          <Popover.Content>{message}</Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
}

export default CustomPopup;
