import React, { memo, useEffect, useRef } from "react";
import { filterComparableData } from "../../utils/comparison";
import uuid from "uuid";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const AudioData = ({ elData }) => {
  const audioRef = useRef(null);
  const { autoplay, loop, control } = elData.videoFormat;

  useEffect(() => {
    if (elData.url.value) {
      audioRef.current.autoplay = autoplay;
      audioRef.current.loop = loop;
      audioRef.current.controls = control;
    }
  }, [autoplay, control, loop, elData.url.value]);

  return (
    <div className="audioData">
      {elData.url.isValid ? (
        <div>
          <audio key={uuid.v4()} ref={audioRef} muted={!isSafari}>
            <source src={elData.url.value} />
          </audio>
        </div>
      ) : (
        <div className="imageData-initialView">Choose audio from assets</div>
      )}
    </div>
  );
};

export default memo(AudioData, filterComparableData);
