import uuid from "uuid";

import { MdTextFields } from "react-icons/md";
import {
  TiDivideOutline,
  TiSocialYoutubeCircular,
  TiSocialFacebookCircular,
  TiSocialPinterestCircular,
  TiSocialInstagram,
} from "react-icons/ti";
import {
  FaRegPlayCircle,
  FaImage,
  FaPinterest,
  FaLinkedin,
} from "react-icons/fa";
import { IoIosImages, IoMdShare } from "react-icons/io";
import { FiHeadphones, FiLink2, FiMapPin, FiTwitter } from "react-icons/fi";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import {
  AiOutlineLinkedin,
  AiOutlineYoutube,
  AiOutlineInstagram,
} from "react-icons/ai";

import Text from "../../components/Settings/Text";
import Video from "../../components/Settings/Video";
import Image from "../../components/Settings/Image";
import Slider from "../../components/Settings/Slider";
import SocialMedia from "../../components/Settings/SocialMedia";
import Audio from "../../components/Settings/Audio";
import Map from "../../components/Settings/Map";
import LinkButton from "../../components/Settings/LinkButton";
import Divider from "../../components/Settings/Divider";

import TextData from "../../components/Dashboard/TextData";
import VideoData from "../../components/Dashboard/VideoData";
import MapData from "../../components/Dashboard/MapData";
import DividerData from "../../components/Dashboard/DividerData";
import ImageData from "../../components/Dashboard/ImageData";
import SliderData from "../../components/Dashboard/SliderData";
import SocialMediaData from "../../components/Dashboard/SocialMediaData";
import AudioData from "../../components/Dashboard/AudioData";
import LinkButtonData from "../../components/Dashboard/LinkButtonData";
import { ascendingKey } from "../../utils/ascendingKey";

export const ELEMENT_HEADER_IDS = ["Image-Header", "Text-Header"];
export const ELEMENT_FOOTER_IDS = [
  "Image-Footer",
  "SocialMedia-Footer",
  "Text-Footer",
];

export const LABELS = [
  "Text",
  "Video",
  "Image",
  "Slider",
  "SocialMedia",
  "Audio",
  "Map",
  "LinkButton",
  "Divider",
];

const ICONS = [
  MdTextFields,
  FaRegPlayCircle,
  FaImage,
  IoIosImages,
  IoMdShare,
  FiHeadphones,
  FiMapPin,
  FiLink2,
  TiDivideOutline,
];

export const SETS = [
  Text,
  Video,
  Image,
  Slider,
  SocialMedia,
  Audio,
  Map,
  LinkButton,
  Divider,
];

const DATA = [
  TextData,
  VideoData,
  ImageData,
  SliderData,
  SocialMediaData,
  AudioData,
  MapData,
  LinkButtonData,
  DividerData,
];

const VIDEO_PROVIDERS = ["YouTube", "Facebook", "Vimeo"];
const AUDIO_PROVIDERS = ["Soundcloud"];

export const SOCIAL_MEDIAS = [
  "Facebook",
  "Instagram",
  "Pinterest",
  "LinkedIn",
  "Twitter",
  "Youtube",
];

export const SOCIAL_MEDIA_ICONS_STYLE = ["Solid", "Outlined"];

const SOLID_SOCIAL_MEDIA_ICONS = [
  FaFacebook,
  TiSocialInstagram,
  FaPinterest,
  FaLinkedin,
  FaTwitter,
  TiSocialYoutubeCircular,
];

const OUTLINE_SOCIAL_MEDIA_ICONS = [
  TiSocialFacebookCircular,
  AiOutlineInstagram,
  TiSocialPinterestCircular,
  AiOutlineLinkedin,
  FiTwitter,
  AiOutlineYoutube,
];

export const BORDER_TYPE_LIST = ["solid", "dotted", "dashed", "double", "none"];
export const BAR_LIST = ["Elements", "Component Settings"];

export const EL_LIST = LABELS.map((label, i) => ({
  label,
  Icon: ICONS[i],
  id: uuid.v4(),
}));

export const EL_SETS_LIST = LABELS.map((label, i) => ({
  label,
  Sets: SETS[i],
}));

export const EL_DATA_LIST = LABELS.reduce((object, label, index) => {
  object[label] = DATA[index];
  return object;
}, {});

export const VIDEO_PROVIDER_LIST = VIDEO_PROVIDERS.map((provider) => ({
  name: provider,
  id: uuid.v4(),
}));

export const AUDIO_PROVIDER_LIST = AUDIO_PROVIDERS.map((provider) => ({
  name: provider,
  id: uuid.v4(),
}));

export const SOLID_SOCIAL_MEDIAS = SOCIAL_MEDIAS.map((label, i) => ({
  label,
  Icon: SOLID_SOCIAL_MEDIA_ICONS[i],
  id: uuid.v4(),
}));

export const OUTLINE_SOCIAL_MEDIAS = SOCIAL_MEDIAS.map((label, i) => ({
  label,
  Icon: OUTLINE_SOCIAL_MEDIA_ICONS[i],
  id: uuid.v4(),
}));

export const SOCIAL_MEDIA_ICONS_STYLES = SOCIAL_MEDIA_ICONS_STYLE.map(
  (name) => ({
    name,
    id: uuid.v4(),
  })
);

export const FORMAT_LIST = [
  {
    label: "Autoplay: ",
    id: uuid.v4(),
    name: "autoplay",
    for: ["YouTube", "Facebook", "Vimeo"],
  },
  {
    label: "Loop: ",
    id: uuid.v4(),
    name: "loop",
    for: ["YouTube", "Vimeo"],
  },
  {
    label: "Control: ",
    id: uuid.v4(),
    name: "control",
    for: ["YouTube"],
  },
];

// ----------------> ALL CARDS' INITIAL STATES
export const INITIAL_STATE = (type) => {
  switch (type) {
    // Text
    case LABELS[0]:
      return { markup: "<p></p>", styles: { height: 100 } };

    // Video
    case LABELS[1]:
      return {
        // provider: "YouTube",
        url: { value: "" },
        provider: null,
        videoFormat: { autoplay: false, loop: false, control: true },
      };

    // Image
    case LABELS[2]:
      return {
        url: { value: "" },
        imgSrc: "",
        styles: { height: null },
      };

    // Slider
    case LABELS[3]:
      return {
        duration: 1000,
        imgSrc: {},
      };
    // SocialMedia
    case LABELS[4]:
      return {
        iconStyle: { type: SOCIAL_MEDIA_ICONS_STYLE[0], grayscale: false },
        list: {
          [ascendingKey()]: {
            name: SOCIAL_MEDIAS[0],
            url: { value: "", identified: null },
          },
        },
      };

    // Audio
    case LABELS[5]:
      return {
        url: { value: "", isValid: false },
        videoFormat: { autoplay: false, loop: false, control: true },
      };

    // Map
    case LABELS[6]:
      return {
        address: {
          value: {
            lat: 0,
            lng: 0,
            name: "",
          },
        },
        zoom: 0,
        styles: { height: 200 },
      };

    // LinkButton
    case LABELS[7]:
      return {
        btnText: "",
        url: { value: "", isValid: false },
        bgColor: "#ffffff",
        textColor: "#000000",
        borderType: "none",
        fontSize: 16
      };

    // Divider
    case LABELS[8]:
      return {
        borderType: "solid",
        borderWidth: "1",
        borderColor: "#000000",
      };
    default:
      return null;
  }
};

export const CHARS_MAX_LENGTH = 52;

export const HEADER_INITIAL_STATE = {
  image: {
    url: { value: "" },
    imgSrc: "",
    styles: { height: 100 },
    order: 0,
  },
  text: {
    markup: `<p style="text-align:left;"></p>`,
    hasText: false,
    styles: { height: 100 },
    order: 1,
  },
};

export const FOOTER_INITIAL_STATE = {
  image: {
    url: { value: "" },
    imgSrc: "",
    styles: { height: 100 },
    order: 0,
  },
  text: {
    markup: `<p style="text-align:left;"></p>`,
    hasText: false,
    styles: { height: 100 },
    order: 1,
  },
  socialMedia: {
    iconStyle: { type: SOCIAL_MEDIA_ICONS_STYLE[0], grayscale: false },
    list: {
      [ascendingKey(0)]: {
        name: SOCIAL_MEDIAS[0],
        url: { value: "", identified: null },
      },
      [ascendingKey(1)]: {
        name: SOCIAL_MEDIAS[1],
        url: { value: "", identified: null },
      },
      [ascendingKey(2)]: {
        name: SOCIAL_MEDIAS[2],
        url: { value: "", identified: null },
      },
    },

    order: 2,
  },
};

export const GOOGLE_MAPS_API_KEY = "AIzaSyBRYtYjO549cbv9akBo8aKTi-0Q5SQIMIE";
export const LIBRARIES = ["places"];

export const directLinksInitial = {
  WEB: {
    [uuid.v4()]: {
      elLabel: "Web",
      elData: { url: { value: "", isValid: false },new_window:false },
    },
  },
  MAP: {
    [uuid.v4()]: {
      elLabel: LABELS[6],
      elData: INITIAL_STATE(LABELS[6]),
    },
  },
  VIDEO: {
    [uuid.v4()]: {
      elLabel: LABELS[1],
      elData: INITIAL_STATE(LABELS[1]),
    },
  },
  AUDIO: {
    [uuid.v4()]: {
      elLabel: LABELS[5],
      elData: INITIAL_STATE(LABELS[5]),
    },
  },
};

export const CONTENT_TYPES = ["TEMPLATE", "WEB", "MAP", "VIDEO", "AUDIO"];

export const MAX_WIDTH = 335;
