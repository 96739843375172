import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Col, Row, Container } from "react-bootstrap";
import { resetPassword } from "../../context/fetch/fetchUser";
import { PASSWORD_RESET } from "../../configs/constants/paths";
import schema from "./schema";

const ResetPassword = () => {
  const [state, setState] = useState({
    fields: { email: "" },
    errors: { email: "" },
  });

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    schema
      .validate(state.fields, { abortEarly: false })
      .then((valid) => {
        resetPassword(state.fields);
        history.push(`${PASSWORD_RESET}/success`);
      })
      .catch((err) => {
        const newState = { errors: { email: "" } };
        err.errors.forEach((err) => {
          newState.errors[err.key] = err.value;
        });
        setState({ ...state, ...newState });
      });
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      fields: { [name]: value },
      errors: { [name]: "" },
    });
  };

  return (
    <Container className={"login-container"}>
      <Row className={"justify-content-center login-section"}>
        <Col md={7} lg={6} xl={5} className={"login-box"}>
          <Form onSubmit={handleSubmit}>
            <p className={"text-center text-uppercase login-title fbold"}>
              Reset Password
            </p>
            <Form.Group>
              <Form.Control
                type="text"
                name="email"
                placeholder="Email"
                value={state.fields.email}
                onChange={onChange}
                id="email"
                className={"focus-effect cursor-pointer"}
              />
              <span className={"focus-border"}></span>
              {state.errors.email && (
                <Form.Text className="text-danger">
                  {state.errors.email}
                </Form.Text>
              )}
            </Form.Group>
            <p className={"text-center mb-0"}>
              <Button
                variant={"info"}
                style={{ borderRadius: 0 }}
                type="submit"
              >
                Send Password Reset Link
              </Button>
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
