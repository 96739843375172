import React from "react";
import { EL_DATA_LIST } from "../../configs/constants";

export const GetElementData = (elements, key, handleOnStyleChange) => {
  const label = elements[key].elLabel;
  const DataViewer = EL_DATA_LIST[label];
  return (
    <DataViewer
      keyVal={key}
      elData={elements[key].elData}
      handleOnStyleChange={handleOnStyleChange}
    />
  );
};
