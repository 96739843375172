import React, { memo } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { htmlToDraftJs } from "../../../editor/utils/htmlToDraftJs";
import { setTextVal } from "../../../editor/utils/setStateValues";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { areEqual } from "../../../editor/utils/comparison";

const Text = ({ elId, cards, setCards, isEdited, setIsEdited }) => {
  const elData = cards[elId].elData;

  const handleOnStateChange = (editorState) => {
    const currentContent = editorState.getCurrentContent();
    const markup = draftToHtml(convertToRaw(currentContent));
    const updatedCards = setTextVal(markup, cards, elId);
    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  return (
    <>
      <div className="textSettings">
        <h4>Text</h4>
        <Editor
          defaultEditorState={htmlToDraftJs(elData.markup)}
          wrapperClassName="wrapper"
          editorClassName="editor"
          onEditorStateChange={handleOnStateChange}
        />
      </div>
      <hr />
    </>
  );
};

export default memo(Text, areEqual);
