import {
  ELEMENT_FOOTER_IDS,
  ELEMENT_HEADER_IDS,
} from "../../configs/constants/index";

export const getActiveEl = (layout) => {
  if (layout.activeEl.id === ELEMENT_HEADER_IDS[1]) {
    // Header-Text
    return {
      elLabel: ELEMENT_HEADER_IDS[1],
      elData: layout.header.text,
    };
  } else if (layout.activeEl.id === ELEMENT_HEADER_IDS[0]) {
    // Header-Image
    return {
      elLabel: ELEMENT_HEADER_IDS[0],
      elData: layout.header.image,
    };
  } else if (layout.activeEl.id === ELEMENT_FOOTER_IDS[2]) {
    // Footer-Text
    return {
      elLabel: ELEMENT_FOOTER_IDS[2],
      elData: layout.footer.text,
    };
  } else if (layout.activeEl.id === ELEMENT_FOOTER_IDS[0]) {
    // Footer-Image
    return {
      elLabel: ELEMENT_FOOTER_IDS[0],
      elData: layout.footer.image,
    };
  } else if (layout.activeEl.id === ELEMENT_FOOTER_IDS[1]) {
    // Footer-SocialMedia
    return {
      elLabel: ELEMENT_FOOTER_IDS[1],
      elData: layout.footer.socialMedia,
    };
  }

  return layout.elements[layout.activeEl.id];
};
