import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useProject } from "../../context/reducers/projectContext";
import { getProjects } from "../../context/fetch/fetchProjects";
import ProjectOverview from "./ProjectOverview";
import { getProjectsSuccess } from "../../context/actions/projectActions";
import { useStateValue } from "../../context";
import {
  PROJECT_INFO_TEXT_1,
  PROJECT_INFO_TEXT_2,
} from "../../configs/constants";
import Add from "./Add";
import { useLocation } from "react-router-dom";

const Main = () => {
  const [projects, dispatchPrj] = useProject();
  const [isPrjLoading, setPrjLoading] = useState(false);
  const [{ user }] = useStateValue();
  const location = useLocation();
  const [sortQuery, setSortQuery] = useState(null);

  const getPathEnd = (path) => {
    const arrOfPath = path.split("/");
    return arrOfPath.slice(-1)[0];
  };

  useEffect(() => {
    let didCancel = false;
    const fetchProjects = async () => {
      setPrjLoading(true);
      try {
        const prjList = await getProjects(sortQuery);
        const data = {
          prjList,
          currentPath: location.pathname,
        };
        !didCancel && dispatchPrj(getProjectsSuccess(data));
        !didCancel && setPrjLoading(false);
      } catch (e) {
        setPrjLoading(false);
      }
    };
    fetchProjects();
    return () => (didCancel = true);
  }, [dispatchPrj, projects.prjList.length, location.pathname, user.user.id, sortQuery]);

  return (
    <>
      <Container className={"info-container"}>
        <Row className={"justify-content-start"}>
          <Col lg={8} className={"info-section projects-section"}>
            <h4 className={"info-title text-uppercase fbold"}>
              Hi, {user.user.name}!
            </h4>
            <p className={"info-text"}>{PROJECT_INFO_TEXT_1}</p>
            <p className={"info-text"}>{PROJECT_INFO_TEXT_2}</p>
          </Col>
        </Row>
      </Container>
      <Container className={"editor-container"}>
        {getPathEnd(projects.currentPath) === "add" ? (
          <Add projects={projects} dispatchPrj={dispatchPrj} />
        ) : (
          <ProjectOverview
            projects={projects}
            dispatchPrj={dispatchPrj}
            isPrjLoading={isPrjLoading}
            setSortQuery={(item) => setSortQuery(item)}
            sortQuery={sortQuery}
          />
        )}
      </Container>
    </>
  );
};

export default Main;
