import * as yup from "yup";

const schema = yup.object().shape({
  projectName: yup
    .string()
    .required({ key: "name", value: "Project Name is required" }),
  files: yup
    .array()
    .min(1, { key: "files" })
    .max(5, {
      key: "files",
      value: "Projects must have less than or equal to 5 items",
    })
    .required({ key: "files", value: "Please uploade an image" }),
  type: yup
    .string()
    .required({ key: "type", value: "Project Type is required" }),
});
export default schema;
