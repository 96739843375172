import React, { useRef, memo } from "react";
import { Form } from "react-bootstrap";
import {
  setUrlVal,
  setVideoFormatVals,
} from "../../../editor/utils/setStateValues";
import { areEqual } from "../../../editor/utils/comparison";
import { FORMAT_LIST } from "../../../editor/configs/constants";

const Video = ({ elId, cards, setCards, isEdited, setIsEdited }) => {
  const urlRef = useRef(null);
  const elData = cards[elId].elData;

  const [autoplayRef, loopRef, controlRef] = [
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const arrOfRefs = [autoplayRef, loopRef, controlRef];

  const handleOnURLChange = () => {
    const updatedCards = setUrlVal(urlRef, cards, elId);
    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  const handleOnVideoFormatChange = () => {
    const updatedCards = setVideoFormatVals(
      { autoplayRef, loopRef, controlRef },
      cards,
      elId
    );

    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  return (
    <Form.Group className="videoSettings">
      <h4>Video</h4>
      <Form.Group className="videoSettings-source">
        <Form.Label>
          Source: {(elData.provider && elData.provider.name) || "Unidentified"}
          (YouTube, Facebook or Vimeo)
        </Form.Label>
        <Form.Control
          type="url"
          placeholder="type url"
          required
          ref={urlRef}
          onChange={handleOnURLChange}
          defaultValue={elData.url.value}
          style={{
            borderColor: elData.provider?.name ? "green" : "red",
          }}
        />
      </Form.Group>
      <Form.Group className="videoSettings-format">
        {FORMAT_LIST.map((item, i) => (
          <Form.Check
            className={item.for.includes(elData.provider?.name) ? "" : "hidden"}
            key={item.id}
            type="checkbox"
            label={item.label}
            name={item.name}
            onChange={handleOnVideoFormatChange}
            defaultChecked={elData.videoFormat && elData.videoFormat[item.name]}
            ref={arrOfRefs[i]}
          />
        ))}
      </Form.Group>{" "}
      <hr />
    </Form.Group>
  );
};

export default memo(Video, areEqual);
