import {
  attemptLogin,
  loginSuccess,
  attemptSignup,
  signupSuccess,
  attemptUpdateProfile,
  profileUpdateSuccess,
  attemptUploadAvatar,
  uploadAvatarSuccess,
  changePasswordSuccess,
  attemptChangePassword,
  attemptVerifyPassword,
  verifyPasswordSuccess,
  successAlert,
  attemptImpersonate,
  impersonateFail,
  impersonateSuccess,
  signupFail,
  loginFail,
  profileUpdateFail,
  uploadAvatarFail,
  changePasswordFail,
  verifyPasswordFail,
} from "../actions/userActions";
import UserService from "../../services/userService";
import Auth from "../../configs/Auth";
import routes from "../../routes/routesCode";
import logger from '../../utils/logger';

const userService = new UserService();

export const login = async (data, dispatch, history, socketState) => {
  try {
    dispatch(attemptLogin());
    const userData = await userService.login(data);
    const {
      token,
      id
    } = userData.data;
    logger.setContextProps('user',{ userId: id });
    dispatch(loginSuccess(userData.data.user));
    Auth.login(token, history, socketState);
  } catch (e) {
    console.log("e", e);
  }
};

export const signUp = async (data, dispatch, history) => {
  try {
    dispatch(attemptSignup());
    await userService.signUp(data);
    history.push({
      pathname: routes.registrationSucceed.path,
      state: { ...data },
    });
    dispatch(signupSuccess());
  } catch (e) {
    console.log("e", e);
    dispatch(signupFail());
  }
};

export const registrationConfirm = async (token) => {
  await userService.registrationConfirm(token);
};

export const fetchMe = async (dispatch) => {
  try {
    dispatch(attemptLogin());
    const { data } = await userService.fetchMe();
    logger.setContextProps('user',{ userId: data.id });
    dispatch(loginSuccess(data));
  } catch (e) {
    console.log("e", e);
    logger.sendMessage(e);
    dispatch(loginFail());
  }
};

export const resetPassword = async (data) => {
  try {
    await userService.resetPassword(data);
  } catch (e) {
    console.log("err", e.response.data.message);
  }
};

export const updateProfile = async (data, dispatch) => {
  try {
    dispatch(attemptUpdateProfile());
    await userService.updateProfile(data);
    dispatch(profileUpdateSuccess(data));
    dispatch(successAlert("Profile updated successfully!"));
  } catch (e) {
    console.log("e", e);
    dispatch(profileUpdateFail());
  }
};

export const uploadAvatar = async (data, dispatch) => {
  try {
    dispatch(attemptUploadAvatar());
    const res = await userService.uploadAvatar(data);
    dispatch(uploadAvatarSuccess(res.data));
  } catch (e) {
    console.log("e", e);
    dispatch(uploadAvatarFail());
  }
};

export const changePassword = async (data, dispatch) => {
  try {
    dispatch(attemptChangePassword());
    await userService.changePassword(data);
    dispatch(changePasswordSuccess());
    dispatch(successAlert("Password changed!"));
  } catch (e) {
    console.log("e", e);
    dispatch(changePasswordFail());
  }
};

export const verifyPassword = async (data, dispatch, history) => {
  try {
    dispatch(attemptVerifyPassword());
    await userService.verifyPassword(data);
    dispatch(verifyPasswordSuccess());
    history.push("/");
  } catch (e) {
    console.log("e", e);
    dispatch(verifyPasswordFail());
  }
};

export const impersonateUser = async (id, dispatch, history) => {
  try {
    dispatch(attemptImpersonate());
    const res = await userService.impersonate(id);
    localStorage.adminToken = await localStorage.token;
    localStorage.adminRefreshToken = await localStorage.refreshToken;
    localStorage.refreshToken = await res.data.refreshToken;
    localStorage.token = await res.data.accessToken;
    fetchMe(dispatch);
    history.push(routes.projects.path);
    dispatch(impersonateSuccess());
  } catch (e) {
    console.log("e", e);
    dispatch(impersonateFail());
  }
};

export const refreshToken = async (socketState, history) => {
  try {
    const result = await userService.refrashToken();
    const { token } = await result.json();
    localStorage.token = token.accessToken;
    localStorage.refreshToken = token.refreshToken;
    socketState.reconnectWS();
    return token;
  } catch (error) {
    console.log("error", error);
    Auth.logout(history, socketState);
  }
};
