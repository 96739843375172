// ----- > folders' constants
export const ATTEMPT_GET_FOLDERS = "ATTEMPT_GET_FOLDERS";
export const GET_FOLDERS_SUCCESS = "GET_FOLDERS_SUCCESS";
export const GET_FOLDERS_FAIL = "GET_FOLDERS_FAIL";

export const ATTEMPT_SET_FOLDER = "ATTEMPT_SET_FOLDER";
export const SET_FOLDER_SUCCESS = "SET_FOLDER_SUCCESS";
export const SET_FOLDER_FAIL = "SET_FOLDER_FAIL";

export const ATTEMPT_EDIT_FOLDER = "ATTEMPT_EDIT_FOLDER";
export const EDIT_FOLDER_SUCCESS = "EDIT_FOLDER_SUCCESS";
export const EDIT_FOLDER_FAIL = "EDIT_FOLDER_FAIL";

export const ATTEMPT_REMOVE_FOLDER = "ATTEMPT_REMOVE_FOLDER";
export const REMOVE_FOLDER_SUCCESS = "REMOVE_FOLDER_SUCCESS";
export const REMOVE_FOLDER_FAIL = "REMOVE_FOLDER_FAIL";

// ----- > assets' constants
export const ATTEMPT_GET_ASSETS = "ATTEMPT_GET_ASSETS";
export const GET_ASSETS_SUCCESS = "GET_ASSETS_SUCCESS";

export const ATTEMPT_SET_FILE = "ATTEMPT_SET_FILE";
export const SET_FILE_SUCCESS = "SET_FILE_SUCCESS";

export const REMOVE_FILE_SUCCESS = "REMOVE_FILE_SUCCESS";

// -----> other' constants
export const SET_ACTIVE_FOLDER_ID = "SET_ACTIVE_FOLDER_ID";

// ----- > folders' actions
export const attemptGetFolders = () => ({ type: ATTEMPT_GET_FOLDERS });
export const getFoldersSuccess = (payload) => ({
  type: GET_FOLDERS_SUCCESS,
  payload,
});
export const getFoldersFail = () => ({ type: GET_FOLDERS_FAIL });

export const attemptSetFolder = () => ({ type: ATTEMPT_SET_FOLDER });
export const setFolderSuccess = (payload) => ({
  type: SET_FOLDER_SUCCESS,
  payload,
});
export const setFolderFail = () => ({ type: SET_FOLDER_FAIL });

export const attemptEditFolder = () => ({ type: ATTEMPT_EDIT_FOLDER });
export const editFolderSuccess = (payload) => ({
  type: EDIT_FOLDER_SUCCESS,
  payload,
});
export const editFolderFail = () => ({ type: EDIT_FOLDER_FAIL });

export const attemptRemoveFolder = () => ({ type: ATTEMPT_REMOVE_FOLDER });
export const removeFolderSuccess = (payload) => ({
  type: REMOVE_FOLDER_SUCCESS,
  payload,
});
export const removeFolderFail = () => ({ type: REMOVE_FOLDER_FAIL });

// ----- > templates' actions
export const attemptGetAssets = () => ({ type: ATTEMPT_GET_ASSETS });
export const getAssetsSuccess = (payload) => ({
  type: GET_ASSETS_SUCCESS,
  payload,
});

export const attemptSetFile = () => ({ type: ATTEMPT_SET_FILE });
export const removeFileSuccess = (payload) => ({
  type: REMOVE_FILE_SUCCESS,
  payload,
});

export const setFileSuccess = (payload) => ({
  type: SET_FILE_SUCCESS,
  payload,
});

// -----> other' actions
export const setActiveFolderId = (payload) => ({
  type: SET_ACTIVE_FOLDER_ID,
  payload,
});
