import React, { useState, useRef } from "react";
import { useStateValue } from "../../context";
import { Form, Button, Container, Col, Row, Image } from "react-bootstrap";
import { updateProfile } from "../../context/fetch/fetchUser";
import ProfileInput from "./ProfileInput";
import { warningAlert } from "../../context/actions/userActions";
import schema from "./schema";
import AssetsModal from "../../core/AssetsModal";

const Profile = () => {
  const [userState, dispatch] = useStateValue();

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const companyRef = useRef(null);
  const streetRef = useRef(null);
  const zipRef = useRef(null);
  const cityRef = useRef(null);
  const countryRef = useRef(null);
  const phoneRef = useRef(null);

  const [state, setState] = useState({
    errors: {
      name: "",
      email: "",
      company: "",
    },
  });
  const [value, setValue] = useState({ obj: {} });

  const user = userState.user.user;
  const profileUpdateLoading = userState.user.profileUpdateLoading;

  const handleUpdateItem = (e) => {
    setValue({ obj: { ...value.obj, ...e.obj } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    schema
      .validate(
        {
          name: nameRef.current.value,
          email: emailRef.current.value,
          company: companyRef.current.value,
        },
        { abortEarly: false }
      )
      .then((valid) => {
        if (Object.keys(value.obj).length) {
          if (
            !(
              nameRef.current.value === user.name &&
              emailRef.current.value === user.email &&
              companyRef.current.value === user.company &&
              streetRef.current.value === user.street &&
              zipRef.current.value === user.zip &&
              cityRef.current.value === user.city &&
              countryRef.current.value === user.country &&
              phoneRef.current.value === user.phone &&
              value.obj.imageUrl === user.imageUrl
            )
          ) {
            updateProfile({ ...value.obj }, dispatch);
            setValue({ obj: {} });
            setState({ errors: { name: "", email: "", company: "" } });
          }
        } else {
          dispatch(warningAlert("No field changed"));
        }
      })
      .catch((err) => {
        const newState = {
          errors: {
            name: "",
            email: "",
            company: "",
          },
        };
        err.errors.forEach((err) => {
          newState.errors[err.key] = err.value;
        });
        setState({ ...state, ...newState });
      });
  };
  const handleOnFileChange = (data) => {
    setValue({ obj: { ...value.obj, imageUrl: data.files[0].file } });
  };

  const renderProfileImage = (currentUrl, newUrl) => {
    if (currentUrl || newUrl) {
      return (
        <Col>
          <div className={"profile-img-box"}>
            <Image src={newUrl ? newUrl : currentUrl} rounded />
          </div>
        </Col>
      );
    }
  };

  return (
    <Container className={"login-container"}>
      <Row className={"justify-content-center login-section"}>
        <Col md={6} className={"login-box col-10"}>
          <Form onSubmit={handleSubmit}>
            <p className={"text-center text-uppercase login-title fbold"}>
              Profile
            </p>
            {renderProfileImage(user.imageUrl, value.obj.imageUrl)}
            <Form.Group className={"text-center"}>
              <AssetsModal
                callBackFile={handleOnFileChange}
                callLocation={`profile-image-one`}
              >
                <Form.Label htmlFor="avatar" className={"avatar-label"}>
                  Upload image
                </Form.Label>
              </AssetsModal>
            </Form.Group>
            <Form.Group>
              <ProfileInput
                type="text"
                name="email"
                ref={emailRef}
                placeholder="Email"
                value={user.email}
                updateValue={handleUpdateItem}
                id="email"
              />
              {state.errors.email && (
                <Form.Text className="text-danger">
                  {state.errors.email}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <ProfileInput
                type="text"
                name="name"
                ref={nameRef}
                placeholder="Name"
                value={user.name}
                updateValue={handleUpdateItem}
                id="name"
              />
              {state.errors.name && (
                <Form.Text className="text-danger">
                  {state.errors.name}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <ProfileInput
                type="text"
                name="company"
                ref={companyRef}
                placeholder="Company"
                value={user.company}
                updateValue={handleUpdateItem}
                id="company"
              />
              {state.errors.company && (
                <Form.Text className="text-danger">
                  {state.errors.company}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <ProfileInput
                type="text"
                name="street"
                ref={streetRef}
                placeholder="Street"
                id="street"
                updateValue={handleUpdateItem}
                value={user?.street}
              />
            </Form.Group>
            <Form.Group>
              <ProfileInput
                type="text"
                name="city"
                ref={cityRef}
                placeholder="City"
                value={user?.city}
                updateValue={handleUpdateItem}
                id="city"
              />
            </Form.Group>
            <Form.Group>
              <ProfileInput
                type="text"
                name="country"
                ref={countryRef}
                placeholder="Country"
                value={user?.country}
                updateValue={handleUpdateItem}
                id="country"
              />
            </Form.Group>
            <Form.Group>
              <ProfileInput
                type="text"
                name="zip"
                ref={zipRef}
                placeholder="Zip"
                value={user?.zip}
                updateValue={handleUpdateItem}
                id="zip"
              />
            </Form.Group>
            <Form.Group>
              <ProfileInput
                type="text"
                name="phone"
                ref={phoneRef}
                placeholder="Phone"
                value={user?.phone}
                updateValue={handleUpdateItem}
                id="phone"
              />
            </Form.Group>
            <p className={"text-center mb-0"}>
              <Button type="submit" disabled={profileUpdateLoading}>
                {profileUpdateLoading ? "...Loading" : "Update"}
              </Button>
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
