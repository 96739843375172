import React, { memo } from "react";
import { filterComparableData } from "../../utils/comparison";

const LinkButtonData = ({ elData }) => {
  const { btnText, textColor, url, bgColor, fontSize } = elData;

  const btnStyle = {
    color: textColor,
    fontSize,
    backgroundColor: bgColor,
  };

  return (
    <div className="linkButtonData">
      <a
        href={url.value}
        style={btnStyle}
        target="_blank"
        rel="noopener noreferrer"
        onClick={url.isValid ? null : (e) => e.preventDefault()}
      >
        {btnText}
      </a>
    </div>
  );
};

export default memo(LinkButtonData, filterComparableData);
