import React, { useState, useEffect, useRef, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import {
  getProject,
  uploadScreens,
  removeScreen,
  removeProject,
  editProject,
  renameProject,
} from "../../context/fetch/fetchProjects";
import { useRouteMatch, useHistory } from "react-router";
import { PROJECT_PATH } from "../../configs/constants/paths";
import RemoveModal from "./RemoveModal";
import RenameProject from "./RenameProject";
import {
  uploadScreensSuccess,
  removeScreenSuccess,
  getScreenSuccess,
  renameProjectSuccess,
  editScreenSuccess,
} from "../../context/actions/projectActions";
import AddModal from "./AddModal";
import AllScreens from "./AllScreens";
import { useScreen } from "../../context/reducers/screenContext";
import { Loader } from "../../core/Loader";
import { useStateValue } from "../../context";
import { successAlert } from "../../context/actions/userActions";
import { getResult } from "../../utils/getResult";
import { WebSocketContext } from "../../layouts/WebSocket";

const ListProject = () => {
  const [screenState, dispatch] = useScreen();
  const [, dispatchAlert] = useStateValue();
  const socketState = useContext(WebSocketContext);

  const match = useRouteMatch();
  const history = useHistory();

  const [progress, setProgress] = useState([]);
  const [isDisable, setDisable] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isRemoveLoading, setRemoveLoading] = useState(false);
  const [isPrjRemoveLoading, setPrjRemoveLoading] = useState(false);
  const [isVisScreenRemove, setScreenRemoveVis] = useState({
    state: false,
    ID: null,
  });
  const [isVisProjectRemove, setProjectRemoveVis] = useState({ state: false });
  const [isShowAdd, setShowAdd] = useState(false);
  const [isRename, setRename] = useState(false);
  const [isEditLoading, setEditLoading] = useState(false);
  const [isRenameLoading, setRenameLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const nameRef = useRef(null);

  const updateScreens = React.useCallback(() => {
    if (
      socketState.screen &&
      socketState.screen.projectId === Number(match.params.id)
    ) {
      dispatch(uploadScreensSuccess([socketState.screen]));
    }
  }, [socketState.screen, dispatch, match.params.id]);

  const showMessage = React.useCallback(() => {
    if (socketState.message) {
      dispatchAlert(successAlert(socketState.message));
    }
  }, [socketState.message, dispatchAlert]);

  useEffect(() => updateScreens(), [updateScreens]);
  useEffect(() => showMessage(), [showMessage]);

  useEffect(() => {
    let isCancelled = false;

    const fetchScreen = async () => {
      try {
        setLoading(true);
        const id = match.params.id;
        const screen = await getProject(id);
        !isCancelled && dispatch(getScreenSuccess(screen));
        !isCancelled && setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchScreen();
    return () => (isCancelled = true);
  }, [dispatch, match.params.id]);

  const onUploadProgress = (percent, i) => {
    setProgress((progress) => {
      const copyProgress = [...progress];
      copyProgress[i] = percent;
      return copyProgress;
    });
  };

  const handleOnUploadClick = async () => {
    if (!socketState.socket?.connected) {
      socketState.reconnectWS();
      return;
    }

    if (files.length) {
      setDisable(true);

      const promises = files.map(async (file, i) => {
        const fd = new FormData();
        fd.append("screens", file, file.name);
        fd.set("projectId", screenState.id);
        return await uploadScreens(fd, i, onUploadProgress);
      });

      Promise.allSettled(promises).then((result) => {
        const resultData = getResult(result);
        if (resultData.pdf) {
          dispatchAlert(successAlert(resultData.pdf));
        }

        dispatch(uploadScreensSuccess(resultData.image));

        setShowAdd(false);
        setFiles([]);
        setProgress([]);
        setDisable(false);
      });
    } else {
      alert("please add file(s)");
    }
  };

  const handleOnProjectRemoveClick = async () => {
    let isCancelled = false;
    try {
      setPrjRemoveLoading(true);
      await removeProject(screenState.id);
      !isCancelled && setPrjRemoveLoading(false);
      !isCancelled && history.push(PROJECT_PATH);
    } catch (error) {
      setPrjRemoveLoading(false);
    }
  };

  const handleOnScreenRemoveClick = async () => {
    const data = { projectId: screenState.id, id: isVisScreenRemove.ID };

    try {
      setRemoveLoading(true);
      await removeScreen(data);
      dispatch(removeScreenSuccess(data.id));
      setScreenRemoveVis({ state: false, ID: null });
      setRemoveLoading(false);
    } catch (error) {
      setRemoveLoading(false);
      setScreenRemoveVis({ state: false, ID: null });
    }
  };

  const handleOnRenameProject = async () => {
    let didCancel = false;
    const name = nameRef.current.value;

    if (name) {
      try {
        const data = { data: { name }, id: screenState.id };
        setRenameLoading(true);
        await renameProject(data);
        !didCancel && dispatch(renameProjectSuccess(name));
        !didCancel && setRename(false);
        !didCancel && setRenameLoading(false);
      } catch (error) {
        setRename(false);
        setRenameLoading(false);
      }
    } else {
      setErrorMessage(true);
    }
  };

  const handleOnPublishClick = async () => {
    let didCancel = false;

    const data = {
      data: { published: !screenState.published },
      id: screenState.id,
    };

    try {
      setEditLoading(true);
      await editProject(data);
      !didCancel && dispatch(editScreenSuccess(!screenState.published));
      !didCancel && setEditLoading(false);
    } catch (error) {
      setEditLoading(false);
    }
  };

  const handleOnAddNewFileClick = () => setShowAdd(true);
  const handleOnRenameClick = () => setRename((isRename) => !isRename);
  const handleProjectDelete = () => setProjectRemoveVis({ state: true });
  const handleOnRemoveClick = (id) =>
    setScreenRemoveVis({ state: true, ID: id });

  if (isLoading) return <Loader />;

  return (
    <Container className={"editor-container"}>
      <div className={"project-editor project-list-editor"}>
        <Row
          className={
            "justify-content-between align-items-md-end mb-2 flex-md-row flex-column"
          }
        >
          <Col className={"col-auto text-left col-auto mb-3 mb-md-0"}>
            {isRename ? (
              <RenameProject
                loading={isRenameLoading}
                handleOnRenameProject={handleOnRenameProject}
                defaultValue={screenState.name}
                ref={nameRef}
                name="name"
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
              />
            ) : (
              <h4 className={"text-uppercase editor-title mb-3"}>
                {screenState.name}
              </h4>
            )}
            <p className={"text-uppercase mb-0"}>
              TYPE: {screenState.type.title}
            </p>
            <p className={"text-uppercase mb-0"}>
              UPLOADED IMAGES: {screenState.screens.length}
            </p>
            <p className={"text-uppercase mb-0"}>
              {!screenState.published ? "Unpublished" : "Published"}
            </p>
          </Col>

          <>
            <Col className={"text-left mb-3 mb-md-0 project-list-btn-wrapper"}>
              <Button
                variant="outline-secondary"
                onClick={handleOnPublishClick}
                disabled={isEditLoading}
              >
                {screenState.published ? "Unpublish" : "Publish"}
              </Button>
              <Button variant="outline-secondary" onClick={handleOnRenameClick}>
                {isRename ? "Cancel" : "Rename"}
              </Button>
              <Button variant="outline-secondary" onClick={handleProjectDelete}>
                Delete
              </Button>
            </Col>
            <Col className={"text-md-right col-auto"}>
              <Button
                variant="primary"
                className={"add-btn"}
                onClick={handleOnAddNewFileClick}
              >
                <FiPlus size={"16"} /> Add new file
              </Button>
            </Col>
          </>
        </Row>
        <Row className={"justify-content-start"}>
          <Col col={12}>
            <div className={"file-editor project-overview-editor"}>
              <Col lg={10} className={"m-auto"}>
                <Row className={"align-items-center mt-5 project-overview-row"}>
                  <AllScreens
                    screens={screenState.screens}
                    handleOnRemoveClick={handleOnRemoveClick}
                    id={screenState.id}
                  />
                </Row>
              </Col>
            </div>
          </Col>
        </Row>
      </div>
      <RemoveModal
        handleOnRemoveClick={handleOnScreenRemoveClick}
        isVisModal={isVisScreenRemove}
        setModalVis={setScreenRemoveVis}
        loading={isRemoveLoading}
        title={"Delete Screen"}
      />
      <RemoveModal
        isVisModal={isVisProjectRemove}
        setModalVis={setProjectRemoveVis}
        handleOnRemoveClick={handleOnProjectRemoveClick}
        loading={isPrjRemoveLoading}
        title={"Delete Project"}
      />
      <Row className={"justify-content-center align-items-center my-5"}>
        <Col md={5} className={"drag-box col-10 "}>
          <AddModal
            show={isShowAdd}
            setShow={setShowAdd}
            isDisable={isDisable}
            files={files}
            onChangeFiles={setFiles}
            handleOnUploadClick={handleOnUploadClick}
            progress={progress}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ListProject;
