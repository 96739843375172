export const getResult = (files) =>
  files.reduce(
    (result, item) => {
      if (item.status === "fulfilled") {
        if (item.value.data[0].extension === "pdf" && !result.pdf) {
          result.pdf = item.value.data[0].message;
          return result;
        } else {
          result.image = result.image.concat(item.value.data);
          return result;
        }
      } else {
        console.log("reason", item.reason);
        return result;
      }
    },
    { pdf: null, image: [] }
  );
