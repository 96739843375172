import {
  ATTEMPT_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOG_OUT,
  ATTEMPT_SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ATTEMPT_UPDATE_PROFILE,
  PROFILE_UPDATE_SUCCESS,
  ATTEMPT_UPLOAD_AVATAR,
  UPLOAD_AVATAR_SUCCESS,
  UPLOAD_AVATAR_FAIL,
  PROFILE_UPDATE_FAIL,
  ATTEMPT_CHANGE_PASSWORD,
  CAHNGE_PASSWORD_SUCCESS,
  CAHNGE_PASSWORD_FAIL,
  ATTEMPT_VERIFY_PASSWORD,
  VERIFY_PASSWORD_SUCCESS,
  VERIFY_PASSWORD_FAIL,
  ERROR_ALERT,
  WARNING_ALERT,
  SUCCESS_ALERT,
  UNSHOW_ALERT,
  ATTEMPT_IMPERSONATE,
  IMPERSONATE_SUCCESS,
  IMPERSONATE_FAIL,
} from "./actions/userActions";

export const initialState = {
  user: {
    user: {},
    userLoading: false,
    isLoggedIn: false,
    userSignupLoading: false,
    profileUpdateLoading: false,
    uploadAvatarLoading: false,
    uploadAvatarError: false,
    uploadAvatarErrorMessage: null,
    changePasswordLoading: false,
    verifyPasswordLoading: false,
    impersonateLoading: false,
  },
  alert: {
    alertMessage: null,
    alertType: null,
    alertShow: false,
  },
};

export const reducer = (state, { type, payload }) => {
  // console.log("type, payload", type, payload);
  switch (type) {
    case ATTEMPT_LOGIN:
      return {
        ...state,
        user: {
          ...state.user,
          userLoading: true,
          userError: false,
          userErrorMessage: null,
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          user: payload,
          userLoading: false,
          userError: false,
          userErrorMessage: null,
          isLoggedIn: true,
        },
      };
    case LOGIN_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          user: {},
          userLoading: false,
          isLoggedIn: false,
          userError: true,
          userErrorMessage: payload,
        },
      };
    case LOG_OUT:
      return {
        ...state,
        user: {
          ...state.user,
          user: {},
          userLoading: false,
          isLoggedIn: false,
          userError: false,
          userErrorMessage: null,
        },
      };
    case ATTEMPT_SIGNUP:
      return {
        ...state,
        user: {
          ...state.user,
          userSignupLoading: true,
          userSignupError: false,
          userSignupErrorMessage: null,
        },
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          userSignupError: false,
          userSignupErrorMessage: null,
          userSignupLoading: false,
        },
      };
    case SIGNUP_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          userSignupLoading: false,
          userSignupError: true,
          userSignupErrorMessage: payload,
        },
      };
    case ATTEMPT_UPDATE_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          profileUpdateLoading: true,
          profileUpdateError: false,
          profileUpdateErrorMessage: null,
        },
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          user: { ...state.user.user, ...payload },
          profileUpdateLoading: false,
          profileUpdateError: false,
          profileUpdateErrorMessage: null,
        },
      };
    case PROFILE_UPDATE_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          profileUpdateLoading: false,
          profileUpdateError: true,
          profileUpdateErrorMessage: payload,
        },
      };
    case ATTEMPT_UPLOAD_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          uploadAvatarError: false,
          uploadAvatarErrorMessage: null,
          uploadAvatarLoading: true,
        },
      };
    case UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          user: {
            ...state.user.user,
            imageUrl: payload,
          },
          uploadAvatarError: false,
          uploadAvatarErrorMessage: null,
          uploadAvatarLoading: false,
        },
      };
    case UPLOAD_AVATAR_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          uploadAvatarError: true,
          uploadAvatarErrorMessage: payload,
          uploadAvatarLoading: false,
        },
      };
    case ATTEMPT_CHANGE_PASSWORD:
      return {
        ...state,
        user: {
          ...state.user,
          changePasswordError: false,
          changePasswordErrorMessage: null,
          changePasswordLoading: true,
        },
      };
    case CAHNGE_PASSWORD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          changePasswordError: false,
          changePasswordErrorMessage: null,
          changePasswordLoading: false,
        },
      };
    case CAHNGE_PASSWORD_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          changePasswordError: true,
          changePasswordErrorMessage: payload,
          changePasswordLoading: false,
        },
      };
    case ATTEMPT_VERIFY_PASSWORD:
      return {
        ...state,
        user: {
          ...state.user,
          verifyPasswordError: false,
          verifyPasswordErrorMessage: null,
          verifyPasswordLoading: true,
        },
      };
    case VERIFY_PASSWORD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          verifyPasswordError: false,
          verifyPasswordErrorMessage: null,
          verifyPasswordLoading: false,
        },
      };
    case VERIFY_PASSWORD_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          verifyPasswordError: false,
          verifyPasswordErrorMessage: payload,
          verifyPasswordLoading: true,
        },
      };
    case ERROR_ALERT:
      return {
        ...state,
        alert: {
          alertType: "error",
          alertMessage: payload,
          alertShow: true,
        },
        user: {
          ...state.user,
          changePasswordLoading: false,
          verifyPasswordLoading: false,
          userSignupLoading: false,
          profileUpdateLoading: false,
          uploadAvatarLoading: false,
          userLoading: false,
        },
      };
    case WARNING_ALERT:
      return {
        ...state,
        alert: {
          alertType: "warning",
          alertMessage: payload,
          alertShow: true,
        },
        user: {
          ...state.user,
          changePasswordLoading: false,
          verifyPasswordLoading: false,
          userSignupLoading: false,
          profileUpdateLoading: false,
          uploadAvatarLoading: false,
          userLoading: false,
        },
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        alert: {
          alertType: "success",
          alertMessage: payload,
          alertShow: true,
        },
        user: {
          ...state.user,
          changePasswordLoading: false,
          verifyPasswordLoading: false,
          userSignupLoading: false,
          profileUpdateLoading: false,
          uploadAvatarLoading: false,
          userLoading: false,
        },
      };
    case UNSHOW_ALERT:
      return {
        ...state,
        alert: {
          alertType: null,
          alertMessage: null,
          alertShow: false,
          user: {
            changePasswordLoading: false,
            verifyPasswordLoading: false,
            userSignupLoading: false,
            profileUpdateLoading: false,
            uploadAvatarLoading: false,
            userLoading: false,
          },
        },
      };
    case ATTEMPT_IMPERSONATE:
      return {
        ...state,
        user: {
          ...state.user,
          impersonateLoading: true,
          userLoading: true,
        },
      };
    case IMPERSONATE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          impersonateLoading: false,
        },
      };
    case IMPERSONATE_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          impersonateLoading: false,
        },
      };
    default:
      return state;
  }
};
