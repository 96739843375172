import {
  HELP,
  PROJECT_PATH,
  USERS,
  TAGS,
  TEMPLATES_PATH,
  ASSETS_PATH,
  HOW_IT_WORKS,
  REFERENCES,
  TESTIMONIALS,
  PRICES,
} from "../../configs/constants/paths";

export const PROJECT_INFO_TEXT_1 = `
Determine yourself what digital content you like to be linked to
your photos, books, magazines, posters, ads, products packages,
façades or any other images. It’s your decision what a user will
see and experience once scanning your visual work!
`;
export const PROJECT_INFO_TEXT_2 = `
Create your individual mobile screen with the ayscan template
creator for any images within minutes. Use ayscan predefined
templates or create your own templates. Drag and drop elements
such as link-buttons, video and map, text boxes or social media
buttons without coding, and preview the results instantly. ayscan
content management solution is the most convenient way to augment
your anolog media with digital content.
`;

export const GUEST_NAVBAR = [
  { to: HOW_IT_WORKS, label: "How it works" },
  { to: REFERENCES, label: "References" },
  { to: TESTIMONIALS, label: "Testimonials" },
  { to: PRICES, label: "Prices" },
];

export const LOGGEDIN_NAVBAR = [
  { to: PROJECT_PATH, label: "Projects" },
  { to: ASSETS_PATH, label: "Assets" },
  { to: TEMPLATES_PATH, label: "Templates" },
  { to: HELP, label: "Help" },
];
export const LOGGEDIN_ADMIN_NAVBAR = [
  { to: PROJECT_PATH, label: "Projects" },
  { to: ASSETS_PATH, label: "Assets" },
  { to: TEMPLATES_PATH, label: "Templates" },
  { to: HELP, label: "Help" ,type: 'external'},
  { to: USERS, label: "Users" },
  { to: TAGS, label: "Tags" },
];

export const PrjNavList = [" Drafts", "Published", "  All"];
export const WS_BASE = `${process.env.REACT_APP_API}`;
export const REACT_APP_PREVIEW_URL = process.env.REACT_APP_PREVIEW_URL;
export const EXPIRATION_INTERVAL = 300000;
