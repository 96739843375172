//Public routes
export const LOGIN_PATH = "/login";
export const REGISTRATION_PATH = "/registration";
export const HOW_IT_WORKS = "/howItWorks";
export const REFERENCES = "/references";
export const TESTIMONIALS = "/testimonials";
export const PRICES = "/prices";
export const PASSWORD_RESET = "/password/reset";
export const VERIFY_PASSWORD = "/reset-password/verify/token";

//Private routes
export const PROJECT_PATH = "/project";
export const TEMPLATES_PATH = "/templates";
export const TEMPLATES = TEMPLATES_PATH + "/:id?";
export const ASSETS_PATH = "/assets";
export const ASSETS = ASSETS_PATH + "/:id?";
export const PREVIEW = "/v1/:id/asset";
export const HELP = "https://www.ayscan.de/help";
export const PROFILE = "/profile";
export const SECURITY = "/security";
export const USERS = "/users";
export const TAGS = "/tags";
export const PRESET_TAMPLATES = "/preset-templates";
export const EDITOR = "/templates/:id/editor/:id";
