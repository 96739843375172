import React, { memo, useRef } from "react";
import { FaRegImage } from "react-icons/fa";
import { Form, Button } from "react-bootstrap";
import {
  setDurationVal,
  setSliderImageVal,
  setAddImage,
  setRemoveImage,
} from "../../../editor/utils/setStateValues";
import { areEqual } from "../../../editor/utils/comparison";
import AssetsModal from "../../../core/AssetsModal";
import { FcRemoveImage } from "react-icons/fc";

const Slider = ({ elId, cards, setCards, isEdited, setIsEdited }) => {
  const durRef = useRef(null);
  const elData = cards[elId].elData;

  const handleOnDurationChange = () => {
    const updatedCards = setDurationVal(durRef, cards, elId);
    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  const handleOnFileChanage = (data) => {
    const URL = data.files[0].file;
    const shellID = data.shellID;
    const updatedCards = setSliderImageVal(URL, cards, elId, shellID);
    setCards(updatedCards);
    !isEdited && setIsEdited(true);
  };

  const handleOnAddImages = (params) => {
    const result = params.files.reduce(
      (obj, file) => setAddImage(obj, elId, file.file),
      cards
    );
    setCards(result);
    !isEdited && setIsEdited(true);
  };

  const handleOnRemoveImage = (key) => {
    const result = setRemoveImage(key, cards, elId);
    setCards(result);
    !isEdited && setIsEdited(true);
  };

  return (
    <Form.Group className="slider">
      <h4>Slider</h4>
      <Form.Group>
        <Form.Label>Duration (milliseconds): </Form.Label>
        <Form.Control
          type="number"
          min="0"
          defaultValue={elData.duration}
          placeholder="seconds"
          ref={durRef}
          onChange={handleOnDurationChange}
        />
      </Form.Group>
      <Form.Group className="slider-images">
        {Object.keys(elData.imgSrc).map((key) => (
          <div className="image-uploader" key={key}>
            <FcRemoveImage onClick={() => handleOnRemoveImage(key)} />
            <AssetsModal
              callBackFile={handleOnFileChanage}
              callLocation={`projectView-image-multiple`}
              shellID={key}
            >
              <div className="image-preview">
                {elData.imgSrc[key] ? (
                  <img src={elData.imgSrc[key]} alt="img" />
                ) : (
                  <div className="icon-wrapper">
                    <FaRegImage size="70px" />
                  </div>
                )}
              </div>
            </AssetsModal>
          </div>
        ))}
      </Form.Group>
      <Form.Group>
        <AssetsModal
          callBackFile={handleOnAddImages}
          callLocation={`projectView-image-multiple`}
        >
          <Button variant="secondary" type="button">
            Add Images
          </Button>
        </AssetsModal>
      </Form.Group>

      <hr />
    </Form.Group>
  );
};

export default memo(Slider, areEqual);
