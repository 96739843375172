import ProjectService from "../../services/projectServices";
import {
  getProjectTypesSuccess,
  getProjectStatusesSuccess,
  attemptEditTag,
  editTagFail,
  attemptRemoveTag,
  removeTagFail,
  attemptAddTag,
  addTagSuccess,
  addTagFail,
  editScreenSuccess,
  editScreenFail,
  attemptEditScreen,
  editProjectTypeSuccess,
  editStatusesSuccess,
  removeProjectTypeSuccess,
  removeStatusesSuccess,
} from "../actions/projectActions";

const projectService = new ProjectService();

export const getProjectTypes = async () => {
  const result = await projectService.getProjectTypes();
  return result.data.data;
};

export const addTag = async (dispatch, data) => {
  try {
    dispatch(attemptAddTag());
    await projectService.addTag(data);
    data.type === "PROJECT_TYPE"
      ? getProjectTypes(dispatch)
          .then((data) => dispatch(getProjectTypesSuccess(data)))
          .catch((e) => console.log("e", e))
      : getProjectStatuses(dispatch).then((data) =>
          dispatch(getProjectStatusesSuccess(data))
        );

    dispatch(addTagSuccess());
  } catch (e) {
    console.log("e", e);
    dispatch(addTagFail());
  }
};

export const editTag = async (dispatch, data) => {
  try {
    dispatch(attemptEditTag());
    const res = await projectService.editTag(data);
    res.data.type === "PROJECT_TYPE"
      ? dispatch(editProjectTypeSuccess(data))
      : dispatch(editStatusesSuccess(data));
  } catch (e) {
    console.log("e", e);
    dispatch(editTagFail());
  }
};

export const removeTag = async (dispatch, data) => {
  try {
    dispatch(attemptRemoveTag());
    const res = await projectService.removeTag(data);
    res.data.type === "PROJECT_TYPE"
      ? dispatch(removeProjectTypeSuccess(data))
      : dispatch(removeStatusesSuccess(data));
  } catch (e) {
    console.log("e", e);
    dispatch(removeTagFail());
  }
};

export const getProjectStatuses = async () => {
  const res = await projectService.getProjectStatuses();
  return res.data.data;
};

export const addProject = async (fd) => await projectService.addProject(fd);

export const removeProject = async (ID) => {
  await projectService.deleteProject(ID);
};

export const editProject = async (data) => {
  await projectService.editProject(data);
};

export const renameProject = async (data) => {
  await projectService.editProject(data);
};

export const getProjects = async (sortQuery) => {
  const result = await projectService.getProjects(sortQuery);
  return result.data;
};

export const getProject = async (data,sortQuery) => {
  const result = await projectService.getProject(data,sortQuery);
  return result.data;
};

export const uploadScreens = async (fd, i, onUploadProgress) => {
  return await projectService.uploadScreens(fd, i, onUploadProgress);
};

export const removeScreen = async (data) => {
  await projectService.deleteScreen(data);
};

export const editScreen = async (dispatch, data) => {
  try {
    dispatch(attemptEditScreen());
    await projectService.editScreen(data);
    dispatch(editScreenSuccess(data));
  } catch (e) {
    console.log("e", e);
    dispatch(editScreenFail());
  }
};

export const setScreenData = async (id, data) => {
  await projectService.setScreenData(id, data);
};
