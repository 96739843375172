/// project types
export const ATTEMPT_GET_PROJECT_TYPES = "ATTEMPT_GET_PROJECT_TYPES";
export const GET_PROJECT_TYPES_SUCCESS = "GET_PROJECT_TYPES_SUCCESS";
export const GET_PROJECT_TYPES_FAIL = "GET_PROJECT_TYPES_FAIL";
/// tag add
export const ATTEMPT_ADD_TAG = "ATTEMPT_ADD_TAG";
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS";
export const ADD_TAG_FAIL = "ADD_TAG_FAIL";
/// project types edit
export const ATTEMPT_EDIT_TAG = "ATTEMPT_EDIT_TAG";
export const EDIT_PROJECT_TYPE_SUCCESS = "EDIT_PROJECT_TYPE_SUCCESS";
export const EDIT_TAG_FAIL = "EDIT_TAG_FAIL";
/// statuses edit
export const EDIT_STATUSES_SUCCESS = "EDIT_STATUSES_SUCCESS";
/// tag remove
export const ATTEMPT_REMOVE_TAG = "ATTEMPT_REMOVE_TAG";
export const REMOVE_PROJECT_TYPE_SUCCESS = "REMOVE_PROJECT_TYPE_SUCCESS";
export const REMOVE_STATUSES_SUCCESS = "REMOVE_STATUSES_SUCCESS";
export const REMOVE_TAG_FAIL = "REMOVE_TAG_FAIL";
/// add project
export const ATTEMPT_ADD_PROJECT = "ATTEMPT_ADD_PROJECT";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL";
/// project statuses
export const ATTEMPT_GET_PROJECT_STATUSES = "ATTEMPT_GET_PROJECT_STATUSES";
export const GET_PROJECT_STATUSES_SUCCESS = "GET_PROJECT_STATUSES_SUCCESS";
export const GET_PROJECT_STATUSES_FAIL = "GET_PROJECT_STATUSES_FAIL";
/// get projects
export const ATTEMPT_GET_PROJECTS = "ATTEMPT_GET_PROJECTS";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL";
/// remove project
export const ATTEMPT_REMOVE_PROJECT = "ATTEMPT_REMOVE_PROJECT";
export const REMOVE_PROJECT_SUCCESS = "REMOVE_PROJECT_SUCCESS";
export const REMOVE_PROJECT_FAIL = "REMOVE_PROJECT_FAIL";
/// get project
export const ATTEMPT_GET_PROJECT = "ATTEMPT_GET_PROJECT";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAIL = "GET_PROJECT_FAIL";
/// edit project
export const ATTEMPT_EDIT_PROJECT = "ATTEMPT_EDIT_PROJECT";
export const PROJECT_EDITED_SUCCESS = "PROJECT_EDITED_SUCCESS";
export const RENAME_PROJECT_SUCCESS = "RENAME_PROJECT_SUCCESS";
export const PROJECT_EDITED_FAIL = "PROJECT_EDITED_FAIL";

export const ATTEMPT_GET_SCREEN = "ATTEMPT_GET_SCREEN";
export const GET_SCREEN_SUCCESS = "GET_SCREEN_SUCCESS";
export const GET_SCREEN_FAIL = "GET_SCREEN_FAIL";

/// add screens
export const ATTEMPT_UPLOAD_SCREENS = "ATTEMPT_UPLOAD_SCREENS";
export const UPLOAD_SCREENS_SUCCESS = "UPLOAD_SCREENS_SUCCESS";
export const UPLOAD_SCREENS_FAIL = "UPLOAD_SCREENS_FAIL";
/// remove screen
export const ATTEMPT_REMOVE_SCREEN = "ATTEMPT_REMOVE_SCREEN";
export const REMOVE_SCREEN_SUCCESS = "REMOVE_SCREEN_SUCCESS";
export const REMOVE_SCREEN_FAIL = "REMOVE_SCREEN_FAIL";
/// edit screen
export const ATTEMPT_EDIT_SCREEN = "ATTEMPT_EDIT_SCREEN";
export const EDIT_SCREEN_SUCCESS = "EDIT_SCREEN_SUCCESS";
export const EDIT_SCREEN_FAIL = "EDIT_SCREEN_FAIL";
// setCurrentPath
export const SET_CURRENT_PATH = "SET_CURRENT_PATH";

/// project types
export const attemptProjectTypes = () => ({ type: ATTEMPT_GET_PROJECT_TYPES });
export const getProjectTypesSuccess = (payload) => ({
  type: GET_PROJECT_TYPES_SUCCESS,
  payload,
});
export const getProjectTypesFail = () => ({ type: GET_PROJECT_TYPES_FAIL });

/// project types add
export const attemptAddTag = () => ({ type: ATTEMPT_ADD_TAG });
export const addTagSuccess = () => ({ type: ADD_TAG_SUCCESS });
export const addTagFail = () => ({ type: ADD_TAG_FAIL });

/// project types edit
export const attemptEditTag = () => ({
  type: ATTEMPT_EDIT_TAG,
});
export const editProjectTypeSuccess = (payload) => ({
  type: EDIT_PROJECT_TYPE_SUCCESS,
  payload,
});
export const editTagFail = () => ({ type: EDIT_TAG_FAIL });

/// statuses edit
export const editStatusesSuccess = (payload) => ({
  type: EDIT_STATUSES_SUCCESS,
  payload,
});

/// tag remove
export const attemptRemoveTag = () => ({ type: ATTEMPT_REMOVE_TAG });
export const removeProjectTypeSuccess = (payload) => ({
  type: REMOVE_PROJECT_TYPE_SUCCESS,
  payload,
});
export const removeStatusesSuccess = (payload) => ({
  type: REMOVE_STATUSES_SUCCESS,
  payload,
});

export const removeTagFail = () => ({ type: REMOVE_TAG_FAIL });

/// add project
export const attemptAddProject = () => ({ type: ATTEMPT_ADD_PROJECT });
export const addProjectSuccess = (payload) => ({
  type: ADD_PROJECT_SUCCESS,
  payload,
});
export const addProjectFail = () => ({ type: ADD_PROJECT_FAIL });

/// edit project
export const attemptEditProject = () => ({ type: ATTEMPT_EDIT_PROJECT });
export const projectEditedSuccess = (payload) => ({
  type: PROJECT_EDITED_SUCCESS,
  payload,
});
export const renameProjectSuccess = (payload) => ({
  type: RENAME_PROJECT_SUCCESS,
  payload,
});
export const projectEditedFail = () => ({ type: PROJECT_EDITED_FAIL });

/// project statuses
export const attemptProjectStatuses = () => ({
  type: ATTEMPT_GET_PROJECT_STATUSES,
});
export const getProjectStatusesSuccess = (payload) => ({
  type: GET_PROJECT_STATUSES_SUCCESS,
  payload,
});
export const getProjectStatusesFail = () => ({
  type: GET_PROJECT_STATUSES_FAIL,
});

/// get projects
export const attemptGetProjects = () => ({ type: ATTEMPT_GET_PROJECTS });
export const getProjectsSuccess = (payload) => ({
  type: GET_PROJECTS_SUCCESS,
  payload,
});
export const getProjectsFail = () => ({ type: GET_PROJECTS_FAIL });

/// remove project
export const attemptRemoveProject = () => ({ type: ATTEMPT_REMOVE_PROJECT });
export const removeProjectSuccess = (payload) => ({
  type: REMOVE_PROJECT_SUCCESS,
  payload,
});
export const removeProjectFail = () => ({ type: REMOVE_PROJECT_FAIL });

/// get project
export const attemptGetProject = () => ({ type: ATTEMPT_GET_PROJECT });
export const getProjectSuccess = (payload) => ({
  type: GET_PROJECT_SUCCESS,
  payload,
});
export const getProjectFail = () => ({ type: GET_PROJECT_FAIL });

/// get screen
export const attemptGetScreen = () => ({ type: ATTEMPT_GET_SCREEN });
export const getScreenSuccess = (payload) => ({
  type: GET_SCREEN_SUCCESS,
  payload,
});
export const getScreenFail = () => ({ type: GET_SCREEN_FAIL });

/// add screens
export const attemptUploadScreens = () => ({ type: ATTEMPT_UPLOAD_SCREENS });
export const uploadScreensSuccess = (payload) => ({
  type: UPLOAD_SCREENS_SUCCESS,
  payload,
});
export const uploadScreensFail = () => ({ type: UPLOAD_SCREENS_FAIL });

/// remove screen
export const attemptRemoveScreen = () => ({ type: ATTEMPT_REMOVE_SCREEN });
export const removeScreenSuccess = (payload) => ({
  type: REMOVE_SCREEN_SUCCESS,
  payload,
});
export const removeScreenFail = () => ({ type: REMOVE_SCREEN_FAIL });

/// edit screen
export const attemptEditScreen = () => ({ type: ATTEMPT_EDIT_SCREEN });
export const editScreenSuccess = (payload) => ({
  type: EDIT_SCREEN_SUCCESS,
  payload,
});
export const editScreenFail = () => ({ type: EDIT_SCREEN_FAIL });

// setCurrentPath
export const setCurrentPath = (payload) => ({
  type: SET_CURRENT_PATH,
  payload,
});
