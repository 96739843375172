import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import SelectTemplates from "./SelectTemplates";

const ListView = ({ screens, tmplsNFolders, setProject }) => {
  return (
    <>
      {screens?.map((screen) => (
        <div key={screen.id} className={"border-bottom"} id={screen.id}>
          <Row
            className={
              "align-items-start mt-3 project-overview-row project-overview-full-height"
            }
          >
            <Col xl={4} md={6} className={"mb-5 mb-md-3"}>
              <Card className={"cursor-pointer"}>
                <Card.Body
                  className={
                    "d-flex justify-content-between align-items-center py-2 px-1"
                  }
                >
                  <Card.Title className={"mb-0"}>{screen.name}</Card.Title>
                </Card.Body>
                <Card.Body className={"p-0"}>
                  <div className={"project-img-box border"}>
                    <Card.Img variant="top" src={screen.imgUrl} alt={""} />
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <SelectTemplates
              screen={screen}
              tmplsNFolders={tmplsNFolders}
              setProject={setProject}
            />
          </Row>
        </div>
      ))}
    </>
  );
};

export default ListView;
