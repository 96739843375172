import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import {
  addProject as addProjectAction,
  uploadScreens,
} from "../../context/fetch/fetchProjects";
import {
  getProjectTypesSuccess,
  addProjectSuccess,
  setCurrentPath,
} from "../../context/actions/projectActions";
import { getProjectTypes } from "../../context/fetch/fetchProjects";
import { PROJECT_PATH } from "../../configs/constants/paths";
import DragNDrop from "../../core/DragNDrop";
import schema from "./schema";
import { getResult } from "../../utils/getResult";
import { successAlert } from "../../context/actions/userActions";
import { useStateValue } from "../../context";

const initialState = {
  errors: {
    name: "",
    files: "",
    type: "",
  },
};

const Add = ({ projects, dispatchPrj }) => {
  const [, dispatchAlert] = useStateValue();
  const [files, setFiles] = useState([]);
  const [state, setState] = useState(initialState);
  const [isLoading, setLoading] = useState(false);
  const [isTypeLoading, setTypeLoading] = useState(false);
  const history = useHistory()

  const nameRef = useRef(null);
  const typeRef = useRef(null);

  const [progress, setProgress] = useState([]);

  useEffect(() => {
    let didCancel = false;
    const fetchProjectTypes = async () => {
      setTypeLoading(true);
      try {
        const data = await getProjectTypes();
        !didCancel && dispatchPrj(getProjectTypesSuccess(data));
        !didCancel && setTypeLoading(false);
      } catch (e) {
        !didCancel && setTypeLoading(false);
        console.log("e", e);
      }
    };
    if (!projects.typeList.length) fetchProjectTypes();
    return () => (didCancel = true);
  }, [dispatchPrj, projects.typeList.length]);

  const onUploadProgress = (percent, i) => {
    setProgress((progress) => {
      const copyProgress = [...progress];
      copyProgress[i] = percent;
      return copyProgress;
    });
  };

  const addProject = (e) => {
    e.preventDefault();

    const projectName = nameRef.current.value;
    const type = typeRef.current.value;

    let didCancel = false;
    schema
      .validate({ files, projectName, type }, { abortEarly: false })
      .then(async () => {
        setLoading(true);

        const response = await addProjectAction({
          name: projectName,
          projectTypeId: Number(type),
        });
        const project = response.data;
        const projectID = project.id;

        const promises = files.map(async (file, i) => {
          const fd = new FormData();
          fd.append("screens", file, file.name);
          fd.set("projectId", projectID);
          return await uploadScreens(fd, i, onUploadProgress);
        });

        Promise.allSettled(promises)
          .then((result) => {
            const resultData = getResult(result);
            if (resultData.pdf) {
              dispatchAlert(successAlert(resultData.pdf));
            }
          })
          .then(() => {
            !didCancel && dispatchPrj(addProjectSuccess(project));
            !didCancel && setLoading(false);
            history.push(`${PROJECT_PATH}/screens/${projectID}`);
            didCancel = true;
          });
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        didCancel = true;
        const newState = {
          errors: {
            name: "",
            files: "",
            type: "",
          },
        };
        err.errors.forEach((err) => {
          newState.errors[err.key] = err.value;
        });
        setState({ ...state, ...newState });
      });
  };

  const handleOnCloseClick = () => {
    dispatchPrj(setCurrentPath(PROJECT_PATH));
  };

  return (
    <div className={"project-add-editor"}>
      <Row className={"justify-content-between align-items-end mb-1"}>
        <Col col={6}>
          <h4 className={"text-uppercase editor-title mb-0"}>My Projects</h4>
        </Col>
      </Row>
      <Row className={"justify-content-start"}>
        <Col col={12}>
          <div className={`file-editor ${isLoading ? "eventDisabled" : ""}`}>
            <Link
              to={PROJECT_PATH}
              className={`close-icon ${isLoading ? "disabled" : ""}`}
              onClick={handleOnCloseClick}
            >
              &times;
            </Link>
            <Row>
              <Col lg={7}>
                <p className={"editor-text"}>
                  Add a new project to connect interactive content.
                </p>
                <Form className={"project-add-box"} onSubmit={addProject}>
                  <Form.Group
                    as={Row}
                    controlId="formGroupName"
                    className={"mb-4"}
                  >
                    <Form.Label column sm="4">
                      Project name:
                    </Form.Label>
                    <Col sm="8">
                      <div className={"position-relative"}>
                        <Form.Control
                          type="name"
                          className={"focus-effect cursor-pointer"}
                          ref={nameRef}
                        />
                        <span className={"focus-border"}></span>
                      </div>
                      {state.errors.name && (
                        <Form.Text className="text-danger">
                          {state.errors.name}
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="selectName"
                    className={"mb-4"}
                  >
                    <Form.Label column sm="4">
                      Select Type:
                    </Form.Label>

                    <Col sm="8">
                      {isTypeLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <select
                          name="type"
                          className="custom-select"
                          ref={typeRef}
                        >
                          {projects.typeList.map((proj) => (
                            <option key={proj.id} value={proj.id}>
                              {proj.title}
                            </option>
                          ))}
                        </select>
                      )}

                      {state.errors.type && (
                        <Form.Text className="text-danger">
                          {state.errors.type}
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formGroupFile" className="">
                    <Col sm="4">
                      <Form.Label className={"mb-0"}>
                        Upload images to scan:
                      </Form.Label>
                      <p className={"upload-text mb-0"}>(JPEG, PNG, PDF)</p>
                    </Col>
                    <Col sm="8">
                      <DragNDrop
                        files={files}
                        onChangeFiles={setFiles}
                        accept={"application/pdf, image/*"}
                        progress={progress}
                      />

                      {state.errors.files && (
                        <Form.Text className="text-danger">
                          {state.errors.files}
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                  <p className={"text-right text-uppercase"}>
                    <Button
                      className={"btn btn-primary"}
                      type={"submit"}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading" : "Add Project"}
                    </Button>
                  </p>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Add;
