import React from "react";
import { Col, Card, Spinner } from "react-bootstrap";
import ImageLoader from "react-imageloader";
import { Link } from "react-router-dom";
import { PROJECT_PATH } from "../../configs/constants/paths";
import { areEqual } from "../../editor/utils/comparison";

const AllScreens = ({ screens, handleOnRemoveClick, id }) => {
  return screens.map((screen, i) => (
    <Col lg={4} sm={6} className={" mb-3"} key={i}>
      <Card className={"cursor-pointer"}>
        <Link to={`${PROJECT_PATH}/view-list/${id}#${screen.id}`}>
          <Card.Body className={"p-2"}>
            <Card.Title className={"mb-0"}>{screen.name}</Card.Title>
            <div>
              <Card.Text>
                RATING:{" "}
                {screen.tracking_rating
                  ? `5/${screen.tracking_rating}`
                  : "Not available"}
              </Card.Text>
              <Card.Text
                style={{
                  color:
                    screen.status === "Active"
                      ? "#4CAF50"
                      : screen.status === "Processing" ||
                        screen.status === "Inactive"
                      ? "#ff9800"
                      : "#f44336",
                }}
              >
                STATUS: {screen.status ? screen.status : "Not available"}
              </Card.Text>
            </div>
          </Card.Body>
          <Card.Body className={"p-0"}>
            <div className={"project-img-box"}>
              <ImageLoader
                src={screen.imgUrl}
                wrapper={React.createFactory("div")}
                preloader={() => <Spinner animation="grow" variant="primary" />}
              >
                Image load failed!
              </ImageLoader>
            </div>
          </Card.Body>
        </Link>
        <Card.Body className={"p-2 card-footer"}>
          <Card.Link onClick={() => handleOnRemoveClick(screen.id)}>
            Delete
          </Card.Link>
        </Card.Body>
      </Card>
    </Col>
  ));
};

export default React.memo(AllScreens, areEqual);
