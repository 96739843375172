import { isUrlValid } from "../urlValidation";
import { VIDEO_PROVIDER_LIST } from "../../configs/constants";

export const detectProvider = (url = "") => {
  const isURL = isUrlValid(url);
  const youTubeParser = (url) => {
    // const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    // const regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/gm;
    // const match = url.match(regExp);
    // return match && match[0]?.length == 11 ? match[7] : false;
    // url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    // return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];

    let ID = "";
    url = url.replace(/(>|<)/gi, "").split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_-]/i);
      ID = ID[0];
    } else {
      // ID = url;
      ID = null;
    }
    return ID;
  };

  const isFacebookVideo = (url) => {
    const regExp = /^(https?:\/\/www\.facebook\.com\/(?:video\.php\?v=\d+|.*?\/videos\/\d+))*/;
    const match = url.match(regExp);
    return match && match[0].split(/\/|=/g).filter((el) => /^\d+$/.test(el))[0];
  };

  const isVimeoVideo = (url) => {
    // eslint-disable-next-line
    var result = url.match(/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i);
    return result[1]
  };

  if (isURL) {
    try {
      const response = youTubeParser(url);
      if (response)
        return { name: VIDEO_PROVIDER_LIST[0].name, data: { id: response } };
    } catch (error) {
      console.log("not youTube url", error);
    }

    try {
      const response = isFacebookVideo(url);
      if (response)
        return {
          name: VIDEO_PROVIDER_LIST[1].name,
          data: { id: response },
        };
    } catch (error) {
      console.log("not facebook video url");
    }

    try {
      const response = isVimeoVideo(url);
      if (response)
        return {
          name: VIDEO_PROVIDER_LIST[2].name,
          data: { id: response },
        };
    } catch (error) {
      console.log("not facebook video url");
    }
  }

  return null;
};
