import React from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import ProjectTypes from "./projectTypes";
import ProjectStatuses from "./projectStatuses";
import { TegProvider } from "../../context/reducers/tegContext";
import {
  initialState,
  reducer,
} from "../../context/reducers/tegContext/reducer";

const Tags = () => {
  return (
    <TegProvider initialState={initialState} reducer={reducer}>
      <Container className="editor-container tags-container">
        <Tabs defaultActiveKey="types" id="uncontrolled-tab-example">
          <Tab eventKey="types" title="Project Types">
            <ProjectTypes btnName="Add Types" />
          </Tab>
          <Tab eventKey="statuses" title="Project Statuses">
            <ProjectStatuses btnName="Add Statuses" />
          </Tab>
        </Tabs>
      </Container>
    </TegProvider>
  );
};

export default Tags;
