import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";

export const htmlToDraftJs = html => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};
