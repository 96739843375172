import React from "react";
import { Col, Row } from "react-bootstrap";

const Header = ({ id, name, projTitle, length, published }) => {
  return (
    <Row
      className={ "justify-content-between align-items-md-end mb-5 flex-md-row flex-column" }
    >
      <Col className={"col-auto text-left col-auto mb-3 mb-md-0"}>
        <h4 className={"text-uppercase editor-title mb-3"}>{name}</h4>
        <p className={"text-uppercase mb-0"}>TYPE: {projTitle}</p>
        <p className={"text-uppercase mb-0"}>UPLOADED IMAGES: {length}</p>
        <p className={"text-uppercase mb-0"}>
          {!published ? "Unpublished" : "Published"}
        </p>
      </Col>
    </Row>
  );
};
export default Header;
