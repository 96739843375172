import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Form, Button, Col, Row, Container } from "react-bootstrap";
import { useStateValue } from "../../context";
import { verifyPassword } from "../../context/fetch/fetchUser";
import schema from "./schema";

const VerifyPassword = ({ match, history }) => {
  const [{ user }, dispatch] = useStateValue();
  const verifyPasswordLoading = user.verifyPasswordLoading;

  const [state, setState] = useState({
    fields: { oldPassword: "", password: "", confirmPassword: "" },
    errors: { oldPassword: "", password: "", confirmPassword: "" },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    schema
      .validate(state.fields, { abortEarly: false })
      .then(() => {
        const data = {
          password: state.fields.password,
          token: match.params.token,
        };
        verifyPassword(data, dispatch, history);
      })
      .catch((err) => {
        const newState = {
          errors: { oldPassword: "", password: "", confirmPassword: "" },
        };
        err.errors.forEach((err) => {
          newState.errors[err.key] = err.value;
        });
        setState({ ...state, ...newState });
      });
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      fields: { ...state.fields, [name]: value },
      errors: { ...state.errors, [name]: "" },
    });
  };

  return (
    <>
      <Container className={"login-container"}>
        <Row className={"justify-content-center login-section"}>
          <Col md={7} lg={6} xl={5} className={"login-box"}>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="New password"
                  id="password"
                  onChange={onChange}
                  value={state.fields.password}
                />
                {!!state.errors.password && (
                  <Form.Text className="text-danger">
                    {state.errors.password}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm new Password"
                  value={state.fields.confirmPassword}
                  onChange={onChange}
                  id="confirmPassword"
                />
                {!!state.errors.confirmPassword && (
                  <Form.Text className="text-danger">
                    {state.errors.confirmPassword}
                  </Form.Text>
                )}
              </Form.Group>
              <p className={"text-center"}>
                <Button type="submit" disabled={verifyPasswordLoading}>
                  {verifyPasswordLoading ? "Loading" : "Change"}
                </Button>
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(VerifyPassword);
