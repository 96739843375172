import React, { memo } from "react";
import { areEqual } from "../../utils/comparison";

const FbPlayer = ({ elData }) => {
  const id = elData.provider.data.id;
  const { autoplay } = elData.videoFormat;

  return (
    <div className="FbPlayer videoData-responsive">
      <iframe
        title="facebook-video"
        // width="100%"
        // height="100%"
        scrolling="no"
        // frameBorder="no"
        src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F${id}%2F&width=500&show_text=false&height=280&autoplay=${autoplay}&appId`}
        allowtransparency="true"
        allow="encrypted-media"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};

export default memo(FbPlayer, areEqual);