import {
  ATTEMPT_GET_PROJECT_TYPES,
  GET_PROJECT_TYPES_FAIL,
  GET_PROJECT_TYPES_SUCCESS,
  ATTEMPT_EDIT_TAG,
  EDIT_TAG_FAIL,
  ATTEMPT_REMOVE_TAG,
  REMOVE_TAG_FAIL,
  ATTEMPT_ADD_TAG,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAIL,
  ATTEMPT_GET_PROJECT_STATUSES,
  GET_PROJECT_STATUSES_FAIL,
  GET_PROJECT_STATUSES_SUCCESS,
  EDIT_PROJECT_TYPE_SUCCESS,
  EDIT_STATUSES_SUCCESS,
  REMOVE_PROJECT_TYPE_SUCCESS,
  REMOVE_STATUSES_SUCCESS,
} from "../../actions/projectActions";
import { removeFilter, editFilter } from "../../helpers";

export const initialState = {
  project: {
    projectTypes: [],
    projectTypesLoading: true,
    addTagLoading: false,
    editTagLoading: false,
    removeTagLoading: false,
    projectStatuses: [],
    projectStatusesLoading: true,
  },
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case ATTEMPT_GET_PROJECT_TYPES:
      return {
        ...state,
        project: {
          ...state.project,
          projectTypesLoading: true,
        },
      };
    case GET_PROJECT_TYPES_FAIL:
      return {
        ...state,
        project: {
          ...state.project,
          projectTypesLoading: false,
        },
      };
    case GET_PROJECT_TYPES_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          projectTypesLoading: false,
          projectTypes: payload,
        },
      };
    case ATTEMPT_GET_PROJECT_STATUSES:
      return {
        ...state,
        project: {
          ...state.project,
          projectStatusesLoading: true,
        },
      };
    case GET_PROJECT_STATUSES_FAIL:
      return {
        ...state,
        project: {
          ...state.project,
          projectStatusesLoading: false,
        },
      };
    case GET_PROJECT_STATUSES_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          projectStatusesLoading: false,
          projectStatuses: payload,
        },
      };

    case ATTEMPT_ADD_TAG:
      return {
        ...state,
        project: {
          ...state.project,
          addTagLoading: true,
        },
      };
    case ADD_TAG_SUCCESS: {
      return {
        ...state,
        project: {
          ...state.project,
          addTagLoading: false,
        },
      };
    }
    case ADD_TAG_FAIL: {
      return {
        ...state,
        project: {
          ...state.project,
          addTagLoading: false,
        },
      };
    }
    case ATTEMPT_EDIT_TAG:
      return {
        ...state,
        project: {
          ...state.project,
          editTagLoading: true,
        },
      };
    case EDIT_PROJECT_TYPE_SUCCESS: {
      return {
        ...state,
        project: {
          ...state.project,
          projectTypes: editFilter(
            state.project.projectTypes,
            payload.obj,
            payload.id
          ),
          editTagLoading: false,
        },
      };
    }
    case EDIT_TAG_FAIL: {
      return {
        ...state,
        project: {
          ...state.project,
          editTagLoading: false,
        },
      };
    }
    case EDIT_STATUSES_SUCCESS: {
      return {
        ...state,
        project: {
          ...state.project,
          projectStatuses: editFilter(
            state.project.projectStatuses,
            payload.obj,
            payload.id
          ),
          editTagLoading: false,
        },
      };
    }
    case ATTEMPT_REMOVE_TAG:
      return {
        ...state,
        project: {
          ...state.project,
          removeTagLoading: true,
        },
      };
    case REMOVE_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          projectTypes: removeFilter(state.project.projectTypes, payload),
          removeTagLoading: false,
        },
      };
    case REMOVE_STATUSES_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          projectStatuses: removeFilter(state.project.projectStatuses, payload),
          removeTagLoading: false,
        },
      };
    case REMOVE_TAG_FAIL:
      return {
        ...state,
        project: {
          ...state.project,
          removeTagLoading: false,
        },
      };

    default:
      return state;
  }
};
