import React from "react";
import uuid from "uuid";
import Settings from "../Settings";
import { useStateValue } from "../../context";
import { EL_LIST, BAR_LIST, INITIAL_STATE } from "../../configs/constants";
import { addNewElement, setBarIndex } from "../../context/actions";
import { Col } from "react-bootstrap";
import DraggableComponent from "../../core/useDrag";
import CustomPopup from "../../../core/CustomPopup";

const Menu = () => {
  const [{ layout, isPinned }, dispatch] = useStateValue();
  const [index, setIndex] = React.useState(-1);
  const [elRefs] = React.useState([]);

  React.useEffect(() => {
    const id = setTimeout(() => {
      setIndex(-1);
    }, 2000);
    return () => {
      clearTimeout(id);
    };
  }, [index]);

  const handleOnBarClick = (i) => {
    layout.activeEl.id && dispatch(setBarIndex(i));
  };

  const handleOnElementClick = (type, index) => {
    const elements = { ...layout.elements };
    const ID = uuid.v4();
    const order = Object.keys(layout.elements).length;

    elements[ID] = {
      elLabel: type,
      elData: INITIAL_STATE(type),
      order,
    };

    const data = {
      elements,
      id: ID,
    };

    isPinned ? setIndex(index) : dispatch(addNewElement(data));
  };

  return (
    <Col lg={6} className="pl-lg-0">
      <div className="menu">
        <div className="menu-main">
          <div className="menu-main-navbar">
            <nav>
              <ul>
                {BAR_LIST.map((text, i) => (
                  <li
                    key={uuid.v4()}
                    className={`bar ${
                      layout.activeBarIndex === i ? "bar-active" : ""
                    }`}
                    onClick={() => handleOnBarClick(i)}
                  >
                    {text}
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div className="menu-main-body">
            {layout.activeBarIndex ? (
              <Settings />
            ) : (
              EL_LIST.map((el, i) => (
                <DraggableComponent key={el.id} type={el.label}>
                  <CustomPopup
                    title="Not Allowed"
                    message="Pinned Template"
                    show={index === i}
                    targetRef={elRefs[i]}
                  />
                  <div
                    className={`element`}
                    onClick={() => handleOnElementClick(el.label, i)}
                    ref={(el) => (elRefs[i] = el)}
                  >
                    {<el.Icon size="22px" />}
                    <span>{el.label}</span>
                  </div>
                </DraggableComponent>
              ))
            )}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default Menu;
