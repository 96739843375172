import React from "react";

const Spinner = ({ style }) => {
  const spinnerStyle = {
    ...style,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={spinnerStyle} className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
