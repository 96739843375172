import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

function RemoveModal({ handleOnRemoveClick, className }) {
  const [show, setShow] = useState(false);

  const handleOnSave = () => {
    handleOnRemoveClick();
    setShow(false);
  };

  const handleOnClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className={className} onClick={handleShow}>
        Delete
      </Button>

      <Modal show={show} onHide={handleOnClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Files(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete fiile(s) ?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleOnSave}>
            DELETE
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RemoveModal;
