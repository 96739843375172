import React, { useRef } from "react";
import { FiSquare, FiList } from "react-icons/fi";
import { FaTimes } from "react-icons/fa";
import { Col, Nav, Button, Form } from "react-bootstrap";
import { useTemplatesValue } from "../../context/reducers/templatesContext";
import { setTemplate } from "../../context/fetch/fetchTemplates";

const AddTemplate = (props) => {
  const [state, dispatch] = useTemplatesValue();
  const tmplNameRef = useRef(null);

  const { onCloseClick, showInModal, closeModal } = props;

  const handleOnSaveClick = async () => {
    const tmplName = tmplNameRef.current.value;
    const id = state.folder.activeFolderId;
    const body = JSON.stringify(props.layout) || null;
    const responseData = await setTemplate({ tmplName, id, body }, dispatch);
    if (showInModal) {
      const newTemplateID = responseData.id;
      const URL = `/templates/${state.folder.activeFolderId}/editor/${newTemplateID}`;
      window.open(URL, "_self");
      closeModal();
    } else {
      onCloseClick();
    }
  };

  return (
    <Col lg={9} className={"px-0 h-100"}>
      <div
        className={
          "justify-content-between border-bottom d-flex align-items-center assets-navbar"
        }
      >
        <Col className={"col-auto"}>
          <Nav
            variant="tabs"
            defaultActiveKey="link-2"
            className={"justify-content-start align-items-center"}
          >
            <Nav.Item className={"tabs-title pl-0"}>VIEW</Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-1" className={"disabled"}>
                <FiList size={"25"} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-2" className={"disabled"}>
                <FiSquare size={"23"} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col className={"col-auto px-0"}>
          <Nav className="flex-column-reverse flex-sm-row">
            <Button
              className={`btn btn-primary ${
                state.folder.activeFolderId ? "" : "disabled"
              }`}
              onClick={handleOnSaveClick}
            >
              Save
            </Button>
            <Button
              variant="link"
              className={"px-3 text-muted"}
              onClick={props.onCloseClick}
            >
              <FaTimes />
            </Button>
          </Nav>
        </Col>
      </div>
      <div className={"col-12 my-5"}>
        <Col md={5} className={"edit-folder col-10"}>
          <p> Enter a template name:</p>
          <Form className={"my-3"} onSubmit={(e) => e.preventDefault()}>
            <Form.Group
              controlId="formBasicText"
              className={"position-relative"}
            >
              <Form.Control
                type="text"
                placeholder="Template Name"
                className={"focus-effect cursor-pointer"}
                ref={tmplNameRef}
              />
              <span className={"focus-border"}></span>
              <Form.Text className="text-white d-none">
                Template Name!
              </Form.Text>
            </Form.Group>
          </Form>
        </Col>
      </div>
    </Col>
  );
};

export default AddTemplate;
