import React from "react";
import { useStateValue } from "../../context";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { login } from "../../context/fetch/fetchUser";
import schema from "./schema";
import { WebSocketContext } from "../../layouts/WebSocket";

const Login = () => {
  const socketState = React.useContext(WebSocketContext);
  const [{ user }, dispatch] = useStateValue();
  const userLoading = user.userLoading;
  const history = useHistory();

  const [state, setState] = React.useState({
    fields: { email: "", password: "" },
    errors: { email: "", password: "" },
  });

  const handleOnSubmit = (e) => {
    e.preventDefault();

    schema
      .validate(state.fields, { abortEarly: false })
      .then(() => {
        login(state.fields, dispatch, history, socketState);
      })
      .catch((err) => {
        const newState = { errors: { email: "", password: "" } };
        err.errors.forEach((err) => {
          newState.errors[err.key] = err.value;
        });
        setState({ ...state, ...newState });
      });
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      fields: { ...state.fields, [name]: value },
      errors: { ...state.errors, [name]: "" },
    });
  };

  return (
    <Container className={"login-container"}>
      <Row className={"justify-content-center"}>
        <Col md={7} lg={6} xl={5}>
          <Form onSubmit={handleOnSubmit} className={"login-box m-auto"}>
            <p className={"text-center text-uppercase login-title fbold"}>
              {"Log In"}
            </p>
            <Form.Group>
              <Form.Control
                type="text"
                name="email"
                placeholder={"Email"}
                value={state.fields.email}
                onChange={onChange}
                id="email"
                className={"focus-effect cursor-pointer"}
              />
              <span className={"focus-border"}></span>
              {state.errors.email && (
                <Form.Text className="text-danger">
                  {state.errors.email}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-0">
              <Form.Control
                type="password"
                name="password"
                placeholder={"Password"}
                id="password"
                onChange={onChange}
                value={state.fields.password}
                className={"focus-effect cursor-pointer"}
              />
              <span className={"focus-border"}></span>
              {state.errors.password && (
                <Form.Text className="text-danger">
                  {state.errors.password}
                </Form.Text>
              )}
            </Form.Group>
            <p className={"text-right text-capitalize"}>
              <Link
                to={"/password/reset"}
                className={"text-capitalize flight login-text"}
              >
                {"Forgot Password"}
              </Link>
            </p>

            <p className={"text-center mb-0"}>
              <Button type="submit">
                {userLoading ? "Loading" : "Log In"}
              </Button>
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
