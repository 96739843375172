import React, { useRef } from "react";
import { BORDER_TYPE_LIST } from "../../configs/constants";
import { updateElState } from "../../context/actions";
import { useStateValue } from "../../context";
import { getActiveEl } from "../../utils/getActiveEl";
import {
  setBtnTextVal,
  setBgColorVal,
  setBorderTypeVal,
  setBtnUrlVal,
  setFontSizeVal,
  setTextColorVal,
} from "../../utils/setStateValues";

const LinkButton = () => {
  const [{ layout }, dispatch] = useStateValue();
  const els = layout.elements;
  const elId = layout.activeEl.id;

  const btnTextRef = useRef(null);
  const urlRef = useRef(null);
  const bgColorRef = useRef(null);
  const textColorRef = useRef(null);
  const borderTypeRef = useRef(null);
  const fontSizeRef = useRef(null);

  const LBD = getActiveEl(layout).elData;

  const handleOnSetBtnTextChange = () => {
    const result = setBtnTextVal(btnTextRef, els, elId);
    dispatch(updateElState(result));
  };

  const handleOnURLChange = () => {
    const result = setBtnUrlVal(urlRef, els, elId);
    dispatch(updateElState(result));
  };

  const handleOnBgColorChange = () => {
    const result = setBgColorVal(bgColorRef, els, elId);
    dispatch(updateElState(result));
  };

  const handleOnTextColorChange = () => {
    const result = setTextColorVal(textColorRef, els, elId);
    dispatch(updateElState(result));
  };

  const handleOnBorderTypeChange = () => {
    const result = setBorderTypeVal(borderTypeRef, els, elId);
    dispatch(updateElState(result));
  };
  const handleOnFontSizeChange = () => {
    const result = setFontSizeVal(fontSizeRef, els, elId);
    dispatch(updateElState(result));
  };

  return (
    <div className="link-button">
      <h4>Link Button</h4>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="link-button-row-1">
          <input
            className="custom-input"
            type="text"
            placeholder="type button name"
            value={LBD.btnText}
            onChange={handleOnSetBtnTextChange}
            ref={btnTextRef}
          />
        </div>
        <div className="link-button-row-2">
          <input
            className="custom-input"
            type="url"
            placeholder="URL"
            required
            onChange={handleOnURLChange}
            ref={urlRef}
            value={LBD.url.value}
            style={{ borderBottomColor: LBD.url.isValid ? "green" : "red" }}
          />
        </div>

        <div className="link-button-row-3">
          <label className="mb-0 mr-2">Button Color: </label>
          <input
            type="color"
            value={LBD.bgColor}
            onChange={handleOnBgColorChange}
            ref={bgColorRef}
          />
          <label className="mb-0 mr-2">Text Color: </label>
          <input
            type="color"
            value={LBD.textColor}
            onChange={handleOnTextColorChange}
            ref={textColorRef}
          />
          <label className="mb-0 mr-2">Font Size(px): </label>
          <input
            type="number"
            onChange={handleOnFontSizeChange}
            value={LBD.fontSize}
            ref={fontSizeRef}
          />
          <select
            className="ml-3"
            value={LBD.borderType}
            onChange={handleOnBorderTypeChange}
            ref={borderTypeRef}
          >
            {BORDER_TYPE_LIST.map((value, i) => (
              <option key={i} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </form>
    </div>
  );
};

export default LinkButton;
