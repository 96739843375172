import React, { memo, useRef } from "react";
import { updateHeader, setElementHeader } from "../../context/actions";
import { setHeaderStyle, setHeaderOrder } from "../../utils/setStateValues";
import { areEqual } from "../../utils/comparison";
import { ELEMENT_HEADER_IDS, MAX_WIDTH } from "../../configs/constants";
import { Resizable } from "re-resizable";
import DropSortContainer from "../../utils/dragDropSort/Container";

const Header = ({ layout, dispatch }) => {
  const header = layout.header;
  const text = header.text;
  const src = header.image.imgSrc;
  const imgSrc = header.image.imgSrc;
  const height = header.image.styles.height;

  const Logo = () => {
    const imageRef = useRef(null);

    const handleOnLogoAreaClick = () => {
      dispatch(setElementHeader(ELEMENT_HEADER_IDS[0]));
    };

    const handleOnSizeChange = () => {
      const height = imageRef.current.height;
      const result = setHeaderStyle({ height }, layout);
      dispatch(updateHeader(result));
    };

    return (
      <div
        className="logo"
        onClick={handleOnLogoAreaClick}
        onMouseDown={(e) => e.preventDefault()}
        id="logo"
      >
        {src ? (
          <Resizable
            className={"resizable"}
            minHeight={10}
            maxHeight={260}
            maxWidth={MAX_WIDTH}
            lockAspectRatio={true}
            enable={{ top: false, bottom: true }}
            defaultSize={{
              // width: "100%",
              height,
            }}
            onResizeStop={() => handleOnSizeChange()}
          >
            <img src={src} alt="img" ref={imageRef} />
          </Resizable>
        ) : (
          <div className="logo-area">Load image (Logo) +</div>
        )}
      </div>
    );
  };

  const Headline = () => {
    const handleOnTextAreaClick = () => {
      dispatch(setElementHeader(ELEMENT_HEADER_IDS[1]));
    };

    return (
      <div
        className="head-text"
        onClick={handleOnTextAreaClick}
        onMouseDown={(e) => e.preventDefault()}
        id="headline"
      >
        {text.hasText ? (
          <div dangerouslySetInnerHTML={{ __html: text.markup }} />
        ) : (
          <div className="head-text-preview">Header text (Headline) +</div>
        )}
      </div>
    );
  };

  const handleOnMove = () => {
    const header = setHeaderOrder(layout);
    dispatch(updateHeader(header));
  };

  const getItems = () => {
    const Components = [Logo, Headline];
    if (layout.header.image.order !== 0) {
      Components.reverse();
    }
    return Components;
  };

  return (
    <div className="dashboard-main-header">
      <div className={`label ${text.hasText || imgSrc ? "none" : ""}`}>
        HEADER
      </div>
      <DropSortContainer key={text.markup + src} onMove={handleOnMove}>
        {getItems().map((Item, i) => (
          <Item key={i} />
        ))}
      </DropSortContainer>
    </div>
  );
};

const checkEquality = (preview, next) => {
  const previewHeader = preview.layout.header;
  const nextHeader = next.layout.header;
  return areEqual(previewHeader, nextHeader);
};

export default memo(Header, checkEquality);
